const FETCH_INIT_CONTRACTS = 'FETCH_INIT_CONTRACTS'
const FETCH_PAGE_CONTRACTS = 'FETCH_PAGE_CONTRACTS'
const SEARCH_CONTRACTS = 'SEARCH_CONTRACTS'
const CLEAR_CONTRACTS = 'CLEAR_CONTRACTS'

export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case FETCH_INIT_CONTRACTS:
            return action.payload
        case FETCH_PAGE_CONTRACTS:
            return state.concat(action.payload)
        case SEARCH_CONTRACTS:
            return action.payload
        case CLEAR_CONTRACTS:
            return []
        default:
            return init
    }
}
