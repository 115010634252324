import { SET_USER_OPTIONS, CLEAR_USER_OPTIONS } from '../actions/action_types'

export default (state, { type, payload }) => {

    const init = state || []

    switch (type) {
        case SET_USER_OPTIONS:
            return payload
        case CLEAR_USER_OPTIONS:
            return []
        default:
            return init
    }
}