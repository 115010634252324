import { AUTH_USER, AUTH_ERROR, UNAUTH_USER } from '../actions/action_types'

const INITITAL_STATE = {
    authenticated: null,
}

export default (state = INITITAL_STATE, action) => {
    const init = state || {}

    switch (action.type) {
        case AUTH_USER:
            return { authenticated: true }
        case UNAUTH_USER:
            return { authenticated: false }
        case AUTH_ERROR:
            return { error: action.payload }
        default:
            return init
    }
}
