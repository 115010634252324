import {
    head, findIndex, propEq, find, update, mergeRight, path,
    map, equals, xprod, prop, last, filter, compose as c, pick, reject,
    converge, sortBy, pathOr
} from 'ramda'
import axios from '../Essentials/axios'
import {
    CHANGE_AGREEMENT_OWNER,
    ADD_ACCOUNT_AGREEMENTS,
    UPDATE_AGREEMENT_STATUS,
    EDIT_ACCOUNT_AGREEMENT,
    CLEAR_AGREE_EDITS,
    SWITCH_ACCOUNT_AGREEMENT,
    REMOVE_ACCOUNT_AGREEMENT,
    CHANGE_COMMENT,
    CHANGE_MESSAGE,
    CLEAR_SPA_PAGE,
    UPDATE_CURRENT_AGREEMENT_OWNER,
    CLEAR_USER_OPTIONS
} from './action_types'

/*
String => Promise([AccountAgreements])
*/
const fetchAccountAgreements = (id) => {
    return axios.get('/accountagreements', {
        params: {
            id,
        },
    })
}

export const getItemsByName = (items) => {
    return axios.post('/item/getItemsByName', { items })
}

export const getAccountAgreements = (id) => {

    return fetchAccountAgreements(id).then(({ data }) => {
        return [{
            type: ADD_ACCOUNT_AGREEMENTS,
            payload: data,
        }, {
            type: SWITCH_ACCOUNT_AGREEMENT,
            payload: c(head, sortBy(prop('_id')))(data)
        }]
    })
}

export const updateMasterAgreementOwner = (salesRepId, masterAgreementId) => {
    return async (dispatch, getState) => {
        const state = getState()
        const masterId = masterAgreementId ? masterAgreementId : pathOr('', ['accountAgreement', 'masterId', '_id'])(state)

        if (!masterId || !salesRepId)
            return console.error('Missing required parameters')

        const { data, error } = await axios.post('/updatemasteragreementowner', { masterId, salesRepId })
        if (error) // eslint-disable-next-line no-console
            return console.error(error)
        dispatch({ type: CHANGE_AGREEMENT_OWNER, payload: data.user })
        dispatch({ type: UPDATE_CURRENT_AGREEMENT_OWNER, payload: data.user })
        dispatch({ type: CLEAR_USER_OPTIONS })
    }
}

/*
String => String => [{ _id: String }] => { React History } => Dispatch
*/
export const deleteAccountAgreement = (masterId, agreementId, accountAgreements, history) => (dispatch) => {
    axios.post('/deleteagreement', {
        masterId,
        agreementId,
    })
        .then(({ data }) => {
            if (data === 'Removed Master') return history.push('/')

            const accountAgreementsWithRemoval = prop('accountAgreements')(data) || []
            const dirtyProd = xprod(accountAgreements)(accountAgreementsWithRemoval)
            const compareProd = converge(equals, [c(prop('_id'), head), c(prop('accountAgreementId'), last)])
            const cleanProd = filter(compareProd)(dirtyProd)
            const updatedAgreements = map(head)(cleanProd)

            dispatch({
                type: REMOVE_ACCOUNT_AGREEMENT,
                payload: updatedAgreements,
            })

            
            dispatch({
                type: SWITCH_ACCOUNT_AGREEMENT,
                payload: head(updatedAgreements),
            })
        })
        .catch((e) => console.log(e))
}

export const netsuiteEstimateAccountAgreement = (accountId, priceAgreements, agreementId, salesRepId) => axios.post('/netsuiteestimateagreement', {
    accountId, priceAgreements, agreementId, salesRepId,
})
    .then((response) => {
        console.log('NS RESPONSE FROM ESTIMATE', response)

        const estimateurl = pathOr(null, ['data', 'estimateurl'], response)

        /* Set an error message for UI ? */
        if (!estimateurl)
            return
        // check popup -  if popup is blocked open the page in same window, if its not blocked open in new window
        const newWin = window.open(estimateurl, '_blank')
        if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
            window.location = estimateurl
        }

        return response
    })
    .catch(e => {
        console.log('Error Creating Estimate', e)
        const errorMessage = pathOr('Unexpected Server Error Creating Estimate', ['response', 'data'], e)
        /* Error object must have a type property for redSpin/redux */
        return({ hasError: true, message: errorMessage, type: 'CREATE_ESTIMATE_ERROR' })
    })

// Takes account spa id and desired status string
export const updateAgreeStatus = (accountAgreement, status, accountAgreements, comment) => (dispatch) => {
    const { _id } = accountAgreement

    axios.post('/updateagreestatus', {
        id: _id,
        status,
        comment,
    })
        .then(({ data }) => {
            const { _id, status, comments } = data
            const indexToUpdate = findIndex(propEq('_id')(_id))(accountAgreements)
            const found = find(propEq('_id')(_id))(accountAgreements)
            const updatedAgreement = mergeRight(found, { status, comments })

            const { accountId } = updatedAgreement
            const { name, companyName } = accountId
            const accountName = name || companyName

            const updatedAccountAgreements = update(indexToUpdate, updatedAgreement, accountAgreements)

            const message = `${accountName} has been ${(equals(status, 'pending')) ? 'moved to ' : ''}${status}`

            dispatch({
                type: UPDATE_AGREEMENT_STATUS,
                payload: updatedAccountAgreements,
            })

            dispatch({
                type: SWITCH_ACCOUNT_AGREEMENT,
                payload: updatedAgreement,
            })

            dispatch({
                type: CHANGE_COMMENT,
                payload: '',
            })

            dispatch({
                type: CHANGE_MESSAGE,
                payload: message,
            })
        })
}
// {
//     priceAgreements: [{item: String, sellPrice: Number, expDate: Date}],
//     lowestMargin: Number,
//     targetMargin: Number,
//     masterId: Number,
//     accountId: String,
//     accountAgreementId: String
//  }
export const submitEdited = (modifiedAgreement, accountAgreements) => (dispatch) => {
    const status = pathOr('pending', ['status'])(modifiedAgreement)
    const accountAgreementId = prop('_id')(modifiedAgreement)
    const accountId = path(['accountId', '_id'])(modifiedAgreement)
    const targetMargin = prop('targetMargin')(modifiedAgreement)
    const masterId = path(['masterId', '_id'])(modifiedAgreement)
    const rawPriceAgreements = prop('priceAgreements')(modifiedAgreement)
    const priceAgreements = map(pick(['expDate', 'item', 'sellPrice']))(rawPriceAgreements)

    const requestBody = {
        accountAgreementId, accountId, masterId, priceAgreements, targetMargin, status,
    }

    axios.post('/updateagreement', requestBody)
        .then((response) => {
            const newAgreement = { ...response.data, masterId: { ...modifiedAgreement.masterId, ...response.data.masterId } }

            const agreementId = prop('_id')(newAgreement)
            const filterOutOld = reject(propEq('_id')(agreementId))
            const updatedAgreements = [...filterOutOld(accountAgreements), newAgreement]

            dispatch({
                type: EDIT_ACCOUNT_AGREEMENT,
                payload: updatedAgreements,
            })

            dispatch({
                type: SWITCH_ACCOUNT_AGREEMENT,
                payload: newAgreement,
            })

            dispatch({
                type: CLEAR_AGREE_EDITS,
            })
        })
        .catch((e) => console.log(e))
}

export const switchAccountAgreement = (accountAgreement) => {

    return (dispatch) => {

        dispatch({
            type: SWITCH_ACCOUNT_AGREEMENT,
            payload: accountAgreement
        })
    }
}

export const clearSpaPage = () => {

    return (dispatch) => {

        dispatch({
            type: CLEAR_SPA_PAGE
        })
    }
}

export const downloadSpaCSV = async ({ filters }) => await axios.post('/spas/download', filters)