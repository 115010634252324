import React from 'react'
import {
 isEmpty, pathOr, prop, map, addIndex,
} from 'ramda'

const mapIndexed = addIndex(map)

const AccountChoices = (props) => {
        const { visibleAccounts, addAccountToContract, clearModelsToView } = props

        const addAccount = (account) => {
            addAccountToContract(account)
            clearModelsToView('accounts')
        }

        const renderChoice = (account, index) => {
            const repFirstName = pathOr('', ['salesRepId', 'name', 'first'])(account)
            const repLastName = pathOr('', ['salesRepId', 'name', 'last'])(account)
            const comma = (!isEmpty(repFirstName) && !isEmpty(repLastName)) ? ',' : ''
            const name = prop('name')(account) || prop('companyName')(account) || ''

            return (

                <li key={index} onMouseDown={() => addAccount(account)}>
                    <div className="col-4">{pathOr([], ['entityId'])(account)}</div>
                    <div className="col-3 alignLeft bolder">{name}</div>
                    <div className="col-2">{pathOr('', ['category'])(account)}</div>
                    <div className="col-2">{`${repLastName}${comma} ${repFirstName}`}</div>
                </li>
            )
        }
        const renderChoices = mapIndexed(renderChoice)

        return (
            <div className="resultBySearch col-12">
                <ul>
                    {renderChoices(visibleAccounts)}
                </ul>
            </div>
        )
}

export default AccountChoices
