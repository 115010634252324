
export default (state, action) => {
    const init = state || 'SUFFICIENT'

    switch (action.type) {
        case 'SUF':
            return 'SUFFICIENT'
        case 'INSUF':
            return 'INSUFFICIENT'
        default:
            return init
    }
}
