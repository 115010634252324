import {
 propOr, pick, compose as c, pathOr, map, dissoc, merge,
} from 'ramda'
import axios from '../Essentials/axios'

export const CLEAR_CON_SUBMIT = 'CLEAR_CON_SUBMIT'
export const MERGE_CON_DETAILS = 'MERGE_CON_DETAILS'
const SELECT_MANUFACTURER = 'SELECT_MANUFACTURER'
const CHANGE_SEARCH_VALUE = 'CHANGE_SEARCH_VALUE'
const ADD_KNOWN_ITEMS = 'ADD_KNOWN_ITEMS'
const ADD_UNKNOWN_ITEMS = 'ADD_UNKNOWN_ITEMS'
const ADD_ACCOUNTS = 'ADD_ACCOUNTS'
const MOUNT_EDIT = 'MOUNT_EDIT'

export const addToContractSubmit = (detailsObj) => ({ type: MERGE_CON_DETAILS, payload: detailsObj })

// Based on settings ID
export const getPad = () => axios.get('/currentsettings')
        .then((res) => ({
                type: MERGE_CON_DETAILS,
                payload: { pad: pathOr(0, ['data', 'defaultPad'])(res) },
            }))

export const mountContractToEdit = (id) => axios.get('/contract', {
        params: {
            id,
        },
    })
        .then((res) => {
            const baseDetails = c(pick(['pad', 'startDate', 'endDate', 'entityId']), propOr({}, 'data'))(res)
            const manufacturer = pathOr({}, ['data', 'manufacturerId'])(res)
            const manuString = `${propOr('', 'entityId')(manufacturer)} - ${propOr('', 'name')(manufacturer)}`

            const type = pathOr('', ['data', 'type'])(res)
            const baseGpo = pathOr('', ['data', 'contractSource', 'baseGpo'])(res)
            const tier = pathOr('', ['data', 'contractSource', 'tier'])(res)
            const sourceId = pathOr('', ['data', 'contractSource', '_id'])(res)

            const sourceReducer = { baseGpo, type, _id: sourceId }
            const contractDetails = {
 ...baseDetails, editMode: true, baseGpo, tier, type,
}

            const contractAccounts = pathOr([], ['data', 'accounts'])(res)
            const ratio = (pathOr(0, ['data', 'pad'])(res) + 100) / 100
            const fetchedItems = pathOr([], ['data', 'contractItems'])(res)

            const formatConItem = (conItem) => {
                const { itemId, itemName } = conItem
                const getBaseDetails = pick(['mpn', 'externalId', 'vendors', 'uom', 'unitsTypeId', 'purchasePrice', 'contractPrice'])
                const renamedKeys = { _id: itemId, name: itemName }
                return c(merge(renamedKeys), getBaseDetails)(conItem)
            }
            const contractItems = map(formatConItem)(fetchedItems)

            const unknownItems = c(map(dissoc('_id')), pathOr([], ['data', 'unknownItems']))(res)

            return [
                {
                    type: MERGE_CON_DETAILS,
                    payload: contractDetails,
                },
                {
                    type: SELECT_MANUFACTURER,
                    payload: manufacturer,
                },
                {
                    type: CHANGE_SEARCH_VALUE,
                    payload: manuString,
                },
                {
                    type: ADD_KNOWN_ITEMS,
                    payload: contractItems,
                },
                {
                    type: ADD_UNKNOWN_ITEMS,
                    payload: unknownItems,
                },
                {
                    type: ADD_ACCOUNTS,
                    payload: contractAccounts,
                },
                {
                    type: MOUNT_EDIT,
                    payload: sourceReducer,
                },
            ]
        })
        .catch((e) => console.log(e))


export const postContract = (contract, history, contractId) => {
    const edit = propOr(false, 'editMode')(contract)
    const requestBody = edit ? { ...contract, contractId } : contract
    const routeToPost = edit ? '/contract/edit' : '/contract/add'

    return axios.post(routeToPost, {
        ...requestBody,
    })
        .then((res) => {
            history.push(`/contract?id=${res.data._id}`)

            return { type: CLEAR_CON_SUBMIT }
        })
        .catch((e) => console.log(e))
}

export default (state, action) => {
    const init = state || {
        editMode: false,
        entityId: '',
        manufacturerId: '',
        type: '',
        startDate: '',
        endDate: '',
        pad: '',
        baseGpo: '',
        tier: '',
        contractPrices: [],
        accounts: [],
    }

    switch (action.type) {
        case MERGE_CON_DETAILS:
            return { ...init, ...action.payload }
        case CLEAR_CON_SUBMIT:
            return {}
        default:
            return init
    }
}
