const FETCH_INIT_MANUS = 'FETCH_INIT_MANUS'
const FETCH_PAGE_MANUS = 'FETCH_PAGE_MANUS'
const SEARCH_MANUS = 'SEARCH_MANUS'
const CLEAR_MANUS = 'CLEAR_MANUS'

export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case FETCH_INIT_MANUS:
            return action.payload
        case FETCH_PAGE_MANUS:
            return state.concat(action.payload)
        case SEARCH_MANUS:
            return action.payload
        case CLEAR_MANUS:
            return []
        default:
            return init
    }
}
