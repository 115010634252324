import React from 'react'
import { path, prop } from 'ramda'

const DeleteAgreement = (props) => {
    const {
        accountAgreement: currentAgreement, toggleModal,
        deleteAccountAgreement, accountAgreements, history,
    } = props

    const accountIdentifier = path(['accountId', 'entityId'])(currentAgreement) || ''
    const masterId = prop('masterId')(currentAgreement)
    const currentAgreeId = prop('_id')(currentAgreement)

    const deleteAgreeOnClick = () => {
        toggleModal({ spaDelete: false })
        deleteAccountAgreement(masterId, currentAgreeId, accountAgreements, history)
    }

    return (
        <div>

                <div className="modal">
                    <div className="modalPopup">
                        <div className="alert">
                            <p>Delete agreement with {accountIdentifier} ?</p>
                        </div>
                        <div>
                            <button onClick={() => toggleModal({ spaDelete: false })}> No </button>
                            <button onClick={deleteAgreeOnClick}> Yes </button>
                        </div>
                    </div>
                </div>
            ) : (
                    ''
                )}
        </div>
    )
}

export default DeleteAgreement
