import {
    remove, slice, assoc, uniqBy, propEq, prop, differenceWith,
    head, last, map, eqProps, xprod, filter, mergeAll, replace,
    reject, isEmpty, compose as c, merge,
} from 'ramda'
import { CLEAR_CON_SUBMIT } from './contract_submit_reducer'
import axios from '../Essentials/axios'

const REMOVE_KNOWN_ITEM = 'REMOVE_KNOWN_ITEM'
const CLEAR_CONTRACT_ITEMS = 'CLEAR_CONTRACT_ITEMS'
const MERGE_CON_ERROR = 'MERGE_CON_ERRORS'
const ADD_KNOWN_ITEMS = 'ADD_KNOWN_ITEMS'
const ADD_UNKNOWN_ITEMS = 'ADD_UNKNOWN_ITEMS'
const CHANGE_KNOWN_PRICE = 'CHANGE_KNOWN_PRICE'

// const escapeDollar = replace(/\$/g, '')
const escapeNonsense = replace(/[^0-9.]/g, '')

export const getItemsOffCSV = (input, vendor) => {
    const filterEmptyCodes = reject(c(isEmpty, head))
    const filterEmptyPrices = reject(c(isEmpty, escapeNonsense, last))
    const filterEmpty = c(filterEmptyPrices, filterEmptyCodes)
    const inputWithoutEmpties = filterEmpty(input)
    const twoDListToPair = (list) => ({ vendorCode: head(list), contractPrice: c(escapeNonsense, last)(list) })
    const itemPricePairs = map(twoDListToPair, inputWithoutEmpties)
    const vendorcodes = map(head, inputWithoutEmpties)

    return axios.post('/csvitems', {
        vendor,
        vendorcodes,
    })
        .then(({ data }) => {
            const compareProd = (pair) => {
                const itemCode = prop('vendorCode')(head(pair))
                const vendors = prop('vendors')(last(pair))
                const filteredVendors = filter(propEq('vendorCode')(itemCode))(vendors)
                return isEmpty(filteredVendors)
            }

            //// Filtering this on the front end and not in the query on the backend because currently anything 
            //// that got uploaded from the csv and doesn't return a result from the backend gets moved to the bottom section of 
            //// unfound items. This gives us the ability to remove it from that list as well, even though it is not currently not implemented right now
            //// As of a now, an inactive item wills show up in the list of unfound items when uploading via a csv
            const items = data.filter(item=>!item.isInactive)

            const cleanProd = reject(compareProd, xprod(itemPricePairs, items))
            const knownItems = map(mergeAll, cleanProd)
            const unknownItems = differenceWith(eqProps('vendorCode'))(itemPricePairs, knownItems)

            return [{ type: ADD_KNOWN_ITEMS, payload: knownItems }, { type: ADD_UNKNOWN_ITEMS, payload: unknownItems }]
        })
        .catch((e) => console.log(e))
}

export const addItemToContract = (item) => ({ type: ADD_KNOWN_ITEMS, payload: [item] })

export const removeItemFromContract = (itemType, index) => ({ type: `REMOVE_${itemType}_ITEM`, payload: index })

export const changeContractPrice = (index, prop, val) => ({
        type: CHANGE_KNOWN_PRICE,
        index,
        prop,
        val,

    })

export const clearContractItems = () => [{ type: CLEAR_CONTRACT_ITEMS }, { type: MERGE_CON_ERROR, payload: { contractPriceErrors: [] } }]

export const changeCP = (index, propName, val, list) => [
        ...slice(0, index, list),
        assoc(propName, val, list[index]),
        ...slice(index + 1, Infinity, list),
    ]


const addItemDefaults = map((item) => merge(item, { selectedUom: prop('purchaseUnit')(item) }))
export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case REMOVE_KNOWN_ITEM:
            return remove(action.payload, 1, init)
        case CHANGE_KNOWN_PRICE:
            return changeCP(action.index, action.prop, action.val, init)
        case ADD_KNOWN_ITEMS:
            return uniqBy(prop('_id'), [...init, ...addItemDefaults(action.payload)])
        case CLEAR_CONTRACT_ITEMS:
            return []
        case CLEAR_CON_SUBMIT:
            return []
        default:
            return init
    }
}
