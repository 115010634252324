import { remove, uniqBy, prop } from 'ramda'
import { CLEAR_CON_SUBMIT } from './contract_submit_reducer'

const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS'
const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT'
const ADD_ACCOUNTS = 'ADD_ACCOUNTS'

export const addAccountToContract = (account) => ({ type: ADD_ACCOUNTS, payload: [account] })

export const removeAccountFromContract = (index) => ({ type: REMOVE_ACCOUNT, payload: index })

export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case FETCH_ACCOUNTS:
            return [...init, ...action.payload]
        case ADD_ACCOUNTS:
            return uniqBy(prop('_id'), [...init, ...action.payload])
        case REMOVE_ACCOUNT:
            return remove(action.payload, 1, init)
        case CLEAR_CON_SUBMIT:
            return []
        default:
            return init
    }
}
