import React, { Component } from 'react'
import {
    map, addIndex, pathOr, toUpper, compose,
} from 'ramda'
import ItemFilter from './ItemFilter'
import InactiveItemWarning from '../Reusable/InactiveItemWarning'

const formatPrice = (price) => {
    if (price === '') return price
    else return `$${price.toFixed(2)}`
}

const mapIndexed = addIndex(map)

const renderItem = (item, index) => {
    const externalId = item.externalId || ''
    const manuName = item.manuName || ''
    const mpn = item.mpn || ''
    const name = item.name || ''
    const bestPrice = pathOr('', ['bestPrice'])(item)
    const cost = pathOr('', ['cost'])(item)
    const type = compose(toUpper, pathOr('', ['costSource', 'type']))(item) || 'Purchase Price' // item.costSource.type || ''
    const isInactive = pathOr(false, ['isInactive'])(item)
    return (
        <li key={index}>
            <div className="col-2">{externalId}</div>
            <div className="col-2 alignLeft bolder">{name}{isInactive && <InactiveItemWarning />}</div>
            <div className="col-2">{manuName}</div>
            <div className="col-2">{mpn}</div>
            <div className="col-2">{formatPrice(bestPrice)}</div>
            <div className="col-1">{formatPrice(cost)}</div>
            <div className="col-1">{type}</div>
        </li>
    )
}

const renderItems = mapIndexed(renderItem)

class ItemList extends Component {
    render() {
        const { visibleItems } = this.props

        return (
            <div>
                <ItemFilter {...this.props} />
                <div className="headerBasicList">
                    <div className="col-2">ID</div>
                    <div className="col-2">Name</div>
                    <div className="col-2">Manufacturer</div>
                    {/* if MPN is unnecessary just get rid of below div */}
                    <div className="col-2">MPN</div>
                    <div className="col-2">Price</div>
                    <div className="col-1">Cost</div>
                    <div className="col-1">Type</div>
                </div>
                <div className="basicList unLinked">
                    <ul>
                        {renderItems(visibleItems)}
                    </ul>
                </div>
            </div>
        )
    }
}

export default ItemList
