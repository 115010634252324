import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { debounce } from 'lodash'
import {
    isEmpty, head, pathOr, compose, concat, converge, always, prop, applyTo, append, __, identity,
} from 'ramda'

class Search extends Component {
     handleFormChange = debounce((searchTerms) => {
        this.props.selectFirstPage()
        this.props.redSpin(this.props.searchSpas(1, searchTerms), '/spasSearch')
    }, 300)
    render() {
        const {
            handleSubmit, searchTerms, selectFirstPage, searchSpas, paginatedAgreements, redSpin, history,
        } = this.props

        const enhanceTab = converge(concat, [pathOr('all', ['currentTab']), always('Agrees')])
        const getAgrees = converge(applyTo, [identity, compose(pathOr([]), append(__, ['agreements']), enhanceTab)])
        const agreesBasedOnTab = getAgrees(paginatedAgreements)

        // const handleFormChange = (searchTerms) => {
        //     selectFirstPage()
        //     redSpin(searchSpas(1, searchTerms), '/spasSearch')
        // }

        const handleEnterKey = (e) => {
            if ((e.key === 'Enter') && isEmpty(agreesBasedOnTab)) return e.preventDefault()
            if (e.key === 'Enter') {
                const spaId = compose(prop('_id'), head)(agreesBasedOnTab)
                history.push(`/spa?id=${spaId}`)
                e.preventDefault()
            }
        }


        return (
            <div className="commonSearch">
                <form
                    onKeyPress={handleEnterKey}
                    onChange={(e) => {
                        this.handleFormChange({search: e.target.value})
                    }}
                    className={`${(searchTerms) ? ' inputted' : ''}`}>
                    <Field name="search" component="input" type="text" placeholder="Search" autoComplete="off" />
                </form>
            </div>
        )
    }
}

export default reduxForm({
    form: 'Search',
})(Search)
