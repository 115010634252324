import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
    head, toUpper, concat, tail, map, addIndex, pathOr, path, isEmpty, converge, compose as c,
} from 'ramda'
import { dateString } from '../utilities/util'

const capitalizeFirst = converge(concat, [c(toUpper, head), tail])
const mapIndexed = addIndex(map)

const renderAgreement = (agreement, index) => {
    const { _id: entity } = agreement

    const rawEarliest = pathOr('', ['earliestExpDate'])(agreement)
    const rawLatest = pathOr('', ['latestExpDate'])(agreement)
    const earliest = isEmpty(rawEarliest) ? '' : dateString(new Date(rawEarliest))
    const latest = isEmpty(rawLatest) ? '' : dateString(new Date(rawLatest))

    const masterEntity = pathOr('', ['masterId', 'entityId'])(agreement)
    const realMasterId = path(['masterId', '_id'])(agreement)
    const formattedStatus = c(capitalizeFirst, pathOr('', ['status']))(agreement)

    return (
        <li key={index}>
            <Link to={`/spa?id=${realMasterId}`}>
                <div className="col-3 bolder">{formattedStatus}</div>
                <div className="col-3 bolder">{masterEntity}</div>
                <div className="col-2 bolder">{entity}</div>
                <div className="col-2">{earliest}</div>
                <div className="col-2">{latest}</div>
            </Link>
        </li>
    )
}

const renderAgreements = mapIndexed(renderAgreement)

class AgreementList extends Component {
    render() {
        const agreementsToRender = pathOr([], ['agreements'])(this.props)

        return (

            <div>
                <div className="headerBasicList">
                    <div className="col-3">Status</div>
                    <div className="col-3">Master Agreement</div>
                    <div className="col-2">Agreement</div>
                    <div className="col-2">Earliest Date</div>
                    <div className="col-2">Latest Date</div>
                </div>
                <div className="basicList">
                    <ul>
                        {renderAgreements(agreementsToRender)}
                    </ul>
                </div>
            </div>

        )
    }
}


export default AgreementList
