import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
 map, prop, addIndex, isEmpty, pathOr,
} from 'ramda'
import { dateString } from '../utilities/util'

const mapIndexed = addIndex(map)

const renderContract = (contract, index) => {
    const { _id } = contract

    const rawEndDate = pathOr('', ['endDate'])(contract)
    const rawStartDate = pathOr('', ['startDate'])(contract)

    const endDate = isEmpty(rawEndDate) ? '' : dateString(new Date(rawEndDate))
    const startDate = isEmpty(rawStartDate) ? '' : dateString(new Date(rawStartDate))
    const manuName = pathOr('', ['manufacturerId', 'name'])(contract)
    const contractSource = prop('contractSource')(contract) || {}
    const baseGpo = pathOr('LVC', ['contractSource', 'baseGpo'])(contract)
    const sourceName = pathOr('', ['contractSource', 'tier'])(contract)
    const contractIdentifier = prop('entityId')(contract) || _id

    return (
        <li key={index}>
            <Link to={`/contract?id=${_id}`}>
                <div className="col-2 bolder">{contractIdentifier}</div>
                <div className="col-3 alignLeft bolder">{manuName}</div>
                <div className="col-1">{baseGpo}</div>
                <div className="col-2">{sourceName}</div>
                <div className="col-2">{startDate}</div>
                <div className="col-2">{endDate}</div>
            </Link>
        </li>
    )
}

const renderContracts = mapIndexed(renderContract)

class ContractList extends Component {
    render() {
        const contractsToRender = pathOr([], ['contracts'])(this.props)

        return (

            <div>
                <div className="headerBasicList">
                    <div className="col-2">ID</div>
                    <div className="col-3">Vendor</div>
                    <div className="col-1">GPO</div>
                    <div className="col-2">Tier</div>
                    <div className="col-2">Start Date</div>
                    <div className="col-2">End Date</div>
                </div>
                <div className="basicList">
                    <ul>
                        {renderContracts(contractsToRender)}
                    </ul>
                </div>
            </div>

        )
    }
}

export default ContractList
