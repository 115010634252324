import React, { Component } from 'react'
import { isEmpty } from 'ramda'

class SelectTier extends Component {
  renderTierOptions(baseGpo) {
    const { tiers } = this.props

    return tiers.map((src) => (
        <option key={src._id} value={src._id}>
          {src.tier}
        </option>
      ))
  }

  render() {
    const {
 tiers, error, addToContractErrors, addToContractSubmit, selectTier, source,
} = this.props

    const handleSelect = (event) => {
      addToContractSubmit({ tier: event.target.value })
      selectTier(event.target.value)
    }

    return (
      <div className={`selectSource${error ? ' error' : ''}`}>
        <p>Tier</p>
        <select
          name="tier"
          onChange={(e) => handleSelect(e)}
          onFocus={() => addToContractErrors({ tier: false })}
          value={`${source._id ? source._id : 'deadTier'}`}
        >
          <option disabled="disabled" selected="true" className="defaultOption" value="deadTier">- Select Tier -</option>
          {!isEmpty(tiers) && this.renderTierOptions()}
        </select>
      </div>
    )
  }
}

export default SelectTier
