import React, { Component } from 'react'
import { connect } from 'react-redux'
import { equals, anyPass } from 'ramda'
import { addToSPACreate, clearSPACreate, postMasterSPA } from '../reducers/spa_create_reducer'
import { addToCreateSpaErrors } from '../reducers/create_spa_errors_reducer'
import { selectItemForSPA, removeItemFromSPA } from './selected_items_reducer'
import { getEnhancedItems } from './item_data_reducer'
import { addItemMod } from './item_mods_reducer'
import { getRecentlyOrdered } from './recetly_ordered_reducer'
import { searchModels, selectModel, clearModel, clearModelsToView } from '../actions/index'
import { mapDispatch, mapSpinToDispatch, redSearchHandlerInit } from '../utilities/redux-util'
import Stage1 from './Stage1'
import Stage2 from './Stage2'
import Stage3 from './Stage3'
import roleManagement from '../HOC/RoleManagement'

const handleBack = (history) => {
    if (anyPass([equals(0), equals(1), equals(2)])(history.length)) history.push('/')
    else history.goBack()
}

class CreateSPA extends Component {
    componentDidMount() {
        const { addToSPACreate } = this.props
        addToSPACreate({ stage: 1 })
    }

    componentWillUnmount() {
        const { clearSPACreate } = this.props
        clearSPACreate()
    }

    render() {
        const { stage } = this.props.spaCreate
        const { isAdmin, isSalesManager, isPurchaseAssistant, isInsideSales, isSalesRep, history } = this.props

        if (isAdmin || isSalesManager || isSalesRep || isInsideSales || isPurchaseAssistant) {
            return (
                <div className="contentDetail">
                    <div className="headerDetail">
                        <button className="btnPrev" onClick={() => handleBack(history)} />
                        <div className="titleDetail SPA">
                            <div className="infoIcon" />
                            <h2>Create Agreement</h2>
                        </div>
                    </div>
                    <div className="progress">
                        <div>
                            <p>Basic Info</p>
                            <button className={`${equals(1)(stage) ? 'selected' : ''}`}>1</button>
                        </div>
                        <div className={`step-2${equals(2)(stage) ? ' selected' : ''}`}>
                            <p>Item Selection</p>
                            <button className={`${equals(2)(stage) ? 'selected' : ''}`}>2</button>
                        </div>
                        <div className={`step-3${equals(3)(stage) ? ' selected' : ''}`}>
                            <p>Item Cart</p>
                            <button className={`${equals(3)(stage) ? 'selected' : ''}`}>3</button>
                        </div>
                    </div>
                    {(stage === 1) && <Stage1 {...this.props} searchModels={searchModels} />}
                    {(stage === 2) && <Stage2 {...this.props} searchModels={searchModels} />}
                    {(stage === 3) && <Stage3 {...this.props} />}
                </div>
            )
        }
    }
}


const mapStateToProps = (state) => ({
    spaCreate: state.spaCreate,
    visibleAccounts: state.visibleAccounts,
    currentAccount: state.currentAccount,
    visibleItems: state.visibleItems,
    spaItemData: state.spaItemData,
    selectedSpaItems: state.selectedSpaItems,
    spaItemMods: state.spaItemMods,
    createSpaErrors: state.createSpaErrors,
    spin: state.spin,
    recentlyOrdered: state.recentlyOrdered,
})
const md2p = mapDispatch({
    getEnhancedItems,
    postMasterSPA,
    getRecentlyOrdered,
}, {
    addToSPACreate,
    clearSPACreate,
    clearModelsToView,
    selectModel,
    clearModel,
    selectItemForSPA,
    removeItemFromSPA,
    addItemMod,
    addToCreateSpaErrors,

}, (dispatch) => ({
    redSpin: mapSpinToDispatch(dispatch),
    redSearchHandlerInit: redSearchHandlerInit(dispatch),
}))

export default connect(mapStateToProps, md2p)(roleManagement(CreateSPA))
