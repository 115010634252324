import axios from '../Essentials/axios'
// Added delete contract for clear description of action...although clear contract is the same
const FETCH_CONTRACT = 'FETCH_CONTRACT'
const SELECT_CONTRACT = 'SELECT_CONTRACT'
const CLEAR_CONTRACT = 'CLEAR_CONTRACT'
const DELETE_CONTRACT = 'DELETE_CONTRACT'
const TOGGLE_MODAL = 'TOGGLE_MODAL'

export const deleteContract = (contractId, history) => (dispatch) => {
        axios.post('/contract/delete', { contractId })
            .then((response) => {
                dispatch({
                    type: DELETE_CONTRACT,
                })

                dispatch({
                    type: TOGGLE_MODAL,
                    payload: { contractDelete: false },
                })

                history.push('/contracts')
            })
            .catch((e) => console.log(e))
    }

export default (state, action) => {
    const init = state || {}

    switch (action.type) {
        case FETCH_CONTRACT:
            return action.payload
        case SELECT_CONTRACT:
            return action.payload
        case DELETE_CONTRACT:
            return {}
        case CLEAR_CONTRACT:
            return {}
        default:
            return init
    }
}
