import React, { Component } from 'react'
import { converge, concat, toUpper, head, tail, compose, replace, pathOr } from 'ramda'


class Search extends Component {
    searchHandlerInit = () => {
        const { selectFirstPage, searchModels } = this.props

        const { pathname } = this.props.location

        const handleSearch = (search) => {
            selectFirstPage()
            return searchModels(pathname, 1, search)
        }
        return this.props.redSearchHandlerInit(handleSearch, `${pathname}search`)
    }

    searchHandler = this.searchHandlerInit()

    render() {
        const { searchTerms, history } = this.props

        const { pathname } = this.props.location

        const changeRoute = (currentPath) => {
            if (pathname === '/manus') return '/manufacturer'
            else return replace(/s/, '', currentPath)
        }

        const loseSlash = replace(/\//, '')
        const capitalizeFirst = converge(concat, [compose(toUpper, head), tail])
        const enhancePathName = compose(concat('visible'), capitalizeFirst, loseSlash)
        const modelsToView = pathOr([], [enhancePathName(pathname)])(this.props)


        const handleEnterKey = (e) => {
            if (e.key === 'Enter') return e.preventDefault()
            // if ((e.key === 'Enter') && isEmpty(modelsToView)) return e.preventDefault()
            // if (e.key === 'Enter') {
            //     const routeId = compose(prop('_id'), head)(modelsToView)
            //     history.push(`${changeRoute(pathname)}?id=${routeId}`)
            //     return e.preventDefault()
            // }
        }

        return (
            <div className="commonSearch">
                <form
                    onKeyPress={handleEnterKey}
                    className={`${(searchTerms) ? ' inputted' : ''}`}>
                    <input
                        name="search"
                        type="text"
                        placeholder="Search"
                        autoComplete="off"
                        onChange={(e) => this.searchHandler({ search: e.target.value })} />
                </form>
            </div>

        )
    }
}

export default Search
