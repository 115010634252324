import React, { Component } from 'react'
import { withStyles, Grid } from '@material-ui/core'
import CSVReader from 'react-csv-reader'
import {
  isEmpty, addIndex, map, pathOr, flatten, filter, compose, fromPairs
} from 'ramda'
import { getItemsByName } from '../actions/agreements_page'
import ItemSearch from './ItemSearch'

const mapIndexed = addIndex(map)

class Stage2 extends Component {
  state = {
    csvItems: []
  }

  handleAddRecentlyOrdered = () => {
    const account = this.props.currentAccount

    const res = this.props.redSpin(this.props.getRecentlyOrdered({ id: account._id }), 'getRecentlyOrdered').then((d) => {
      const items = d.payload.data

      if (items) this.props.addToCreateSpaErrors({ emptyItems: false })

      items.map((item) => {
        this.props.selectItemForSPA(item)
      })
    })
  }


  render() {
    const {
      searchModels, visibleItems, selectedSpaItems, selectItemForSPA, removeItemFromSPA,
      addToSPACreate, spaCreate, addToCreateSpaErrors, createSpaErrors, spin, classes
    } = this.props
    const error = pathOr(false, ['emptyItems'])(createSpaErrors)

    const handleNext = () => {
      if (!isEmpty(selectedSpaItems)) {
        addToCreateSpaErrors({ emptyItems: false })
        addToSPACreate({ stage: spaCreate.stage + 1 })
      } else addToCreateSpaErrors({ emptyItems: true })
    }

    const renderSelectedItem = (item, index) => (
      <li key={index}>
        <div className="col-11 alignLeft">
          {pathOr('', ['externalId'])(item)} - <span className="bolder">{pathOr('', ['name'])(item)}{' '}</span>
        </div>
        <div className="col-1">
          <button
            className="btnRemove"
            onClick={() => removeItemFromSPA(index)}
          />
        </div>
      </li>
    )

    const renderSelectedItems = mapIndexed(renderSelectedItem)

    const renderItemChoice = (item, index) => (
      <li
        key={index}
        onClick={() => {
          selectItemForSPA(item)
          addToCreateSpaErrors({ emptyItems: false })
        }}>
        <div className="col-2">{pathOr('', ['externalId'])(item)}</div>
        <div className="col-5 alignLeft bolder">{pathOr('', ['name'])(item)}</div>
        <div className="col-3">{pathOr('', ['manuName'])(item)}</div>
        <div className="col-2">{pathOr('', ['mpn'])(item)}</div>
      </li>
    )

    const renderItemChoices = mapIndexed(renderItemChoice)

    const handleCSVUpload = async (csvLines) => {
      const csvItems = compose(
        filter(f => f),
        flatten
      )(csvLines)

      getItemsByName(csvItems).then((res) => {
        res.data.map(item => selectItemForSPA(item))
      })
    }

    return (
      <div className="col-12">
        <p>Upload from csv</p>
        <p>One item per line (only item name i.e 787370)</p>
        <CSVReader
          cssClass={'csvReader csvReadAgreements'}
          onFileLoaded={handleCSVUpload}
          onError={(e) => {
            console.log(e)
            alert('Unable to read CSV')
          }}
        />

        <div className="splitPanel">
          <div className="singlePanel">
            <ItemSearch {...this.props} />
            <div className="headerBasicList">
              <div className="col-2">ID</div>
              <div className="col-5">Item Name</div>
              <div className="col-3">Vendors</div>
              <div className="col-2">MPN</div>
            </div>
            <div className="basicList">
              <ul>{visibleItems && renderItemChoices(visibleItems)}</ul>
            </div>
          </div>
          <div className="singlePanel inverted">

            <div className="headerBasicList">
              <div className={`col-9${error ? ' error' : ''}`}>
                <p>Selected Item</p>
              </div>
              <div className={`col-3${error ? ' error' : ''}`}>
                <button className={`btnRecentlyOrdered  ${spin.getRecentlyOrdered ? ' loading' : ''}`} onClick={this.handleAddRecentlyOrdered}>Recently Ordered</button>
              </div>
            </div>
            {isEmpty(selectedSpaItems) ? (<div className="alertNoItem errorMsg" />) : (
              <div className="basicList unLinked">
                <ul>{selectedSpaItems && renderSelectedItems(selectedSpaItems)}</ul>
              </div>)}
          </div>
        </div>
        <div className="alignCenter buttons">
          <button className="moreBtn" onClick={() => addToSPACreate({ stage: spaCreate.stage - 1 })}>Previous</button>
          <button className="moreBtn aim" onClick={handleNext}>Next</button>
        </div>
      </div>
    )
  }
}

const styles = () => {
  return {
    csvUploadBtn: {
      marginLeft: 10,
      color: 'white'
    },
  }
}

export default withStyles(styles)(Stage2)
