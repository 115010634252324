import React, { Component } from 'react'
import {
  isEmpty, propOr, head, addIndex, map,
} from 'ramda'


const mapIndexed = addIndex(map)

class ManuSearch extends Component {
  searchHandlerInit = () => {
    const {
      addToContractSubmit, clearContractItems, searchModels, clearModelsToView, changeSearchValue, clearModel,
    } = this.props

    const handleSearch = (search) => {
      addToContractSubmit({ manufacturerId: '' })
      clearModel('manufacturer')
      clearModelsToView('items')
      clearContractItems()
      return searchModels('/manus', 1, search)
    }
    return this.props.redSearchHandlerInit(handleSearch, '/manussearch')
  }

  searchHandler = this.searchHandlerInit()

  render() {
    const {
      visibleManus, contractSubmit, manuSearchValue, selectModel,
      addToContractSubmit, clearContractItems, currentManu, searchModels,
      clearModelsToView, changeSearchValue, addToContractErrors, spin,
      clearModel,
    } = this.props


    const handleManuClick = (manu) => {
      selectModel('manufacturer', manu)
      addToContractSubmit({ manufacturerId: manu._id })
      changeSearchValue(`${manu.entityId} - ${manu.name}`)
      clearModelsToView('manus')
      clearModelsToView('items')
      clearContractItems()
    }

    const handleBlur = (event) => {
      if (isEmpty(currentManu)) changeSearchValue('')
      clearModelsToView('manus')
    }

    const renderManu = (manu, index) => (
      <li key={manu._id} onMouseDown={() => handleManuClick(manu)}>
        <div>{manu.entityId}</div> - <div className="bolder">{manu.name}</div>
      </li>
    )
    const renderManus = mapIndexed(renderManu)

    const renderChoices = () => (
      <div className="resultBySearch">
        <ul>
          {renderManus(visibleManus)}
        </ul>
      </div>
    )

    const handleEnterKey = (e) => {
      if (e.key === 'Enter' && isEmpty(visibleManus)) return e.preventDefault()
      if (e.key === 'Enter') {
        handleManuClick(head(visibleManus))
        e.preventDefault()
      }
    }

    const editMode = propOr(false, 'editMode')(contractSubmit)

    return (
      <div className={`relativeP${!isEmpty(currentManu) ? ' selected' : ''}${spin['/manussearch'] ? ' loading' : ''}`}>
        <input
          type="text"
          name="manuSearch"
          placeholder="Vendor Search"
          disabled={editMode}
          value={manuSearchValue}
          onFocus={() => addToContractErrors({ manufacturerId: false })}
          onChange={(e) => {
            this.props.changeSearchValue(e.target.value)
            this.searchHandler({ search: e.target.value })}
          }
          onBlur={(e) => handleBlur(e)}
          onKeyPress={handleEnterKey}
        />
        {!isEmpty(visibleManus)
          && (
            <div className="resultBySearch">
              <ul>
                {renderManus(visibleManus)}
              </ul>
            </div>
          )}
      </div>
    )
  }
}

export default ManuSearch
