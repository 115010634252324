// May or may not want a modal for editing
const TOGGLE_MODAL = 'TOGGLE_MODAL'

/*
{ deleteModal : Boolean}|{ editModal: false } => { deleteModal: Boolean, editModal: Boolean }
*/
export const toggleModal = (modalCriteria) => (dispatch) => {
    dispatch({
      type: TOGGLE_MODAL,
      payload: modalCriteria,
    })
  }

export default (state, action) => {
  const init = state || {
    spaDelete: false,
    spaEdit: false,
    spaEstimate: false,
    contractDelete: false,
  }

  switch (action.type) {
    case TOGGLE_MODAL:
      return { ...init, ...action.payload }
    default:
      return init
  }
}
