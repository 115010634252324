import React, { Component } from 'react'
import {
 map, prop, curry, compose, addIndex,
} from 'ramda'
import { dateString } from '../utilities/util'

const mapIndexed = addIndex(map)

const renderContract = curry((history, contract, index) => {
        const contractId = prop('contractId')(contract)
        const contractIdentifier = prop('contractEntityId')(contract)
        const contractPrice = prop('contractPrice')(contract)
        const contractSource = prop('type')(contract)
        const contractGPO = prop('contractName')(contract)
        const endDate = dateString(new Date(contract.endDate))
        const startDate = dateString(new Date(contract.startDate))

        return (
            <li key={index} onClick={() => history.push(`contract?id=${contractId}`)}>
                <div className="col-2 bolder">{contractIdentifier}</div>
                <div className="col-2 bolder">$ {parseFloat(contractPrice).toFixed(2)}</div>
                <div className="col-2 bolder">{contractSource}</div>
                <div className="col-2 bolder">{contractGPO}</div>
                <div className="col-2">{startDate}</div>
                <div className="col-2">{endDate}</div>
            </li>
        )
})

const renderContracts = compose(mapIndexed, renderContract)

const ContractList = (props) => {
    const { contracts, history } = props


    return (
        <div>
            <div className="headerBasicList">
                <div className="col-2"> ID</div>

                <div className="col-2">Price</div>
                <div className="col-2">Cost Source</div>
                <div className="col-2">GPO</div>
                <div className="col-2">Start Date</div>
                <div className="col-2">End Date</div>
            </div>
            <div className="basicList">
                <ul>
                    {(contracts) && renderContracts(history)(contracts)}
                </ul>
            </div>
        </div>
    )
}


export default ContractList
