import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
    find, map, filter, propEq, keys, reduce, and, prop, compose, addIndex, propOr, isEmpty, pathOr,
} from 'ramda'
import { getRegexObj } from '../utilities/util'
import ItemFilter from './ItemFilter'
import InactiveItemWarning from '../Reusable/InactiveItemWarning'

const mapIndexed = addIndex(map)

class ItemList extends Component {
    render() {
        const { currentContract, history } = this.props

        const contractItems = propOr([], 'contractItems')(currentContract)
        const unknownItems = propOr([], 'unknownItems')(currentContract)

        const itemFilter = pathOr({}, ['itemFilter'])(this.props)
        const filterObj = getRegexObj(itemFilter)

        const checkProp = (property, searchObj, item) => {
            const propValue = prop(property)(item)
            return searchObj[property].test(propValue)
        }

        const checkItem = (item) => map((key) => checkProp(key, filterObj, item), keys(filterObj))
        const reduceToTrue = reduce(and, true)
        const filterItems = filter(compose(reduceToTrue, checkItem))
        const filteredItems = filterItems(contractItems)


        const renderContractItem = (contractItem, index) => {
            const {
                externalId, itemName, mpn, paddedContractPrice, contractPrice, itemId, uom, isInactive
            } = contractItem

            const uoms = pathOr({}, ['unitsTypeId', 'uom'], contractItem)
            const selectedUom = find(propEq('internalId', uom), uoms) || {}
            const uomDisp = pathOr('', ['unitName'], selectedUom)
            const extId = externalId || ''
            const name = itemName || ''
            const partNumber = mpn || ''
            const paddedPrice = paddedContractPrice ? parseFloat(paddedContractPrice).toFixed(2) : ''
            const prePadPrice = contractPrice ? parseFloat(contractPrice).toFixed(2) : ''
            return (
                <li key={index} className={isInactive ? 'contract-inactive-item' : ''}>
                    <Link to={`/item?id=${itemId}`}>
                        <div className="col-2">{extId}</div>
                        <div className="col-4 alignLeft bolder">
                            {name}
                            {isInactive && <InactiveItemWarning />}
                        </div>
                        <div className="col-2">{partNumber}</div>
                        <div className="col-2">{`$${paddedPrice}${prePadPrice ? ` ($${prePadPrice}) ` : ''}`}</div>
                        <div className="col-2">{uomDisp}</div>
                    </Link>
                </li>
            )
        }

        const renderContractItems = mapIndexed(renderContractItem)


        const renderUnknownItem = (item, index) => {
            const contractPrice = propOr(0, 'contractPrice')(item)
            const vendorCode = propOr('', 'vendorCode')(item)
            return (
                <li key={index}>
                    <div className="col-1" />
                    <div className="col-8 alignLeft">{vendorCode}</div>
                    <div className="col-2">&#36;{contractPrice}
                    </div>
                    <div className="col-1" />
                </li>
            )
        }
        const renderUnknownItems = mapIndexed(renderUnknownItem)

        const routeToItem = (item) => {
            history.push(`/item?id=${item.itemId}`)
        }

        return (
            <div>
                <ItemFilter {...this.props} filteredItems={filteredItems} routeToItem={routeToItem} />
                <div className="headerBasicList">
                    <div className="col-2">ID</div>
                    <div className="col-4">Name</div>
                    <div className="col-2">MPN</div>
                    <div className="col-2">Cost</div>
                    <div className="col-2">UOM</div>
                </div>
                <div className="basicList">
                    <ul>
                        {renderContractItems(filteredItems)}
                    </ul>
                </div>
                {(!isEmpty(unknownItems)) && <div className="relativeP unknown">
                    <div className="alignCenter">
                        <h3>Unknown Items</h3>
                    </div>
                    <div className="headerBasicList">
                        <div className="col-9">Vendor Code</div>
                        <div className="col-2">Cost</div>
                    </div>
                    <div className="basicList unLinked">
                        <ul>
                            {renderUnknownItems(unknownItems)}
                        </ul>
                    </div>
                </div>}
            </div>


        )
    }
}

export default ItemList
