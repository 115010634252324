import { pathOr, assocPath } from 'ramda'
import {
    CHANGE_AGREEMENT_OWNER,
    ADD_ACCOUNT_AGREEMENTS,
    UPDATE_AGREEMENT_STATUS,
    EDIT_ACCOUNT_AGREEMENT,
    REMOVE_ACCOUNT_AGREEMENT,
    CHANGE_AGREEMENTS_COMMENTS,
    REMOVE_AGREEMENTS_COMMENTS,
    CLEAR_SPA_PAGE,
} from '../actions/action_types'

export default (state, { type, payload }) => {
    //    [{ accountId: { contractSources: Array(2), _id: "1688592", entityId: "131MED GOTHAM GASTROENDTEROLOGY, PLLC", companyName: "GOTHAM GASTROENDTEROLOGY, PLLC", name: "" }
    //     comments: []
    //     createdAt: "2019-01-02T02:22:38.916Z"
    //     masterId: {
    //         _id: 3, entityId: "3", priceAgreements: Array(21), salesRepId: { }, endDate: "2019-07-01T00:00:00.000Z"
    //     }
    //     priceAgreements: []
    //     status: "approved"
    //     targetMargin: 30
    //     updatedAt: "2019-09-23T21:26:28.603Z"
    //     _id: 0}]
    const init = state || []

    switch (type) {
        case CHANGE_AGREEMENTS_COMMENTS:
            return state.map((agreement) => (payload.id === agreement._id ? { ...agreement, comments: payload.comments } : agreement))
        case REMOVE_AGREEMENTS_COMMENTS:
            return state.map((agreement) => (payload.id === agreement._id ? { ...agreement, comments: agreement.comments.filter(f => f !== payload.comment) } : agreement))
        case ADD_ACCOUNT_AGREEMENTS:
            return payload
        case REMOVE_ACCOUNT_AGREEMENT:
            return payload
        case UPDATE_AGREEMENT_STATUS:
            return payload
        case EDIT_ACCOUNT_AGREEMENT:
            return payload
        case CHANGE_AGREEMENT_OWNER:
            return state.map((agreement) => assocPath(['masterId', 'salesRepId'], payload, agreement))
        case CLEAR_SPA_PAGE:
            return []
        default:
            return init
    }
}