const MERGE_SPA_UPDATE_ITEM_ERROR = 'MERGE_SPA_UPDATE_ITEM_ERROR'
const CLEAR_SPA_UPDATE_ITEM = 'CLEAR_SPA_UPDATE_ITEM'

export const addToCreateSpaErrors = (errorObj) => ({ type: MERGE_SPA_UPDATE_ITEM_ERROR, payload: errorObj })

export default (state, action) => {
  const init = state || {
    account: false,
    expDate: false,
    targetMargin: false,
    emptyItems: false,
    sellPriceErrors: [],
  }

  switch (action.type) {
    case MERGE_SPA_UPDATE_ITEM_ERROR:
      return { ...init, ...action.payload }
    case CLEAR_SPA_UPDATE_ITEM:
      return {}
    default:
      return init
  }
}
