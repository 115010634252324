import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { ThemeProvider } from '@material-ui/styles'
import theme from './muiTheme'
import App from './Essentials/App'
import registerServiceWorker from './registerServiceWorker'
import reducers from './reducers/index'
import { AUTH_USER, UNAUTH_USER } from './actions/action_types'
import { initFatal } from './utilities/redux-util'
import { addChatWidget } from './utilities/util'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

/* 
Example use of logtail :

import { Logtail } from '@logtail/browser'

const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_SOURCE_TOKEN)

logtail.info('Logtail for Contracts Client')

*/

Sentry.init({

  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN_CLIENT,
  
  integrations: [new BrowserTracing()],

    /* 
		tracesSampleRate is the rate of transactions (TO DO: define transaction) that Sentry 
		will track in order to monitor the application's performance.
		
		A tracesSampleRate of 1.0 captures 100% of the transactions.  A recommended value
		to use is 0.1 (i.e. 10%).
    */

  tracesSampleRate: 1.0,
})

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore)
const store = createStoreWithMiddleware(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
initFatal(store.dispatch)

// const lstoreUserCreds = (payload) => {
//   Object.keys(payload).forEach((key) => {
//     localStorage.setItem(key, typeof payload[key] === 'object' ? JSON.stringify(payload[key]) : payload[key])
//   })
//   return payload
// }

// // NS initial sign on, receive token from suitelet
// window.onmessage = (e) => {
//   try {
//     if (e.origin !== 'https://760963.app.netsuite.com' && e.origin !== 'https://760963-sb1.app.netsuite.com') {
//       return
//     }

//     const payload = JSON.parse(e.data)

//     lstoreUserCreds(payload)

//   } catch (e) {
//     console.log(e.name, e.message)
//   }
// }

// Subsequent uses
const token = localStorage.getItem('token')

// If we have a token, consider the user to be signed in
if (token) {
  // update application state
  const user = JSON.parse(localStorage.getItem('user'))
  const role = JSON.parse(localStorage.getItem('role'))
  console.log('Signed in')

  store.dispatch({ type: AUTH_USER, user, role })

  if (user) {
    document.tidioIdentify = {
      distinct_id: user._id,
      email: user.contact.email,
      name: `${user.name.first} ${user.name.last}`,
    }
  }

  addChatWidget()
}

if (!token) {
  // const redirectUrl = 'https://system.netsuite.com'
  // if (process.env.NODE_ENV === 'production') {
  //   window.location.href = redirectUrl
  // }
  console.log('Not signed in')
  store.dispatch({ type: UNAUTH_USER })
}


ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
)
registerServiceWorker()
