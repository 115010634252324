import React from 'react'
import { isEmpty, map, addIndex } from 'ramda'

const mapIndexed = addIndex(map)

const ItemChoices = (props) => {
  const {
 addItemToContract, clearModelsToView, visibleItems, currentManu,
} = props

  const renderAlert = () => (
      <div className="alertNoVendor">
        <p>Please Select A Vendor</p>
      </div>
    )

  const addItem = (item) => {
    addItemToContract(item)
    clearModelsToView('items')
  }

  const renderChoice = (item, index) => (
      <li key={index} onMouseDown={() => addItem(item)}>
        <div className="col-3">{item.externalId}</div>
        <div className="col-7 alignLeft bolder">{item.name}</div>
        <div className="col-2">{item.mpn}</div>
      </li>
    )

  const renderChoices = mapIndexed(renderChoice)

  return (
    <div>
      {isEmpty(currentManu) && renderAlert()}
      <div className="resultBySearch col-7">
        <ul>{renderChoices(visibleItems)}</ul>
      </div>
    </div>
  )
}

export default ItemChoices
