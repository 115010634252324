import React, { Component } from 'react'
import {
  map, prop, uniqBy, addIndex, isNil, isEmpty, keys,
  groupBy, compose as c, reject, anyPass, reduce, or, curry,
  sortBy, head, last, toPairs, min, pathOr, equals, contains,
} from 'ramda'
import { Link } from 'react-router-dom'

const makeDate = (str) => new Date(str)
const anyGood = reduce(or)(false)
const mapIndexed = addIndex(map)

const groupAndSortGpos = (sources) => {
  const getDatesPer = map(c(makeDate, prop('createdAt')))
  const reduceMinDatePer = reduce(min)(Infinity)
  const getMinDatePer = c(reduceMinDatePer, getDatesPer)
  const sortAllMinDatePairs = c(sortBy(last), toPairs, map(getMinDatePer))

  const groupedSources = groupBy(prop('baseGpo'))(sources)
  const sortedPairs = sortAllMinDatePairs(groupedSources)
  const gpoNames = map(head)(sortedPairs)

  return gpoNames
}

const sortTiers = sortBy(c(makeDate, prop('createdAt')))

const renderTierOption = curry((selectedTier, tier, index) => {
  const isSelected = tier._id == selectedTier
  const tierName = prop('tier')(tier) || ''
  return (
    <option key={index} value={tier._id} selected={isSelected}>
      {tierName}
    </option>
  )
})

const renderTierOptions = c(mapIndexed, renderTierOption)

const renderGPOOption = (gpoName, index) => (
    <option key={index} value={gpoName}>
      {gpoName}
    </option>
  )

const renderGPOOptions = mapIndexed(renderGPOOption)

class GpoList extends Component {
  render() {
    const {
      history, removeAccountSource, addAccountSource, currentAccount,
      gpoManage, gpoManageChange, sources, isAdmin, isSalesManager, isPurchaseManager, isPurchaseAssistant,
    } = this.props

    const roleList = [isAdmin, isSalesManager, isPurchaseManager, isPurchaseAssistant]
    const { selectedGpo, selectedTier } = gpoManage

    const isEmptyOrNil = anyPass([isEmpty, isNil])
    const accountSources = pathOr([], ['contractSources'])(currentAccount)
    const listOfSourceNumbers = map(prop('_id'))(accountSources)
    const accSourcesToRender = reject(c(isEmptyOrNil, prop('baseGpo')))(accountSources)
    const accountId = currentAccount._id

    const rawSources = sources || []
    const groupedSources = groupBy(prop('baseGpo'))(rawSources)

    const gpos = groupAndSortGpos(rawSources)
    const tiersToRender = c(sortTiers, pathOr([], [selectedGpo]))(groupedSources)

    const renderContractSource = curry((roleList, source, index) => {
      const { _id, baseGpo, tier } = source
      const sourceBase = baseGpo || ''
      const sourceName = tier || ''

      return (
        <li key={index}>
          <div className="col-5 bolder">{sourceBase}</div>
          <div className="col-5">{sourceName}</div>
          <div className="col-2">
            {anyGood(roleList) && <button className="btnRemove" onClick={() => removeAccountSource(accountId, _id)} />}
          </div>
        </li>
      )
    })

    const renderContractSources = c(mapIndexed, renderContractSource)

    const handleGpoSelect = (e) => {
      const val = e.target.value
      const initialTier = c(prop('_id'), head, sortTiers, pathOr([], [val]))(groupedSources)
      gpoManageChange({ selectedGpo: val, selectedTier: initialTier })
    }

    return (
      <div>
        <div className="headerBasicList">
          <div className="col-5">GPO</div>
          <div className="col-5">Tier</div>
        </div>
        <div className="basicList unLinked">
          <ul>{renderContractSources(roleList)(accSourcesToRender)}</ul>
        </div>
        {anyGood(roleList)
          && <div className="addList">
            <div className="col-5 alignCenter">
              <div className="selector">
                <select
name="baseGpo"
                  onChange={handleGpoSelect}
                >
                  <option disabled="disabled" selected="true" className="defaultOption" value="default">- Select GPO -</option>
                  {renderGPOOptions(gpos)}
                </select>
              </div>
            </div>
            <div className="col-5 alignCenter">
              <div className="selector">
                <select
name="tier"
                  onChange={(e) => gpoManageChange({ selectedTier: e.target.value })}
                >
                  <option disabled="disabled" selected="true" className="defaultOption" value="default">- Select Tier -</option>
                  {renderTierOptions(selectedTier)(tiersToRender)}
                </select>
              </div>
            </div>
            <div className="col-2 alignCenter">
              <button
                className="btnSave"
                onClick={() => {
                  if (isEmpty(selectedTier) || contains(parseInt(selectedTier), listOfSourceNumbers)) return
                  else addAccountSource(accountId, selectedTier)
                }} />
            </div>
             </div>}
      </div>
    )
  }
}

export default GpoList
