import { reject, propEq, pathOr } from 'ramda'
import axios from '../Essentials/axios'

export const FETCH_SOURCES = 'FETCH_SOURCES'
export const ADD_FIELD_CHANGES = 'ADD_FIELD_CHANGES'
export const ADD_SOURCE = 'ADD_SOURCE'
export const REMOVE_SOURCE = 'REMOVE_SOURCE'
export const CLEAR_CON_SUBMIT = 'CLEAR_CON_SUBMIT'

export const getSources = () => axios.get('/sources')
        .then((response) => {
            const sources = pathOr([], ['data'])(response)

            return {
                type: FETCH_SOURCES,
                payload: sources,
            }
        })
        .catch((e) => console.log(e))


export const addBaseGpo = (baseGpo, baseGpoList) => axios.post('/gpo/add', { baseGpo })
        .then(({ data }) => ({
            type: ADD_SOURCE,
            payload: data,
            fieldChanges: { baseFieldValue: '' },
        }))
        .catch((e) => console.log(e))


export const addTier = (baseGpo, tier, tierList) => axios.post('/tier/add', {
        baseGpo,
        tier,
    })
        .then(({ data }) => ({
            type: ADD_SOURCE,
            payload: data,
            fieldChanges: { tierFieldValue: '' },
        }))
        .catch((e) => console.log(e))

export const removeTier = (tierId) => axios.post('/tier/remove', {
        tierId,
    })
        .then((response) => {
            const source = response.data

            return {
                type: REMOVE_SOURCE,
                payload: source._id,
            }
        })

export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case FETCH_SOURCES:
            return action.payload
        case ADD_SOURCE:
            return [action.payload, ...init]
        case REMOVE_SOURCE:
            return reject(propEq('_id')(action.payload))(init)
        case CLEAR_CON_SUBMIT:
            return []
        default:
            return init
    }
}
