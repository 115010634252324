import {
    compose, objOf, values, gte,
    mapObjIndexed as mapo, replace, head, curry,
    isEmpty, keys, pick, difference, __,
} from 'ramda'

export const unpick = curry((props, obj) => {
    const objKeys = keys(obj)
    return pick(difference(__, props)(objKeys))(obj)
})

// eslint-disable-next-line no-useless-escape
const escapeRegExp = (str) => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
const makeRegex = (val) => new RegExp(`^${val}`, 'i')
const finalRegex = compose(makeRegex, escapeRegExp)

export const getRegexObj = mapo(finalRegex)
export const getRegexList = compose(values, mapo((val, key) => objOf(key, finalRegex(val))))

export const enhanceModelString = (string) => replace(/\//, '', string.toUpperCase())

export const dateString = (date) => `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`

export const compatibleDateString = (date) => {
    const fullYear = date.getUTCFullYear()
    const rawMonth = date.getUTCMonth() + 1
    const rawDay = date.getUTCDate()
    const enhanceM = (month) => {
        if (gte(month, 10)) return month
        else return `0${month}`
    }
    const enhanceD = (day) => {
        if (gte(day, 10)) return day
        else return `0${day}`
    }
    return `${fullYear}-${enhanceM(rawMonth)}-${enhanceD(rawDay)}`
}

export const getCurrentDate = () => {
    const today = new Date()

    return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
}

export const nearestNth = curry((n, number) => Math.round(n * number) / n)

export const greatestNth = curry((n, number) => Math.ceil(n * number) / n)

export const lowestNth = curry((n, number) => Math.floor(n * number) / n)

export const makeWhole = (number) => {
    if (Math.floor(number) === number) return Math.floor(number)

    else return number
}

// courtesy of Asher
// const isSubString = (a, b) => {
//     if (a === '') return true

//     const headA = head(a)

//    if (contains(headA, b)) return isSubString(tail(a), slice(indexOf(headA, b) + 1, Infinity, b))

//     else return false
// }

export const sliceToWhiteSpace = (str) => {
    if (isEmpty(str)) return str

    else return head((/\w+/).exec(str))
}
export const roundDecimal = (s) => parseFloat(parseFloat(s).toFixed(2))

export const addChatWidget = () => {
    const script = document.createElement('script')

    script.src = '//code.tidio.co/80q5bi3bzrnckh3oigbcgxczy72grnoi.js'
    script.id = 'chatwidget'

    document.body.appendChild(script)
}
