import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { length, pathOr, map } from 'ramda'
import { Tabs, TabLink, TabContent } from 'react-tabs-redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import Tooltip from '@material-ui/core/Tooltip'
import roleManagement from '../HOC/RoleManagement'
import InfinityScroll from '../HOC/Infinity'
import SortableHeader from '../Reusable/SortableHeader'
import { dateString } from '../utilities/util'
import { mapDispatch, mapSpinToDispatch } from '../utilities/redux-util'
import Search from './Search'
import {
    getInitSpas, getPageOfSpas, searchSpas, switchTab,
} from './agreements_page_reducer'
import { selectFirstPage, incrementPage, clearModelsToView } from '../actions/index'

class AllSpas extends Component {
    state = {
        // The initial state is used to style the table headers and indicate to user which column the table is being sorted by and in which direction:
        sort: {
            field: 'createdAt',
            direction: 'desc',
        },
    }

    componentDidMount() {
        const {
            selectFirstPage, getInitSpas, history, isAdmin, isSalesManager, isInsideSales, isSalesRep, isPurchaseManager, isPurchaseAssistant, redSpin,
        } = this.props

        if (!isAdmin && !isSalesManager && !isSalesRep && !isInsideSales && !isPurchaseAssistant && !isPurchaseManager) history.push('/contracts')

        else {
            selectFirstPage()
            redSpin(getInitSpas(1), 'mainlist')
        }
    }


    render() {
        const {
            paginatedAgreements, switchTab, currentPage,
            searchTerms, getPageOfSpas, incrementPage, selectFirstPage,
            spin, redSpin,
        } = this.props

        const {
            agreements,
            totals,
            currentTab,
        } = paginatedAgreements

        const {
            all, hasPending, hasRejected, approved,
        } = totals
        const {
            allAgrees, hasPendingAgrees, hasRejectedAgrees, approvedAgrees,
        } = agreements

        const sort = (field) => {
            const { direction } = this.state.sort
            const newSortDirection = field !== this.state.sort.field ? 'asc' : direction === 'asc' ? 'desc' : 'asc'
            // if user clicked on new table head the first direction will be ascending, if not the direction will simply be toggled on each click

            window.scrollTo({ top: 0, behavior: 'smooth' })
            // scrolling to the top of the results on each sort

            this.setState({ sort: { field, direction: newSortDirection } },
                async () => {
                    const sort = { [field]: this.state.sort.direction }
                    await redSpin(this.props.getInitSpas(1, undefined, sort, currentTab))
                    selectFirstPage()
                })
        }

        const renderTableHeader = () => {
            const headers = [
                { label: 'ID', name: '_id', col: '1', sortable: true, },
                { label: 'Name', name: 'entityId', col: '1', sortable: true, },
                { label: 'Customer', name: 'firstAccount.entityId', col: '3', sortable: false, },
                { label: 'Sales Rep', name: 'salesRepId.name.last', col: '3', sortable: false, },
                { label: 'Date Created', name: 'createdAt', col: '2', sortable: true, },
                { label: 'Expiration Date', name: 'endDate', col: '2', sortable: true, },
            ]

            return (
                <div className="innerBackground">
                    <div className="headerBasicList">
                        {headers.map((h, index) => <SortableHeader key={index} label={h.label} name={h.name} columnWidth={h.col} sortable={h.sortable} selected={this.state.sort.field === h.name} direction={this.state.sort.direction} clickHandler={sort} />)}
                    </div>
                </div>
            )
        }

        const renderSpa = (agreement) => {
            const {
                _id, entityId, endDate, createdAt,
            } = agreement
            const accountIdentifier = pathOr('', ['firstAccount', 'entityId'])(agreement)
            const firstName = pathOr('', ['salesRepId', 'name', 'first'])(agreement)
            const lastName = pathOr('', ['salesRepId', 'name', 'last'])(agreement)
            const expDate = dateString(new Date(endDate))
            const creationDate = createdAt ? dateString(new Date(createdAt)) : ''

            return (
                <li key={_id}>
                    <Link to={`/spa?id=${_id}`}>
                        <div className="col-1">{_id}</div>
                        <div className="col-1">{entityId}</div>
                        <div className="col-3 alignLeft bolder divider">{accountIdentifier}</div>
                        <div className="col-3 divider">{firstName} {lastName}</div>
                        <div className="col-2">{creationDate}</div>
                        <div className="col-2">{expDate}</div>
                    </Link>
                </li>
            )
        }

        const renderSpas = (agreements) => (
            <div className="basicList">
                <ul>
                    {map(renderSpa)(agreements)}
                </ul>
            </div>
        )

        const renderTabContent = (title, agreements, total) => {
            const loadNextPage = () => {
                const sort = { [this.state.sort.field]: this.state.sort.direction }
                // console.log('al', agreements.length)
                // console.log('total', total)
                if (agreements.length >= total || title != currentTab) return Promise.resolve()
                incrementPage()
                return redSpin(getPageOfSpas(currentPage + 1, searchTerms, sort), 'loading')
            }

            return <TabContent for={title}>
                <InfinityScroll loadMore={loadNextPage} />
                {renderTableHeader()}
                {spin.mainlist ? (<div className="basicList"><div className="loader" /></div>) : (
                    <div>
                        {renderSpas(agreements)}
                        {(total == 0) && renderNoResults()}
                        {<div className={`btnArea${spin.loading ? ' loader' : ''}`} />}
                    </div>)}
            </TabContent>
        }

        const renderShowingOfTotal = (tab, totals, agreements) => (
            <p>Showing <span className="bolder">{length(agreements[`${tab}Agrees`])}</span> of <span>{totals[tab]}</span> Agreements</p>
        )

        const renderNoResults = () => (
            <div className="errorMsg" />
        )

        return (
            <div className="listPage">
                <div className="headerArea withTab">
                    <div className="headerSticky">
                        <div>
                            <h2>Agreements
                                <Tooltip title="Download CSV" placement="right">
                                    <NavLink to="/downloadspa">
                                        <button
                                            style={{ marginLeft: '10px' }}
                                            className={`btnDownload tooltipped btnSmall`}
                                            data-position="top"
                                            data-tooltip="Download Agreements"
                                        />
                                    </NavLink>
                                </Tooltip>
                            </h2>
                            {renderShowingOfTotal(currentTab, totals, agreements)}
                        </div>
                        <div className={`searchLoading${spin['/spasSearch'] ? ' loading' : ''}`}>
                            <Search {...this.props} />
                        </div>
                    </div>
                </div>
                <div className="tabLists">
                    <Tabs
                        handleSelect={(selectedTab) => switchTab(selectedTab)}
                        selectedTab={currentTab}
                    >
                        <div className="headerTab">
                            <TabLink to="all">All</TabLink>
                            <TabLink to="hasPending">Pending</TabLink>
                            <TabLink to="hasRejected">Rejected</TabLink>
                            <TabLink to="approved">Approved</TabLink>
                        </div>
                        {renderTabContent('all', allAgrees, all)}
                        {renderTabContent('hasPending', hasPendingAgrees, hasPending)}
                        {renderTabContent('hasRejected', hasRejectedAgrees, hasRejected)}
                        {renderTabContent('approved', approvedAgrees, approved)}


                    </Tabs>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    paginatedAgreements: state.paginatedAgreements,
    currentPage: state.currentPage,
    searchTerms: getFormValues('Search')(state),
    spin: state.spin,
})

const md2p = mapDispatch({
    getInitSpas,
    getPageOfSpas,
    searchSpas,
}, {
    switchTab,
    selectFirstPage,
    incrementPage,
    clearModelsToView,
}, (dispatch) => ({ redSpin: mapSpinToDispatch(dispatch) }))
export default connect(mapStateToProps, md2p)(roleManagement(AllSpas))
