const FETCH_INIT_ACCOUNTS = 'FETCH_INIT_ACCOUNTS'
const FETCH_PAGE_ACCOUNTS = 'FETCH_PAGE_ACCOUNTS'
const SEARCH_ACCOUNTS = 'SEARCH_ACCOUNTS'

export default (state, action) => {
    const init = state || 0

    switch (action.type) {
        case FETCH_INIT_ACCOUNTS: case FETCH_PAGE_ACCOUNTS: case SEARCH_ACCOUNTS:
            return action.total
        default:
            return init
    }
}
