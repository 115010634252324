import React from 'react'
import { pathOr, map, omit, filter, isEmpty } from 'ramda'

const EstimateAgreement = (props) => {
    const {
 accountAgreement, toggleModal, netsuiteEstimateAccountAgreement, spin, redSpin, getBestCostWithSource, changeMessage
} = props
    const accountId = pathOr('', ['accountId'])(accountAgreement)
    const priceAgreements = pathOr([], ['priceAgreements'])(accountAgreement)
    const salesRepId = pathOr('', ['masterId', 'salesRepId'])(accountAgreement)
    const accountIdentifier = pathOr('', ['accountId', 'entityId'])(accountAgreement)
    const masterIdentifier = pathOr('', ['masterId', 'entityId'])(accountAgreement)

    const priceAgreementsWithActiveItems = filter(priceAgreement => priceAgreement.isInactive == false, priceAgreements)
    const hasActiveItems = !isEmpty(priceAgreementsWithActiveItems)
    
    const EstimateAgreeOnClick = () => {
    
        const priceAgreementsWithCost = map((m) => ({
            ...omit(['contracts'], m),
            cost: pathOr('', ['price'], getBestCostWithSource(m.expDate, m.contracts)).toFixed(2),
        }))(priceAgreementsWithActiveItems)

        /* The errors thrown are not making it to the catch because the redSpin util resolves it, so it always ends up in the then...did not want to change util */
        redSpin(netsuiteEstimateAccountAgreement(accountId, priceAgreementsWithCost, masterIdentifier, salesRepId), 'createEstimate')
        .then(response => {

            if (response.hasError )
                changeMessage(response.message)

            toggleModal({ spaEstimate: false })
        })
    
    }
    // const cost = pathOr('', ['price'], getBestCostWithSource(expDate, contracts))

    return (
        <div>
            <div className="modal">
                <div className="modalPopup">
                    <div className="modalEstimateSPA modalEstimateLogo" />
                    <div className="modalEstimateBody">
                        <h4>Netsuite Estimate</h4>
                        <p style={{ paddingTop: '0', fontSize: '14px' }}>{accountIdentifier}</p>
                        <p style={{ fontSize: '14px', color: 'grey' }}>Inactive items will not be added to estimate</p>
                        {!hasActiveItems && 
                        <p style={{ fontSize: '14px', color: 'red' }}>Cannot create estimate without active items!</p>
                        }

                    </div>
                    <div>
                        <button onClick={() => toggleModal({ spaEstimate: false })}> No </button>
                        {spin.createEstimate
                            ? <button className="globalSearch loading">Yes</button>
                            : <button onClick={EstimateAgreeOnClick} disabled={!hasActiveItems}> Yes </button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EstimateAgreement
