import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  compose as c, addIndex, map,
  curry, equals, prop, groupBy, isEmpty, contains, anyPass, sortBy,
  reduce, min, toPairs, last, head, pathOr, or, objOf,
} from 'ramda'
import {
 getSources, addBaseGpo, addTier, removeTier,
} from '../reducers/sources_reducer'
import { addFieldChanges } from './gpo_list_reducer'
import RoleManagement from '../HOC/RoleManagement'
import { mapDispatch, mapSpinToDispatch, isFatal } from '../utilities/redux-util'
import ErrorPage from '../Essentials/ErrorPage'

const makeDate = (str) => new Date(str)

const handleBack = (history) => {
  if (anyPass([equals(0), equals(1), equals(2)])(history.length)) history.push('/')

  else history.goBack()
}

const mapIndexed = addIndex(map)

const renderGpo = curry((selectedGpo, addFieldChanges, currentGpo, index) => (
    <li
      key={index}
      className={`${(equals(selectedGpo)(currentGpo)) ? ' select' : ''}`}
      onClick={() => addFieldChanges({ selectedGpo: currentGpo })}
    >
      <div className="col-10 alignLeft bolder">{currentGpo}</div>
    </li>
  ))

const renderGpos = c(mapIndexed, renderGpo)

const renderTier = curry((removeTier, source, index) => {
  const sourceToRender = source || {}
  const { tier, _id } = sourceToRender
  const tierName = tier || ''
  return (
    <li key={index}>
      <div className="col-10 alignLeft bolder">{tierName}</div>
      <div className="col-2"><button className="btnRemove" onClick={() => removeTier(_id)} /></div>
    </li>
  )
})

const renderTiers = c(mapIndexed, renderTier)

const groupAndSortGpos = (sources) => {
  const getDatesPer = map(c(makeDate, prop('createdAt')))
  const reduceMinDatePer = reduce(min)(Infinity)
  const getMinDatePer = c(reduceMinDatePer, getDatesPer)
  const sortAllMinDatePairs = c(sortBy(last), toPairs, map(getMinDatePer))

  const groupedSources = groupBy(prop('baseGpo'))(sources)
  const sortedPairs = sortAllMinDatePairs(groupedSources)
  const gpoNames = map(head)(sortedPairs)

  return gpoNames
}

const getInitialSelect = (sources) => c(head, groupAndSortGpos)(sources) || ''

class AllGpos extends Component {
  componentDidMount() {
    const {
 getSources, isAdmin, isSalesManager, isPurchaseAssistant, isPurchaseManager, history, redSpin, addFieldChanges,
} = this.props
    if (!(isAdmin || isPurchaseAssistant || isPurchaseManager || isSalesManager)) history.push('/')

    else {
      const sources = getSources()
        .then((res) => c(addFieldChanges, objOf('selectedGpo'), getInitialSelect, pathOr([], ['payload']))(res))
      isFatal(sources)
      redSpin(sources, 'detailpage')
    }
  }

  render() {
    const {
 pageSuccess, sources, addBaseGpo, addTier, removeTier, addFieldChanges, gpoListValues, spin, history,
} = this.props

    // Just show spinner if not loaded
    if (spin.detailpage) return <div className="loader" />
    if (pageSuccess === 'INSUFFICIENT') return <ErrorPage history={history} />

    const { baseFieldValue, tierFieldValue, selectedGpo } = gpoListValues

    const gpoNames = groupAndSortGpos(sources)
    const groupedSources = groupBy(prop('baseGpo'))(sources)
    const sortTiers = sortBy(c(makeDate, prop('createdAt')))
    const unsortedTiers = prop(selectedGpo)(groupedSources) || []
    const tiersToRender = sortTiers(unsortedTiers)
    const tierNames = map(prop('tier'))(tiersToRender)

    return (

      <div className="contentDetail">
        <div className="headerDetail">
          <button className="btnPrev" onClick={() => handleBack(history)} />
          <div className="titleDetail gpos">
            <div className="infoIcon" />
            <h2>Manage GPOs</h2>
          </div>
        </div>
        <div className="listDetail gpos">
          <div className="tabOnDetail bolder">GPO</div>
          <div className="basicList">
            <ul>{renderGpos(selectedGpo, addFieldChanges)(gpoNames)}</ul>
            <div className="addList">
              <div className="col-10">
                <input
                  className="col-10"
                  type="text"
                  value={baseFieldValue}
                  onChange={(e) => addFieldChanges({ baseFieldValue: e.target.value })}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (or(contains(baseFieldValue)(gpoNames), isEmpty(baseFieldValue))) return
                      addBaseGpo(baseFieldValue, gpoNames)
                    }
                  }}
                  name="GPO"
                  placeholder="New GPO Name" />
              </div>
              <div className="col-2 alignCenter">
                <button
                  className="btnSave"
                  onClick={() => {
                    if (or(contains(baseFieldValue)(gpoNames), isEmpty(baseFieldValue))) return
                    addBaseGpo(baseFieldValue, gpoNames)
                  }} />
              </div>
            </div>
          </div>
        </div>
        <div className="listDetail tiers">
          <div className="tabOnDetail bolder">Tiers For <div>&nbsp;&nbsp;</div><strong>{selectedGpo}</strong></div>
          {(contains(selectedGpo)(gpoNames)) && <div className="basicList unLinked">
            <ul>{renderTiers(removeTier)(tiersToRender)}</ul>
            <div className="addList">
              <div className="col-10">
                <input
                  className="col-10"
                  type="text"
                  value={tierFieldValue}
                  onChange={(e) => addFieldChanges({ tierFieldValue: e.target.value })}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (or(contains(tierFieldValue)(tierNames), isEmpty(tierFieldValue))) return
                      addTier(selectedGpo, tierFieldValue, tierNames)
                    }
                  }}
                  name="Tier"
                  placeholder="New Tier Name" />
              </div>
              <div className="col-2 alignCenter">
                <button
                  className="btnSave"
                  onClick={() => {
                    if (or(contains(tierFieldValue)(tierNames), isEmpty(tierFieldValue))) return
                    addTier(selectedGpo, tierFieldValue, tierNames)
                  }} />
              </div>
            </div>
                                                </div>}
        </div>
      </div>
    )
  }
}

const md2p = mapDispatch({
  getSources,
  addBaseGpo,
  addTier,
  removeTier,
}, { addFieldChanges }, (dispatch) => ({ redSpin: mapSpinToDispatch(dispatch) }))

export const ms2p = ({
 pageSuccess, sources, gpoListValues, spin,
}) => ({
 pageSuccess, sources, gpoListValues, spin,
})

export default c(RoleManagement, connect(ms2p, md2p))(AllGpos)
