import axios from '../Essentials/axios'

const MERGE_SPA_DETAILS = 'MERGE_SPA_DETAILS'
const CLEAR_SPA_CREATE = 'CLEAR_SPA_CREATE'

export const addToSPACreate = (detailsObj) => ({ type: MERGE_SPA_DETAILS, payload: detailsObj })

export const clearSPACreate = () => ({ type: CLEAR_SPA_CREATE })

export const postMasterSPA = (SPA, history) => axios.post('/addpriceagreement', { ...SPA })
        .then((res) => {
            history.push(`/spa?id=${res.data.masterId}`)

           return ({ type: CLEAR_SPA_CREATE })
        })
        .catch((e) => console.log(e))

export default (state, action) => {
    const init = state || {
        stage: 1,
        entityId: '',
        account: {},
        expDate: '',
    }

    switch (action.type) {
        case MERGE_SPA_DETAILS:
            return { ...init, ...action.payload }
        case CLEAR_SPA_CREATE:
            return {}
        default:
            return init
    }
}
