import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { getFormValues } from 'redux-form'
import { Tabs, TabLink, TabContent } from 'react-tabs-redux'
import Tooltip from '@material-ui/core/Tooltip'
import {
  compose as c, equals, anyPass, prop, reduce, or, pathOr, uniq,
} from 'ramda'
import ItemList from './ItemList'
import AccountList from './AccountList'
import { getModel, clearModel } from '../actions/index'
import { toggleModal } from '../reducers/modal_reducer'
import { deleteContract } from '../reducers/current_contract_reducer'
import { dateString } from '../utilities/util'
import HistoryModal from '../HistoryLogs/HistoryModal'
import { mapDispatch, mapSpinToDispatch, isFatal } from '../utilities/redux-util'
import RoleManagement from '../HOC/RoleManagement'
import ErrorPage from '../Essentials/ErrorPage'


const renderSourceInfo = (contract) => {
  const baseGpo = pathOr('Local Vendor Contract', ['contractSource', 'baseGpo'])(contract)
  const sourceName = pathOr('', ['contractSource', 'tier'])(contract)

  return (
    <div className="borderTop">
      <li>
        <p>SOURCE</p>
        <span>{baseGpo}</span>
      </li>
      <li>
        <p>TIER</p>
        <span>{sourceName}</span>
      </li>
    </div>
  )
}

const renderManuInfo = (contract) => {
  const manuId = pathOr('', ['manufacturerId', 'entityId'])(contract)
  const manuName = pathOr('', ['manufacturerId', 'name'])(contract)

  return (
    <li>
      <p>VENDOR</p>
      <span>
        {`${manuId} - ${manuName}`}
      </span>
    </li>
  )
}


const handleBack = (history) => {
  if (anyPass([equals(0), equals(1), equals(2)])(history.length)) history.push('/contracts')
  else history.goBack()
}

const anyGood = reduce(or)(false)

class ContractPage extends Component {
  state = {
    historyOpen: false
  }

  componentDidMount() {
    const { getModel, redSpin } = this.props
    const params = queryString.parse(this.props.location.search)
    const { pathname } = this.props.location
    const contractId = params.id

    redSpin(getModel(pathname, contractId), 'detailpage')
  }

  componentDidUpdate(previousProps) {
    const { getModel, location } = this.props
    const params = queryString.parse(this.props.location.search)
    const { pathname } = this.props.location
    const accountId = params.id
    if (previousProps.location.search !== location.search) {
      getModel(pathname, accountId)
    }
  }

  componentWillUnmount() {
    const { clearModel } = this.props
    clearModel('contract')
  }

  render() {
    const {
      pageSuccess, currentContract, history, modal, toggleModal, deleteContract,
      isAdmin, isSalesManager, isPurchaseManager, isPurchaseAssistant, spin,
    } = this.props

    // Just show spinner if not loaded
    if (spin.detailpage) return <div className="loader" />
    if (pageSuccess === 'INSUFFICIENT') return <ErrorPage history={history} />

    const roleList = [isAdmin, isSalesManager, isPurchaseAssistant, isPurchaseManager]
    const deleteModal = prop('contractDelete')(modal)
    const contractId = prop('_id')(currentContract)
    const conEntity = prop('entityId')(currentContract) || ''
    const itemManufacturerNames = uniq(pathOr([],['contractItems'],currentContract ).filter((v) => v.itemManufacturerName && v.itemManufacturerName !== '').map((v) => v.itemManufacturerName))
    const start = dateString(new Date(currentContract.startDate))
    const end = dateString(new Date(currentContract.endDate))

    const renderDeleteWarning = (deleteModal) => {
      if (deleteModal) {
        return (
          <div>

            <div className="modal">
              <div className="modalPopup">
                <div className="alert">
                  <p>Delete Contract, continue?</p>
                </div>
                <div>
                  <button onClick={() => toggleModal({ contractDelete: false })}> No </button>
                  <button onClick={() => deleteContract(contractId, history)}> Yes </button>
                </div>
              </div>
            </div>
          </div>
        )
      }
    }
    const { historyOpen } = this.state
    return (
      <div className="contentDetail">
        <div className="headerDetail">
          <button className="btnPrev" onClick={() => handleBack(history)} />
          <div className="titleDetail contracts">
            <div className="infoIcon" />
            <h2>{conEntity}</h2>
          </div>
          {anyGood(roleList)
            && <Tooltip title="Edit Contract" placement="top">
              <button className="btnEdit" onClick={() => history.push(`/contractinput?edit=t&id=${contractId}`)} />
            </Tooltip>}
          {anyGood(roleList)
            && <Tooltip title="Delete Contract" placement="top">
              <button
                className="btnDelete"
                onClick={() => toggleModal({ contractDelete: true })}
              />
            </Tooltip>}
          {anyGood(roleList) && renderDeleteWarning(deleteModal)}
          <Tooltip title="Settings Logs" className="minWidthSm" placement="left">
            <button type="button" className="btnLogs" onClick={() => this.setState({ historyOpen: true })} />
          </Tooltip>
        </div>
        <div className="infoBody">
          <ul>
            {currentContract.manufacturerId && renderManuInfo(currentContract)}
            {currentContract.contractSource
              && currentContract.type !== 'lvc'
              && renderSourceInfo(currentContract)}
            <li>
              <p>Start Date</p>
              <span>{start}</span>
            </li>
            <li>
              <p>End Date</p>
              <span>{end}</span>
            </li>
            <li>
              <p>Manufacturer(s)</p>
              <span>{itemManufacturerNames.join(', ')}</span>
            </li>
          </ul>
        </div>
        <div className="listDetail">
          <Tabs>
            <div className="tabOnDetail">
              <TabLink to="tab1" className="tab-link">
                Item List
              </TabLink>
              <TabLink to="tab2" className="tab-link">
                Customer List
              </TabLink>
            </div>
            <TabContent for="tab1">
              <ItemList {...this.props} />
            </TabContent>
            <TabContent for="tab2">
              <AccountList {...this.props} />
            </TabContent>
          </Tabs>
        </div>
        {historyOpen
          && <HistoryModal
            open={historyOpen}
            id={contractId}
            collection="Contract"
            onClose={() => this.setState({ historyOpen: false })}
            title="Contract Logs"
          />}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  modal: state.modal,
  currentContract: state.currentContract,
  itemFilter: getFormValues('ItemFilter')(state),
  accountFilter: getFormValues('AccountFilter')(state),
  spin: state.spin,
  pageSuccess: state.pageSuccess,

})
const md2p = mapDispatch({
  getModel: c(isFatal, getModel),
}, {
  clearModel, toggleModal, deleteContract,
}, (dispatch) => ({ redSpin: mapSpinToDispatch(dispatch) }))
export default RoleManagement(connect(mapStateToProps, md2p)(ContractPage))
