import React from 'react'
import { connect } from 'react-redux'
import { pathOr } from 'ramda'
import { getAgreementOwners, clearUserOptions } from '../actions'
import roleManagement from '../HOC/RoleManagement'

const UserSearch = (props) => {

    const { userOptions, getAgreementOwners, onSelect, clearUserOptions } = props

    const renderOptions = () => (
        <div className="resultBySearch">
            <ul>
                {userOptions.map((user, index) => {
                    const { _id } = user
                    const fullName = pathOr('', ['name', 'first'], user) + ' ' + pathOr('', ['name', 'last'], user)

                    return (
                        <li key={index} onMouseDown={() => onSelect(_id)}>
                            <div className="bolder">{fullName}</div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )

    return (
        <form onSubmit={(e) => { e.preventDefault() }}>
            <input
                placeholder='Search Users'
                onChange={(e) => { getAgreementOwners({ searchTerm: e.target.value }) }}
                onBlur={clearUserOptions}
            />
            {renderOptions()}
        </form>
    )
}

const mapStateToProps = ({ userOptions }) => {
    return { userOptions }
}

export default connect(mapStateToProps, { getAgreementOwners, clearUserOptions })(roleManagement(UserSearch))
