import { assocPath } from 'ramda'
import {
    SWITCH_ACCOUNT_AGREEMENT,
    CLEAR_SPA_PAGE,
    UPDATE_CURRENT_AGREEMENT_OWNER,
    CHANGE_CURRENT_AGREEMENT_COMMENTS,
    REMOVE_CURRENT_AGREEMENT_COMMENTS,
} from '../actions/action_types'

export default (state, { type, payload }) => {
    const init = state || {}

    switch (type) {
        case CHANGE_CURRENT_AGREEMENT_COMMENTS: 
            return { ...state, comments: payload.comments }
        case REMOVE_CURRENT_AGREEMENT_COMMENTS: 
            return { ...state, comments: state.comments.filter(f => f !== payload.comment) }
        case UPDATE_CURRENT_AGREEMENT_OWNER: 
            return assocPath(['masterId', 'salesRepId'], payload, state)
        case SWITCH_ACCOUNT_AGREEMENT:
            return payload
        case CLEAR_SPA_PAGE:
            return {}
        default:
            return init
    }
}