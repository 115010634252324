const FETCH_MANUFACTURER = 'FETCH_MANUFACTURER'
const SELECT_MANUFACTURER = 'SELECT_MANUFACTURER'
const CLEAR_MANUFACTURER = 'CLEAR_MANUFACTURER'
const CLEAR_CON_SUBMIT = 'CLEAR_CON_SUBMIT'

export default (state, action) => {
    const init = state || {}

    switch (action.type) {
        case FETCH_MANUFACTURER:
            return action.payload
        case SELECT_MANUFACTURER:
            return action.payload
        case CLEAR_MANUFACTURER:
            return {}
        case CLEAR_CON_SUBMIT:
            return {}
        default:
            return init
    }
}
