import { FETCH_SOURCES, ADD_SOURCE, ADD_FIELD_CHANGES } from '../reducers/sources_reducer'

export const addFieldChanges = (fieldObj) => ({ type: ADD_FIELD_CHANGES, payload: fieldObj })

export default (state, action) => {
    const init = state || {
        baseFieldValue: '',
        tierFieldValue: '',
        selectedGpo: '',
    }

    switch (action.type) {
        case FETCH_SOURCES:
            return { ...init, ...action.payload }
        case ADD_SOURCE:
            return { ...init, ...action.fieldChanges }
        case ADD_FIELD_CHANGES:
            return { ...init, ...action.payload }
        default:
            return init
    }
}
