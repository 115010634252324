import { mergeWith, concat } from 'ramda'
import axios from '../Essentials/axios'

const FETCH_INIT_SPAS = 'FETCH_INIT_SPAS'
const FETCH_PAGE_SPAS = 'FETCH_PAGE_SPAS'
const SEARCH_SPAS = 'SEARCH_SPAS'
const SWITCH_TAB = 'SWITCH_TAB'
const CLEAR_SPAS = 'CLEAR_SPAS'

export const switchTab = (tab) => ({ type: SWITCH_TAB, payload: tab })

export const getPageOfSpas = (page, searchTerms, sort = null) => axios.get('/spas/paginated', {
    params: {
        page,
        searchTerms,
        sort,
    },
})
    .then(({ data }) => {
        const {
            all, hasPending, hasRejected, approved,
        } = data

        const agreements = {
            allAgrees: all ? all.docs : [],
            hasRejectedAgrees: hasRejected ? hasRejected.docs : [],
            hasPendingAgrees: hasPending ? hasPending.docs : [],
            approvedAgrees: approved ? approved.docs : [],
        }

        return {
            type: FETCH_PAGE_SPAS,
            payload: agreements,
        }
    })

export const searchSpas = (page, searchTerms) => axios.get('/spas/paginated', {
    params: {
        page,
        searchTerms,
    },
})
    .then(({ data }) => {
        const {
            all, hasPending, hasRejected, approved,
        } = data

        const agreements = {
            allAgrees: all ? all.docs : [],
            hasRejectedAgrees: hasRejected ? hasRejected.docs : [],
            hasPendingAgrees: hasPending ? hasPending.docs : [],
            approvedAgrees: approved ? approved.docs : [],
        }

        const totals = {
            all: all ? all.total || all.totalDocs : 0,
            hasPending: hasPending ? hasPending.total || hasPending.totalDocs : 0,
            hasRejected: hasRejected ? hasRejected.total || hasRejected.totalDocs : 0,
            approved: approved ? approved.total || approved.totalDocs : 0,
        }

        return {
            type: SEARCH_SPAS,
            payload: {
                agreements,
                totals,
            },
        }
    })

export const getInitSpas = (page, searchTerms, sort = null, currentTab = 'all') => axios.get('/spas/paginated', {
    params: {
        page,
        searchTerms,
        sort,
    },
})
    .then(({ data }) => {
        const {
            all, hasPending, hasRejected, approved,
        } = data

        const agreements = {
            allAgrees: all ? all.docs : [],
            hasRejectedAgrees: hasRejected ? hasRejected.docs : [],
            hasPendingAgrees: hasPending ? hasPending.docs : [],
            approvedAgrees: approved ? approved.docs : [],
        }

        const totals = {
            all: all ? all.total || all.totalDocs : 0,
            hasPending: hasPending ? hasPending.total || hasPending.totalDocs : 0,
            hasRejected: hasRejected ? hasRejected.total || hasRejected.totalDocs : 0,
            approved: approved ? approved.total || approved.totalDocs : 0,
        }

        return {
            type: FETCH_INIT_SPAS,
            payload: {
                agreements,
                totals,
                currentTab,
            },
        }
    })


export default (state, action) => {
    const init = state || {
        agreements: {
            allAgrees: [], hasRejectedAgrees: [], hasPendingAgrees: [], approvedAgrees: [],
        },
        totals: {
            all: 0, hasPending: 0, hasRejected: 0, approved: 0,
        },
        currentTab: 'all',
    }

    switch (action.type) {
        case FETCH_INIT_SPAS:
            return action.payload
        case FETCH_PAGE_SPAS:
            {
                const agreements = mergeWith(concat)(init.agreements)(action.payload)
                return { ...init, agreements }
            }
        case SEARCH_SPAS:
            return { ...init, ...action.payload }
        case SWITCH_TAB:
            return { ...init, currentTab: action.payload }
        case CLEAR_SPAS:
            return []
        default:
            return init
    }
}
