import React, { createRef, useState } from 'react'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import DatePicker from 'react-datepicker'
import {
    prop, map, addIndex, curry, compose, equals, sortBy, lte, anyPass,
    filter, head, propEq, complement, concat, toUpper,
    assoc, propOr, isEmpty, pathOr, converge, tail,
    reject, isNil, contains,
} from 'ramda'
import { lowestNth, dateString } from '../utilities/util'
import InactiveItemWarning from '../Reusable/InactiveItemWarning'
import '../../node_modules/react-datepicker/dist/react-datepicker.css'

const isEmptyOrNil = anyPass([isEmpty, isNil])
const capitalizeFirst = converge(concat, [compose(toUpper, head), tail])

const mapIndexed = addIndex(map)
const lowestInt = lowestNth(1)
const isANum = complement(isNaN)
const computeMargin = (cost, sellPrice) => (cost === 0 || !cost ? 'No Cost' : lowestInt((1 - cost / sellPrice) * 100))

const getBestCost = (itemExpDate, itemContracts) => {
    const filterEligible = filter(prop('isEligible'))
    /* Ramda's lte seems to behave nicely when comparing two moment dates */
    const afterEndDate = compose(lte(itemExpDate), moment, prop('endDate'))
    const filterEndDates = filter(anyPass([afterEndDate, prop('isPurchasePrice')]))
    const sortByPrice = sortBy(prop('price'))
    const findCost = compose(prop('price'), head, sortByPrice, filterEndDates, filterEligible)

    return findCost(itemContracts) || ''
}

const renderContractList = (item) => {
    const { cost } = item
    const contracts = pathOr([], ['contracts'])(item)

    if (isEmpty(contracts)) return <div className="errorMsg" />

    const renderContract = (contract, index) => {
        const rawEndDate = pathOr('', ['endDate'])(contract)
        const endDate = isEmpty(rawEndDate) ? '' : dateString(new Date(rawEndDate))
        const manuName = pathOr('', ['manuName'])(item)
        const conPrice = pathOr('', ['price'])(contract)
        const formattedConPrice = isEmpty(conPrice) ? `$${conPrice}` : `$${conPrice.toFixed(2)}`
        const contractIdentifier = pathOr('', ['contractEntity'])(contract)
        const isPurchasePrice = pathOr(false, ['isPurchasePrice'])(contract)
        const type = isPurchasePrice ? 'Purchase Price' : compose(toUpper, pathOr('', ['type']))(contract)
        const isEligible = pathOr(false, ['isEligible'])(contract)
        return (
            <li key={index} className={equals(conPrice, cost) && isEligible ? 'selected' : ''}>
                <div className="col-2">{contractIdentifier}</div>
                <div className="col-4 alignLeft bolder">{manuName}</div>
                <div className={`col-1 bolder${isEligible ? ' eligible' : ''}`}>{formattedConPrice}</div>
                <div className="col-2">{type}</div>
                <div className="col-2">{endDate}</div>
                <div className="col-1" />
            </li>
        )
    }

    const renderContracts = mapIndexed(renderContract)

    return renderContracts(contracts)
}

const renderContractsForItem = (item) => (
    <div className="showContracts col-12">
        <div className="headerBasicList">
            <div className="col-2">ID</div>
            <div className="col-4">Vendor</div>
            <div className="col-1">Price</div>
            <div className="col-2">Type</div>
            <div className="col-2">Expiration Date</div>
            <div className="col-1" />
        </div>
        <div className="basicList">
            <ul>
                {renderContractList(item)}
            </ul>
        </div>
    </div>
)

const findItemMods = (itemId, editsList) => compose(head, filter(propEq('item')(itemId)))(editsList)

const getItemDefaults = curry((editsList, itemAgreement) => {
    const { item: itemId, sellPrice: uneditedSellPrice, expDate: uneditedDate } = itemAgreement
    const itemMods = findItemMods(itemId, editsList) || {}
    const rawSellPrice = prop('sellPrice')(itemMods)
    const sellPrice = isANum(rawSellPrice) ? rawSellPrice : uneditedSellPrice

    const contracts = pathOr([], ['contracts'])(itemMods)
    const expDate = compose(moment.utc, propOr(moment.utc(uneditedDate), 'expDate'))(itemMods)
    const cost = getBestCost(expDate, contracts)
    const manuName = pathOr([], ['manuName'])(itemMods)
    const showContracts = pathOr(false, ['showContracts'])(itemMods)
    const exclude = pathOr(false, ['exclude'])(itemMods)

    return {
        item: itemId,
        cost,
        expDate,
        sellPrice,
        contracts,
        manuName,
        showContracts,
        exclude,
    }
})

const handlePriceChange = (editsList, itemId, addEditMods, sellPrice) => {
    const itemMods = findItemMods(itemId, editsList) || {}
    const updatedMods = { ...itemMods, sellPrice, item: itemId }

    addEditMods(updatedMods)
}

const handleDateChange = (editsList, itemId, addEditMods, expDate) => {
    const itemMods = findItemMods(itemId, editsList) || {}

    if (isNil(expDate)) addEditMods({ ...itemMods, expDate: '', item: itemId })
    else addEditMods({ ...itemMods, expDate, item: itemId })
}

const handleExclude = (editsList, itemId, addEditMods) => {
    const itemMods = findItemMods(itemId, editsList) || {}
    const excludedBool = prop('exclude')(itemMods)
    const updatedMods = { ...itemMods, exclude: !excludedBool, item: itemId }
    addEditMods(updatedMods)
}

const handleContractToggle = (editsList, itemId, addEditMods) => {
    const itemMods = findItemMods(itemId, editsList) || {}
    const showBool = prop('showContracts')(itemMods)
    const updatedMods = { ...itemMods, showContracts: !showBool, item: itemId }
    addEditMods(updatedMods)
}

const handleEditSubmit = (submitEdited, editsList, status, accountAgreement, accountAgreements, dispatch, toggleModal) => {
    const filterExcluded = filter(compose(equals(false), propOr(false, 'exclude')))
    const priceAgreements = filterExcluded(editsList)
    const updateStatusAndAgreements = compose(assoc('status', status), assoc('priceAgreements', priceAgreements))
    const modifiedAgreement = updateStatusAndAgreements(accountAgreement)

    const checkSellPrice = (agreement) => {
        const price = prop('sellPrice')(agreement)
        if (anyPass([equals(0), isEmpty, isNil])(price)) return prop('item')(agreement)
        else return false
    }

    const checkDate = (agreement) => {
        if (compose(isEmptyOrNil, prop('expDate'))(agreement)) return true
        else return false
    }
    const checkDates = map(checkDate)
    const checkSellPrices = map(checkSellPrice)
    const filterFalse = reject(equals(false))
    const priceErrors = compose(filterFalse, checkSellPrices)(priceAgreements)
    const dateErrors = compose(filterFalse, checkDates)(priceAgreements)

    const giveErrorObject = (agreements) => {
        if (isEmpty(agreements)) return { emptyItems: true }
        else return { sellPriceErrors: priceErrors }
    }

    if (!isEmpty(priceAgreements) && isEmpty(priceErrors) && isEmpty(dateErrors)) {
        submitEdited(modifiedAgreement, accountAgreements)
        toggleModal({ spaEdit: false })
    } else {
        dispatch({ type: 'ADD_EDIT_ERRORS', payload: giveErrorObject(priceAgreements) })
        return giveErrorObject(priceAgreements)
    }
}

const renderItem = curry((refs, wholeAgreement, addEditMods, agreementEdit, dispatch, itemAgreement, index) => {
    const { item: itemId } = itemAgreement
    const externalIdentifier = propOr('', 'externalId')(itemAgreement)
    const itemName = propOr('', 'name')(itemAgreement)
    const isInactive = pathOr(false, ['isInactive'],)(itemAgreement)

    const editsList = pathOr([], ['editsList'])(agreementEdit)
    const priceErrors = pathOr({}, ['errors', 'sellPriceErrors'])(agreementEdit)

    const error = contains(itemId, priceErrors)
    const updatedPriceErrors = reject(equals(itemId))(priceErrors)

    const editableItemDefaults = getItemDefaults(editsList, itemAgreement)

    const uneditedSellPrice = prop('sellPrice')(itemAgreement)
    const sellPrice = prop('sellPrice')(editableItemDefaults)

    const cost = pathOr('', ['cost'])(editableItemDefaults)
    const hasCost = cost && cost !== 0
    const formattedCost = isEmpty(cost) ? `$${cost}` : `$${cost.toFixed(2)}`

    const uneditedDate = prop('expDate')(itemAgreement)
    const itemExpDate = prop('expDate')(editableItemDefaults) || ''

    //const contracts = pathOr([], ['contracts'])(editableItemDefaults)
    const exclude = pathOr(false, ['exclude'])(editableItemDefaults)
    const showContracts = pathOr(false, ['showContracts'])(editableItemDefaults)

    const currentMargin = computeMargin(cost, sellPrice)
    const targetMargin = prop('targetMargin')(wholeAgreement)

    return (
        <div key={index} ref={refs[itemId]}>
            <li key={index} className={`${exclude ? 'excluded ' : ''}${(currentMargin < targetMargin) ? 'under' : 'over'}TargetMargin`}>
                <div className={`col-2 icon${!equals(sellPrice, uneditedSellPrice) || !moment(uneditedDate).isSame(itemExpDate) ? ' modified' : ''}`}>{externalIdentifier}</div>
                <div className="col-3 alignLeft bolder divider">{itemName} {isInactive && <InactiveItemWarning />}</div>
                <div className="col-1 targetMargin">{`${currentMargin}${hasCost ? '%' : ''}`}</div>
                <div className="col-1">{formattedCost}</div>
                <div className={`col-2 price ${(error) ? 'error' : ''}`}>
                    <input
                        type="number"
                        className="alignRight"
                        name="sellprice"
                        value={equals(0)(sellPrice) ? '' : sellPrice}
                        placeholder="Insert price"
                        onFocus={() => dispatch({ type: 'ADD_EDIT_ERRORS', payload: { sellPriceErrors: updatedPriceErrors } })}
                        onChange={(e) => handlePriceChange(editsList, itemId, addEditMods, Number(e.target.value))}
                    />
                    {error && <p className="enterPriceError">Please enter price</p>}
                </div>
                <div className="col-2 ofInit divider">
                    <DatePicker
                        selected={itemExpDate}
                        onChange={(date) => handleDateChange(editsList, itemId, addEditMods, date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                </div>
                <div className="col-1 button">
                    <button type="button" className="btnList" onClick={() => handleContractToggle(editsList, itemId, addEditMods)} />
                    <button type="button" className={`btnRemove${exclude ? ' include' : ' exclude'}`} onClick={() => handleExclude(editsList, itemId, addEditMods)} />
                </div>
            </li>
            {showContracts && renderContractsForItem(editableItemDefaults)}
        </div>
    )
})


const EditAgreement = (props) => {
    const {
        modal, accountAgreement, accountAgreements, updateAgreeStatus,
        toggleModal, submitEdited, addEditMods, agreementEdit, agreementMessages,
        clearAgreementEdits, isAdmin, isPurchaseAssistant, isSalesManager, changeMessage, dispatch, clearMessage,
        updateEditList
    } = props


    const [updateDate, setUpdateDate] = useState('')

    const { message } = agreementMessages
    const status = pathOr('pending', ['status'])(agreementEdit)
    const statusToggle = pathOr(false, ['statusToggle'])(agreementEdit)
    const accountEntity = pathOr('', ['accountId', 'entityId'])(accountAgreement)
    const initialAccountItems = propOr([], 'initialAccount')(agreementEdit)
    // const sortedInitialAccount = sortBy(prop('externalId'))(initialAccountItems)
    const excludedItems = propOr([], 'initialBase')(agreementEdit)
    //   const sortedExcluded = sortBy(prop('externalId'))(excludedItems)
    const itemAgreementsToRender = [...initialAccountItems, ...excludedItems]
    const editsList = propOr([], 'editsList')(agreementEdit)
    //const targetMargin = prop('targetMargin')(accountAgreement)

    const refs = itemAgreementsToRender.reduce((acc, value) => {
        acc[value.item] = createRef()
        return acc
    }, {})

    const renderItemList = compose(mapIndexed, renderItem(refs))

    const filterExcluded = filter(compose(equals(false), propOr(false, 'exclude')))
    const priceAgreements = filterExcluded(editsList)

    const renderEmptyAlert = (priceAgreements) => {
        if (isEmpty(priceAgreements)) {
            return (
                <div className="alertMsg">
                    <span>Please include at least one item.</span>
                    {/* <button onClick={clearMessage}></button> */}
                </div>
            )
        }
    }
    const renderAlert = (message) => {
        if (!isEmpty(message)) {
            setTimeout(() => changeMessage(''), 3000)

            return (
                <div className="alertMsg">
                    <span>{message}</span>
                    <button onClick={() => changeMessage('')}>X</button>
                </div>
            )
        }
    }

    const updateAllDates = (date, editsList) => {
        if (!date)
            return
        updateEditList(editsList.map(l => ({ ...l, expDate: date })))
    }

    return (
        <div className="modal">
            <div className="modalEditSPA">
                <div className="modalTitle">
                    <div>
                        {(isAdmin || isSalesManager || isPurchaseAssistant) && <button
                            className={`icon${capitalizeFirst(status)}`}
                            onClick={() => dispatch({
                                type: 'CHANGE_STATUS_OPTIONS',
                                payload: { statusToggle: !statusToggle },
                            })} />}
                        {(!isAdmin && !isSalesManager && !isPurchaseAssistant) && <button className={`disabled icon${capitalizeFirst(status)}`} />}
                        {(isAdmin || isSalesManager || isPurchaseAssistant) && statusToggle
                            && (<div className="statusSelect">
                                <button
                                    className={`iconRejected${equals('rejected')(status) ? ' none' : ''}`}
                                    onClick={() => {
                                        // updateAgreeStatus(accountAgreement, 'rejected', accountAgreements, comment)
                                        dispatch({
                                            type: 'CHANGE_STATUS_OPTIONS',
                                            payload: { statusToggle: false, status: 'rejected' },
                                        })
                                    }}
                                />
                                <button
                                    className={`iconApproved${equals('approved')(status) ? ' none' : ''}`}
                                    onClick={() => {
                                        // updateAgreeStatus(accountAgreement, 'approved', accountAgreements, comment)
                                        dispatch({
                                            type: 'CHANGE_STATUS_OPTIONS',
                                            payload: { statusToggle: false, status: 'approved' },
                                        })
                                    }}
                                />
                                <button
                                    className={`iconPending${equals('pending')(status) ? ' none' : ''}`}
                                    onClick={() => {
                                        // updateAgreeStatus(accountAgreement, 'pending', accountAgreements, comment)
                                        dispatch({
                                            type: 'CHANGE_STATUS_OPTIONS',
                                            payload: { statusToggle: false, status: 'pending' },
                                        })
                                    }}
                                />
                            </div>)}
                    </div>
                    <p>{accountEntity}</p>
                </div>

                <div className="modalContent">
                    {message && renderAlert(message)}
                    {renderEmptyAlert(priceAgreements)}
                    <div className="headerBasicList">
                        <div className="col-2">ID</div>
                        <div className="col-3">Name</div>
                        <div className="col-1">Margin</div>
                        <div className="col-1">Cost</div>
                        <div className="col-2">Price</div>
                        <div className="col-2">Expiration Date</div>
                        <div className="col-1" />
                    </div>

                    <div className="basicList mt8 unLinked">
                        <ul>
                            <li className="updateDateHeader">
                                <div className="col-8" />
                                <div className="col-2">
                                    <DatePicker
                                        selected={updateDate}
                                        onChange={(date) => setUpdateDate(date)}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <div className="col-2">
                                    <Button onClick={() => updateAllDates(updateDate, editsList)}>Update</Button>
                                </div>
                                {/* <div className="col-2" /> */}
                            </li>
                            {renderItemList(accountAgreement, addEditMods, agreementEdit, dispatch)(itemAgreementsToRender)}
                        </ul>
                    </div>
                </div>
                <div className="modalBtns">
                    <button onClick={() => {
                        toggleModal({ spaEdit: false })
                        clearAgreementEdits()
                    }}> Cancel
                    </button>
                    <button onClick={() => {
                        const errored = handleEditSubmit(submitEdited, editsList, status, accountAgreement, accountAgreements, dispatch, toggleModal)
                        if (errored && !isEmpty(errored.sellPriceErrors)) {
                            refs[head(errored.sellPriceErrors)].current.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                            })
                        }
                    }}> Submit Changes
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EditAgreement
