import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MUIDataTable from "mui-datatables"
import NativeSelect from '@material-ui/core/NativeSelect'
import BootstrapInput from './BootstrapInput'
import { pathOr, find, propEq, compose, filter, addIndex, map, prop, head, isEmpty } from 'ramda'
import { dateString, lowestNth } from '../utilities/util'
import RoleManagement from '../HOC/RoleManagement'
import { getContractUnknownItems, submitConfirmedItems } from '../actions'
import CustomToolbarSelect from './CustomToolbar'


const mapIndexed = addIndex(map)

const getDiscount = (itemId, contractPrice, contractId, uom, selectedUom) => {
    const currentVendorId = pathOr('', ['manufacturerId', '_id'], contractId)
    const filterForVendor = filter(propEq('vendorId', currentVendorId))
    const getWAC = compose(prop('cost'), head, filterForVendor, pathOr([], ['vendors']))

    const wac = getWAC(itemId)
    //const selectedUom = find(propEq('internalId', prop('uom', selectedUom)), uom)
    const purchUnit = find(propEq('internalId', prop('purchaseUnit', itemId)), uom) || {}
    const purchRate = pathOr(1, ['value'], purchUnit)
    const selectedRate = pathOr(1, ['value'], selectedUom)
    const lowestInt = lowestNth(1)
    return contractPrice ? lowestInt(100 * (1 - contractPrice * purchRate / (wac * selectedRate))) : ''
}

const ContractUnknownItems = (props) => {
    const [data, setData] = useState([])
    const [confirmedUnknownItems, setConfirmedUnknownItems] = useState([])

    const {
        isAdmin, isPurchaseManager, isPurchaseAssistant, isSalesManager, contractUnknownItems, submitConfirmedItems
    } = props

    const { foundUnknownItems } = contractUnknownItems

    useEffect(() => {
        if (!isAdmin && !isPurchaseManager && !isPurchaseAssistant && !isSalesManager) {
            return props.history.replace('/')
        }
        props.getContractUnknownItems()
        setData(getData())
        setConfirmedUnknownItems(foundUnknownItems.map((ui) => {
            const uomArr = pathOr([], ['itemId', 'unitsTypeId', 'uom'], ui)
            const selectedUom = uomArr.find(u => getDiscount(ui.itemId, ui.contractPrice, ui.contractId, uomArr, u) > -1) || pathOr({}, ['itemId', 'unitsTypeId', 'uom', '0'], ui)
            return {
                id: ui._id,
                itemId: pathOr(null, ['itemId', '_id'], ui),
                confirmed: false,
                contractPrice: ui.contractPrice,
                unknownItemId: ui.unknownItemId,
                uom: selectedUom.internalId
            }
        }))
        return () => {
            setData([])
        }
    }, [JSON.stringify(foundUnknownItems)])

    useEffect(() => {
        setData(getData())
    }, [JSON.stringify(confirmedUnknownItems)])

    const changeConfirmedProp = (id, field, value, index) => {
        const sendId = id ? id : (confirmedUnknownItems.find((elem, i) => i === index) || {}).id
        setConfirmedUnknownItems(confirmedUnknownItem => confirmedUnknownItem.map((confirmedItem) => {
            if (confirmedItem.id === sendId) {
                return ({
                    ...confirmedItem,
                    [field]: value
                })
            }
            return confirmedItem
        }))
    }

    const confirmAll = () => {
        const allChecked = find(propEq('confirmed', false), confirmedUnknownItems) === undefined
        setConfirmedUnknownItems(confirmedUnknownItem => (confirmedUnknownItem.map((confirmedItem) => ({
            ...confirmedItem,
            confirmed: !allChecked
        }))))
    }

    const getData = () => {

        const { contractUnknownItems: { foundUnknownItems } } = props

        return foundUnknownItems.map((unknownItem) => {
            const associatedConfirmedRecord = confirmedUnknownItems.find((f) => f.id === unknownItem._id)

            const contractId = pathOr('', ['contractId', '_id'], unknownItem)
            const pad = pathOr('', ['contractId', 'pad'], unknownItem)
            const description = pathOr('', ['itemId', 'name'], unknownItem)
            const itemid = pathOr('', ['itemId', '_id'], unknownItem)
            const item = pathOr('', ['itemId', 'externalId'], unknownItem)
            const uom = pathOr([], ['itemId', 'unitsTypeId', 'uom'], unknownItem)
            const mpn = pathOr('', ['vendorCode'], unknownItem)
            const tier = pathOr('', ['contractId', 'contractSource', 'tier'], unknownItem)
            const gpo = pathOr('', ['contractId', 'contractSource', 'baseGpo'], unknownItem)
            const contractName = pathOr(undefined, ['contractId', 'entityId'], unknownItem)
            const contractPrice = pathOr('', ['contractPrice'], unknownItem)
            const vendor = pathOr('', ['contractId', 'manufacturerId', 'name'], unknownItem)
            const endDate = pathOr(undefined, ['contractId', 'endDate'], unknownItem)

            const selectedUom = find(propEq('internalId', prop('uom', associatedConfirmedRecord)), uom)
            const discount = getDiscount(unknownItem.itemId, contractPrice, unknownItem.contractId, uom, selectedUom) //contractPrice ? lowestInt(100 * (1 - contractPrice * purchRate / (wac * selectedRate))) : ''

            const ratio = (pad / 100) + 1
            const paddedPrice = ((contractPrice) * ratio).toFixed(2)
            return {
                _id: unknownItem._id,
                contractId,
                item,
                itemid,
                Vendor: vendor,
                description,
                mpn: mpn,
                tier: tier,
                gpo: gpo,
                contractName,
                contractPrice: `$${paddedPrice}${contractPrice ? ` ($${contractPrice}) ` : ''}`,
                uom: uom,
                endDate: dateString(new Date(endDate)),
                discount: `${discount}%`
            }
        })
    }

    const cols = [
        { name: '_id', options: { display: 'false', filter: false } },
        { name: 'contractId', options: { display: 'false', filter: false } },
        { name: 'itemid', options: { display: 'false', filter: false } },
        { name: 'Vendor', options: { filter: false } },
        {
            name: 'item', label: 'Item', options: {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    const id = pathOr(undefined, ['rowData', '2'], tableMeta)
                    return (<Link to={`/item?id=${id}`} target="_blank">{value}</Link>)
                }
            },
        },
        {
            name: 'description', label: 'Description',
            options: {
                filter: false,
                setCellProps: (value) => ({ style: { maxWidth: '349px' } })
            }
        },
        { name: 'mpn', label: 'MPN', options: { filter: false } },
        { name: 'tier', label: 'Tier', options: { filter: false } },
        { name: 'gpo', label: 'GPO', options: { filter: false } },
        {
            name: 'contractName',
            label: 'Contract',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    const id = pathOr(undefined, ['rowData', '1'], tableMeta)
                    return (<Link to={`/contract?id=${id}`} target="_blank">{value}</Link>)
                }
            }
        },
        { name: 'contractPrice', label: 'Contract Price', options: { filter: false } },
        {
            name: 'uom',
            label: 'UOM',
            options: {
                sort: false,
                //filter: false,
                filterOptions: {
                    names: ['Has UOM', 'No UOM'],
                    logic: (uom, filters) => {
                        if (filters[0] === 'No UOM') {
                            return uom
                        } else if (filters[0] === 'Has UOM') {
                            return !Boolean(uom)
                        }

                    }
                },
                customBodyRender: (value, tableMeta) => {
                    const id = pathOr(undefined, ['rowData', '0'], tableMeta)
                    const confirmedItem = find(propEq('id', id), confirmedUnknownItems) || {}
                    return (value.length > 0 &&
                        <NativeSelect
                            value={confirmedItem.uom || ''}
                            onChange={(e) => {
                                changeConfirmedProp(id, 'uom', e.target.value)
                            }}
                            input={<BootstrapInput name="uom" />}
                        >
                            {value.map((v) => (
                                <option key={v._id} value={v.internalId}>{v.unitName}</option>
                            ))}
                        </NativeSelect>)
                }
            },
        },
        { name: 'discount', label: 'Discount', options: { filter: false } },
        { name: 'endDate', label: 'End Date', options: { filter: false } },
    ]

    return (
        <div className="listPage ">
            <div className='unknownItemsMarginTop'>
                {props.spin['/contractUnknownItems'] ?
                    <div className="loader" /> :

                    <MUIDataTable
                        title="Found Contract Unknown Items"
                        data={data}
                        columns={cols}
                        resizableColumns
                        options={{
                            download: false,
                            print: false,
                            filter: true,
                            pagination: true,
                            search: true,
                            rowsSelected: compose(
                                filter(val => val !== undefined),
                                mapIndexed((val, index) => {
                                    return val.confirmed ? index : undefined
                                })
                            )(confirmedUnknownItems),
                            onRowsSelect: (rowsSelected) => {
                                if (rowsSelected.length === confirmedUnknownItems.length || rowsSelected.length === 0) {
                                    confirmAll()
                                } else {
                                    rowsSelected.forEach((val) => {
                                        const item = (confirmedUnknownItems.find((elem, i) => i === val.index) || {})
                                        changeConfirmedProp(item.id, 'confirmed', !item.confirmed, val.index)
                                    })
                                }
                            },
                            customToolbarSelect: (selectedRows, tableMeta) => (
                                <CustomToolbarSelect
                                    selectedRows={selectedRows}
                                    tableMeta={tableMeta}
                                    confirmedUnknownItems={confirmedUnknownItems}
                                    submitConfirmedItems={submitConfirmedItems}
                                />
                            ),
                            textLabels: {
                                body: {
                                    noMatch: "All Contract Found Items confirmed successfully!"
                                }
                            }
                        }}
                    />
                }
            </div>
        </div>
    )
}

const mapStateToProps = ({ contractUnknownItems, spin }) => ({
    contractUnknownItems,
    spin
})
export default connect(mapStateToProps, { getContractUnknownItems, submitConfirmedItems })(RoleManagement(ContractUnknownItems))
