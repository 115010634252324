import { merge } from 'ramda'
import axios from '../Essentials/axios'

const INIT_SETTINGS = 'INIT_SETTINGS'
const CHANGE_AUTO_MARGIN = 'CHANGE_AUTO_MARGIN'
const CHANGE_ENABLED = 'CHANGE_ENABLED'
const CHANGE_PAD = 'CHANGE_PAD'
const CLEAR_MESSAGE = 'CLEAR_MESSAGE'
const UPDATE_FAILURE = 'UPDATE_FAILURE'
const UPDATE_SUCCESS = 'UPDATE_SUCCESS'
const CHANGE_CONTRACT_ADVANCE_NOTICE = 'CHANGE_CONTRACT_ADVANCE_NOTICE'
const CHANGE_AGREEMENT_ADVANCE_NOTICE = 'CHANGE_AGREEMENT_ADVANCE_NOTICE'
const CHANGE_RECIPIENTS = 'RECIPIENTS'
const CHANGE_CONTRACT_FOUND_ITEMS_RECIPIENTS = 'CHANGE_CONTRACT_FOUND_ITEMS_RECIPIENTS'

export const postSettings = (margin, enabled, pad, expiringContractDays, expiringContractRecipients, expiringAgreementDays, contractFoundItemsRecipients) => axios.post('/updatesettings', {
        margin, enabled, pad, expiringContractDays, expiringContractRecipients, expiringAgreementDays, contractFoundItemsRecipients
    })
        .then(({ data }) => ({
            type: UPDATE_SUCCESS,
            payload: 'Successfully Saved',
        }))
        .catch((e) => ({
            type: UPDATE_FAILURE,
            payload: 'Something Went Wrong. Please Try Again',
        }))


// Will there be different settings for different users? so we take a settings Id or maybe salesRepId in future
export const getCurrentSettings = () => axios.get('/currentsettings').then((res) => ({
        type: INIT_SETTINGS,
        payload: res.data || {},

        // {
        //     autoMargin: pathOr(0, ['data', 'autoMargin'])(res),
        //     enabled: pathOr(false, ['data', 'enabled'])(res),
        //     defaultPad: pathOr(0, ['data', 'defaultPad'])(res),
        //     expiringContractRecipients:pathOr('', ['data', 'expiringContractRecipients'])(res),
        //     expiringContractDays:pathOr(0, ['data', 'expiringContractDays'])(res)
        // }
    }))
        .catch((e) => console.log(e))


export const changeAutoMargin = (val) => ({
    type: CHANGE_AUTO_MARGIN,
    payload: val,
})


export const changePad = (val) => ({
    type: CHANGE_PAD,
    payload: val,
})

export const changeEnabled = (bool) => ({
    type: CHANGE_ENABLED,
    payload: bool,
})


export const clearMessage = () => ({
    type: CLEAR_MESSAGE,
})

export const changeContractAdvanceNotice = (val) => ({
    type: CHANGE_CONTRACT_ADVANCE_NOTICE,
    payload: val,
})
export const changeAgreementAdvanceNotice = (val) => ({
    type: CHANGE_AGREEMENT_ADVANCE_NOTICE,
    payload: val,
})

export const changeRecipients = (val) => ({
    type: CHANGE_RECIPIENTS,
    payload: val,
})
export const changeContractFoundItemsRecipients = (val) => ({
    type: CHANGE_CONTRACT_FOUND_ITEMS_RECIPIENTS,
    payload: val,
})

export default (state, action) => {
    const init = state || {
 message: '', autoMargin: 0, enabled: false, defaultPad: 0,
}

    switch (action.type) {
        case INIT_SETTINGS:
            return action.payload
        case CHANGE_AUTO_MARGIN:
            return merge(init, { autoMargin: action.payload })
        case CHANGE_PAD:
            return merge(init, { defaultPad: action.payload })
        case CHANGE_RECIPIENTS:
            return merge(init, { expiringContractRecipients: action.payload })
        case CHANGE_CONTRACT_FOUND_ITEMS_RECIPIENTS:
            return merge(init, { contractFoundItemsRecipients: action.payload })
        case CHANGE_CONTRACT_ADVANCE_NOTICE:
            return merge(init, { expiringContractDays: action.payload })
        case CHANGE_AGREEMENT_ADVANCE_NOTICE:
            return merge(init, { expiringAgreementDays: action.payload })
        case CHANGE_ENABLED:
            return merge(init, { enabled: action.payload })
        case UPDATE_SUCCESS:
            return merge(init, { message: action.payload })
        case UPDATE_FAILURE:
            return merge(init, { message: action.payload })
        case CLEAR_MESSAGE:
            return merge(init, { message: '' })
        default:
            return init
    }
}
