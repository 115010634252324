import React, { Component } from 'react'
import { isEmpty, uniqBy, prop } from 'ramda'

class SelectGPO extends Component {
    handleSelect(event) {
        this.props.addToContractSubmit({ baseGpo: event.target.value })
        this.props.selectBaseGpo(event.target.value)
    }


    render() {
        const {
 source, sources, error, addToContractErrors,
} = this.props

        const renderSourceOptions = (uniqBaseGpos) => uniqBaseGpos.map((src) => (
                    <option key={src._id} value={src.baseGpo}>
                        {src.baseGpo}
                    </option>
                ))
        const uniqBaseGpos = uniqBy(prop('baseGpo'))(sources)
        return (
            <div className={`selectSource${error ? ' error' : ''}`}>
                <p>GPO</p>
                <div className="selector">
                    <select
                        name="baseGpo"
                        onChange={this.handleSelect.bind(this)}
                        onFocus={() => addToContractErrors({ baseGpo: false })}
                        value={`${source.baseGpo ? source.baseGpo : 'deadOption'}`}
                    >
                        <option disabled="disabled" className="defaultOption" value="deadOption">- Select GPO -</option>
                        {!(isEmpty(sources)) && renderSourceOptions(uniqBaseGpos)}
                    </select>
                </div>
            </div>
        )
    }
}


export default SelectGPO
