import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { isEmpty, compose, head } from 'ramda'

class AccountFilter extends Component {
    render() {
        const { handleSubmit, routeToAccount, filteredAccs } = this.props

        const handleEnterKey = (terms) => {
            if (isEmpty(filteredAccs)) return
            else compose(routeToAccount, head)(filteredAccs)
        }

        return (
            <div className="commonSearch">
                <form onSubmit={handleSubmit((terms) => handleEnterKey(terms))}>
                    <Field
name="value"
component="input"
type="text"
placeholder="Search"
autoComplete="off"
                    />
                </form>
            </div>
        )
    }
}

export default reduxForm({
        form: 'AccountFilter',
    })(AccountFilter)
