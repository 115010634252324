import React from 'react'
import { anyPass, equals } from 'ramda'

const ErrorPage = (props) => {
    const handleBack = (history) => {
        if (anyPass([equals(0), equals(1), equals(2)])(history.length)) history.push('/')

        else history.goBack()
    }

        return (
            <div className="contentDetail">
                <div className="errorIcon">
                    <h2>Oops!</h2>
                    <p>We can't seem to find the page you're looking for.</p>
                    <span>Error Code : Number</span>
                </div>
                <div className="col-6 alignCenter">
                    <button className="moreBtn" onClick={() => handleBack(props.history)}>Back</button>
                </div>
            </div>
        )
    }

export default ErrorPage
