import axios from '../Essentials/axios'

const MERGE_SPA_ACCOUNT_DETAILS = 'MERGE_SPA_ACCOUNT_DETAILS'
const CLEAR_SPA_UPDATE = 'CLEAR_SPA_UPDATE'

export const addToSPAUpdate = (detailsObj) => ({ type: MERGE_SPA_ACCOUNT_DETAILS, payload: detailsObj })

export const clearSPAUpdate = () => ({ type: CLEAR_SPA_UPDATE })

export const postAccountAgreement = (accountAgreement, history) => axios.post('/addaccountpriceagreement', {
    ...accountAgreement,
})
    .then((res) => {
        history.push(`/spa?id=${res.data.masterId}`)

        return { type: CLEAR_SPA_UPDATE }
    })
    .catch((e) => console.log(e))

export default (state, action) => {
    const init = state || {
        stage: 1,
        endDate: '',
        targetMargin: 30,
        account: {},
        existingAccountIds: []
    }

    switch (action.type) {
        case MERGE_SPA_ACCOUNT_DETAILS:
            return { ...init, ...action.payload }
        case CLEAR_SPA_UPDATE:
            return {}
        default:
            return init
    }
}
