import axios from '../Essentials/axios'

const MERGE_SPA_ACCOUNT_DETAILS = 'MERGE_SPA_ACCOUNT_DETAILS'
const CLEAR_SPA_UPDATE_ITEM = 'CLEAR_SPA_UPDATE_ITEM'

export const addToSPAUpdate = (detailsObj) => ({ type: MERGE_SPA_ACCOUNT_DETAILS, payload: detailsObj })

export const clearSPAUpdateItem = () => ({ type: CLEAR_SPA_UPDATE_ITEM })

export const addToMasterAgreement = (accountAgreement, history) => axios.post('/additemstomaster', {
            ...accountAgreement,
        })
            .then((res) => {
                history.push(`/spa?id=${res.data._id}`)

                return { type: CLEAR_SPA_UPDATE_ITEM }
            })
            .catch((e) => console.log(e))

export default (state, action) => {
    const init = state || {
    stage: 1,
    endDate: '',
    targetMargin: 30,
    account: {},
 }

    switch (action.type) {
        case MERGE_SPA_ACCOUNT_DETAILS:
            return { ...init, ...action.payload }
        case CLEAR_SPA_UPDATE_ITEM:
            return {}
        default:
            return init
    }
}
