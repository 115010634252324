const MERGE_UPDATE_ERROR = 'MERGE_UPDATE_ERRORS'
const CLEAR_SPA_UPDATE = 'CLEAR_SPA_UPDATE'

export const addToUpdateErrors = (errorObj) => ({ type: MERGE_UPDATE_ERROR, payload: errorObj })

export default (state, action) => {
    const init = state || {
        name: false,
        account: false,
        endDate: false,
        targetMargin: false,
        sellPriceErrors: [],
        emptyItems: false,
    }

    switch (action.type) {
        case MERGE_UPDATE_ERROR:
            return { ...init, ...action.payload }
        case CLEAR_SPA_UPDATE:
            return {}
        default:
            return init
    }
}
