import {
    compose as c, composeP as cp, curry, curryN, mergeAll, tap, map,
} from 'ramda'
import { Subject, from, of } from 'rxjs'
import { map as maprx, switchMap, catchError, debounceTime } from 'rxjs/operators'
import { interim } from './interim'
import { SPINZY, STOPZY } from '../reducers/loader_reducer'
// ps :: object of promise returning functions
// is :: object of synchronous functions
export const mapDispatch = curry((ps, ids, fn, dispatch) => {
    const tappedDispatch = tap(dispatch)
    // Binary compose promise
    const op = curryN(2, cp)
    const o = curryN(2, c)
    const mappedPs = map(op(tappedDispatch), ps)
    const mappedIds = map(o(tappedDispatch), ids)
    return mergeAll([
        mappedPs || {},
        mappedIds || {},
        fn ? fn(tappedDispatch) : {},
    ])
})

const start = (name) => ({ type: SPINZY, where: name })
const stop = (name) => ({ type: STOPZY, where: name })
const fail = stop
export const mapSpinToDispatch = (dispatch) => interim(c(dispatch, start), c(dispatch, stop), c(dispatch, fail))

const FATAL_ERROR = '@@FATAL_ERROR'

const fatalSubject = new Subject().pipe(
    switchMap((p) => from(p).pipe(catchError((e) => of({ type: FATAL_ERROR, payload: e })))),
)
export const initFatal = (dispatch) => {
    fatalSubject.subscribe(
        (action) => {
            if (action.type === FATAL_ERROR) dispatch({ type: 'INSUF' })
            else dispatch({ type: 'SUF' })
        },
    )
}
export const isFatal = (p) => {
    fatalSubject.next(p)
    return p
}

export const redSearchHandlerInit = curry((dispatch, srchfn, spinner) => {
    const subject = new Subject()
    subject.pipe(
        maprx((x) => {
            dispatch({ type: SPINZY, where: spinner })
            return x
        }),
        debounceTime(400),
        switchMap((terms) => from(srchfn(terms))),
    ).subscribe({
        next: (action) => {
            // dispatch({ type: 'RXJS-SEARCH'} )
            dispatch(action)
            dispatch({ type: STOPZY, where: spinner })
        },
    })

    return subject.next.bind(subject)
})
