import React from 'react'
import descIcon from '../images/sort-down.png'
import ascIcon from '../images/sort-up.png'

const SortableHeader = ({
 label, name, columnWidth, sortable = true, selected = false, direction, clickHandler,
}) => (
    <div
        className={`col-${columnWidth} ${sortable && 'sortableHeader'} ${selected && 'selected'}`}
        onClick={sortable ? () => clickHandler(name) : null}
    >
        <span>{label}</span>
        <span className="sort-icon-wrapper">
            {selected && <img className="sort-icon" src={direction === 'desc' ? descIcon : ascIcon} />}
        </span>
    </div>
)

export default SortableHeader;
