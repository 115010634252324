import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import {
  getPageOfModels,
  incrementPage,
  selectFirstPage,
  searchModels,
  getInitModels,
  clearModelsToView,
} from '../actions/index'
import { mapDispatch, mapSpinToDispatch, redSearchHandlerInit } from '../utilities/redux-util'

export default (ComposedComponent) => {
  class Pagination extends Component {
    render() {
      return <ComposedComponent {...this.props} searchModels={searchModels} />
    }
  }

  const ms2p = (state) => ({
      currentPage: state.currentPage,
      totalManus: state.totalManus,
      totalItems: state.totalItems,
      totalAccounts: state.totalAccounts,
      totalContracts: state.totalContracts,
      totalAgreements: state.totalAgreements,
      visibleManus: state.visibleManus,
      visibleItems: state.visibleItems,
      visibleAccounts: state.visibleAccounts,
      visibleContracts: state.visibleContracts,
      visibleAgreements: state.visibleAgreements,

      spin: state.spin,


      searchForm: state.form,
      searchTerms: getFormValues('Search')(state),
      itemSearchTerms: getFormValues('ItemSearch')(state),
    })

  const md2p = mapDispatch({
    getInitModels, getPageOfModels,
  }, {
    clearModelsToView, selectFirstPage, incrementPage,
  }, (dispatch) => ({
    redSpin: mapSpinToDispatch(dispatch),
    redSearchHandlerInit: redSearchHandlerInit(dispatch),
    dispatch,
    }))

  return connect(ms2p, md2p)(Pagination)
}
