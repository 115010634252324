import React, { Component } from 'react'
import {
    isEmpty, pathOr, head, compose,
} from 'ramda'

class GlobalSearch extends Component {
    render() {
        const {
            getGlobalSearchResults, switchVisibility, clearGlobalResults,
            globalSearch, toRoute, history, globalSearchSort,
        } = this.props

        const sortedResults = compose(globalSearchSort, pathOr([], ['results']))(globalSearch)

        const hovering = pathOr(false, ['hovering'])(globalSearch)

        const handleFormChange = (value) => {
            if (isEmpty(value)) clearGlobalResults()

            else getGlobalSearchResults(value)
        }

        const handleKeys = (e) => {
            if (e.key === 'Enter' && isEmpty(sortedResults)) { return e.preventDefault() }
            if (e.key === 'Enter') {
                const { modelType, _id, masterId = '' } = head(sortedResults)
                const isAccountAgreement = modelType == 'Account Agreement'
                const rerouteId = isAccountAgreement ? masterId : _id
                history.push(`/${toRoute(modelType)}?id=${rerouteId}`)
                switchVisibility(false)
                e.preventDefault()
            }
            if (e.key === 'ArrowDown') {
                switchVisibility(true)
                e.preventDefault()
            }
        }

        return (
            <form>
                <input
                    placeholder="Search for anything..."
                    onChange={(e) => {
                        handleFormChange(e.target.value)
                        switchVisibility(true)
                    }}
                    onKeyDown={handleKeys}
                    onBlur={() => {
                        if (!hovering) switchVisibility(false)
                    }}
                    onFocus={() => switchVisibility(true)}
                />
            </form>
        )
    }
}

export default GlobalSearch
