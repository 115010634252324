import React, { Component } from 'react'
import {
 isEmpty, compose as c, head, objOf,
} from 'ramda'

class ItemSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            externalId: '',
            name: '',
            manuName: '',
            mpn: '',
        }
    }

    searchHandlerInit = () => {
        const handleSearch = (e) => {
            const { searchModels, currentManu } = this.props
            if (isEmpty(currentManu)) return Promise.resolve()

            const { name, value } = e
            const change = name ? objOf(name, value) : {}

            const searchP = searchModels('/items', 1, { ...this.state, ...change, vendor: currentManu._id })

            this.setState(change)
            return searchP
        }


        return this.props.redSearchHandlerInit(handleSearch, '/itemssearch')
    }

    searchHandler = this.searchHandlerInit()

    render() {
        const {
            handleSubmit, clearModelsToView, searchModels,
            itemSearchValues, currentManu, addToContractErrors,
            spin, visibleItems, addItemToContract,
        } = this.props


        const objFromEvent = ({ target }) => ({ name: target.name, value: target.value })
        const handleSearch = (e) => this.searchHandler(objFromEvent(e))
        const handleKeyPress = (e) => {
            if ((e.key === 'Enter') && isEmpty(visibleItems)) return e.preventDefault()
            if (e.key === 'Enter') {
                c(addItemToContract, head)(visibleItems)
                clearModelsToView('items')
                e.preventDefault()
            }
            if (e.key === 'ArrowDown') {
                searchModels('/items', 1, itemSearchValues)
                e.preventDefault()
            }
        }

        return (
            <div className={`itemSearch${spin['/itemssearch'] ? ' loading' : ''}`}>
                <form
                    onKeyDown={handleKeyPress}
                    onBlur={() => clearModelsToView('items')}
                    onFocus={(_) => this.searchHandler({})}
                >
                    <div className="col-2">
                        <input
name="externalId"
className="padded"
type="text"
placeholder="Item #"
autoComplete="off"
                            onFocus={() => addToContractErrors({ contractPrices: false })}
                            onChange={handleSearch}
                         />
                    </div>
                    <div className="col-3">
                        <input
name="name"
className="padded"
type="text"
placeholder="Item Name"
autoComplete="off"
                            onFocus={() => addToContractErrors({ contractPrices: false })}
                            onChange={handleSearch}
                         />
                    </div>
                    <div className="col-2">
                        <input
name="mpn"
type="text"
placeholder="MPN"
autoComplete="off"
                            onFocus={() => addToContractErrors({ contractPrices: false })}
                            onChange={handleSearch}
                         />
                    </div>
                </form>
            </div>
        )
    }
}

export default ItemSearch
