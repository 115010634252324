import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UNAUTH_USER } from '../actions/action_types'

const logOut = () => {
  localStorage.clear()

  return {
      type: UNAUTH_USER,
    }
}

class LogOut extends Component {
  componentDidMount() {
    this.props.logOut()
    this.props.history.push('/devlogin')
  }

  render() {
    return <div>Bye Bye</div>
  }
}

export default connect(null, { logOut })(LogOut)
