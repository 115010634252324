const FETCH_INIT_ITEMS = 'FETCH_INIT_ITEMS'
const FETCH_PAGE_ITEMS = 'FETCH_PAGE_ITEMS'
const SEARCH_ITEMS = 'SEARCH_ITEMS'

export default (state, action) => {
    const init = state || 0

    switch (action.type) {
        case FETCH_INIT_ITEMS: case FETCH_PAGE_ITEMS: case SEARCH_ITEMS:
            return action.total
        default:
            return init
    }
}
