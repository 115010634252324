import {
 remove, uniqBy, prop, sortBy, compose,
} from 'ramda'

const ADD_SELECTED_ITEMS = 'ADD_SELECTED_ITEMS'
const REMOVE_SPA_ITEM = 'REMOVE_SPA_ITEM'
const CLEAR_SPA_CREATE = 'CLEAR_SPA_CREATE'

export const selectItemForSPA = (item) => ({ type: ADD_SELECTED_ITEMS, payload: [item] })

export const removeItemFromSPA = (index) => ({ type: REMOVE_SPA_ITEM, payload: index })

export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case REMOVE_SPA_ITEM:
            return remove(action.payload, 1, init)
        case ADD_SELECTED_ITEMS:
            return compose(sortBy(prop('_id')), uniqBy(prop('_id')))([...action.payload, ...init])
        case CLEAR_SPA_CREATE:
            return []
        default:
            return init
    }
}
