import React, { Component } from 'react'
import { isEmpty, compose, head } from 'ramda'

class ItemSearch extends Component {
    searchHandlerInit = () => {
        const handleSearch = (search) => this.props.searchModels('/items', 1, search)

        return this.props.redSearchHandlerInit(handleSearch, '/itemssearch')
    }

    searchHandler = this.searchHandlerInit()

    componentDidMount() {
        this.searchHandler()
    }

    render() {
        const {
 handleSubmit, createSpaErrors, addToCreateSpaErrors, spin, visibleItems, selectItemForSPA,
} = this.props


        const handleEnterKey = () => {
            if (isEmpty(visibleItems)) return
            else compose(selectItemForSPA, head)(visibleItems)
        }

        return (
            <div
                className={`itemSearch relativeP
                ${createSpaErrors.items ? ' error' : ''}
                ${spin['/itemssearch'] ? ' loading' : ''}
            `}>
                <form
                    onChange={(e) => this.searchHandler({ externalId: e.target.value })}
                    onSubmit={handleEnterKey}
                    onFocus={() => addToCreateSpaErrors({ emptyItems: false })}
                >
                    <div className="col-12">
                        <input autoFocus name="externalId" type="text" placeholder="Item #" autoComplete="off" onFocus={() => addToCreateSpaErrors({ items: false })} />
                    </div>
                </form>

            </div>
        )
    }
}

export default ItemSearch
