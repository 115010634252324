import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import authReducer from './auth_reducer'
import { currentUserReducer, currentRoleReducer } from './current_user_reducer'

import currentManuReducer from './current_manu_reducer'
import currentItemReducer from './current_item_reducer'
import currentAccountReducer from './current_account_reducer'
import currentContractReducer from './current_contract_reducer'

import manusPageReducer from './manus_page_reducer'
import itemsPageReducer from './items_page_reducer'
import accountsPageReducer from './accounts_page_reducer'
import contractsPageReducer from './contracts_page_reducer'
import agreementsPageReducer from '../AllSpas/agreements_page_reducer'

import totalManusReducer from './total_manus_reducer'
import totalAccountsReducer from './total_accounts_reducer'
import totalItemsReducer from './total_items_reducer'
import totalContractsReducer from './total_contracts_reducer'
import contractUnknownItemsReducer from './contract_unknown_item_reducer'

import currentPageReducer from './current_page_reducer'
import conItemsReducer from '../ContractInput/contract_items_reducer'
import unknownItemsReducer from '../ContractInput/unknown_items_reducer'
import conAccountsReducer from '../ContractInput/contract_accounts_reducer'
import sourceReducer from '../ContractInput/source_reducer'
import conSubmitReducer from '../ContractInput/contract_submit_reducer'
import conErrorsReducer from '../ContractInput/contract_errors_reducer'

import spaCreateReducer from './spa_create_reducer'
import selectedItemsReducer from '../CreateSPA/selected_items_reducer'
import itemDataReducer from '../CreateSPA/item_data_reducer'
import itemModsReducer from '../CreateSPA/item_mods_reducer'
import createSpaErrorReducer from './create_spa_errors_reducer'
import recentlyOrderedReducer from '../CreateSPA/recetly_ordered_reducer'

import manuSearchReducer from '../ContractInput/manu_search_reducer'

import spaUpdateReducer from '../UpdateSPA/spa_update_reducer'
import updateErrorsReducer from '../UpdateSPA/update_stage1_errors_reducer'
import masterAgreementsReducer from '../UpdateSPA/master_agreements_reducer'
import masterItemDataReducer from '../UpdateSPA/master_item_data_reducer'
import accItemModsReducer from '../UpdateSPA/acc_item_mods_reducer'


import userSearchReducer from './user_search_reducer'

import accountAgreementsReducer from '../reducers/agreements_page'
import currentAccountAgreeReducer from './current_acc_agreement_reducer'
import agreementMessageReducer from '../AgreementPage/messages_reducer'
import editAgreementReducer from '../AgreementPage/edit_agreement_reducer'

import globalSearchReducer from '../Essentials/global_search_reducer'

import sourcesReducer from './sources_reducer'
import gpoListReducer from '../AllGpos/gpo_list_reducer'
import gpoManageReducer from '../AccountPage/gpo_manage_reducer'
import settingsReducer from '../Settings/settings_reducer'

import spinReducer from './loader_reducer'
import modalReducer from './modal_reducer'
import pageSuccessReducer from './page_success_reducer'

import spaUpdateItemReducer from '../UpdateSPAItem/spa_update_item_reducer'
import masterAgreementUpdateItemReducer from '../UpdateSPAItem/master_agreements_reducer'
import masterItemDataUpdateItemReducer from '../UpdateSPAItem/master_item_data_reducer'
// ////////
import updateSpaitemErrorReducer from '../UpdateSPAItem/create_spa_errors_reducer'
import updateSpaitemItemDataReducer from '../UpdateSPAItem/item_data_reducer'
import updateSpaitemItemModsReducer from '../UpdateSPAItem/item_mods_reducer'
import updateSpaitemRecentlyOrderedReducer from '../UpdateSPAItem/recetly_ordered_reducer'
import updateSpaitemSelectedItemsReducer from '../UpdateSPAItem/selected_items_reducer'
import updateSpaitemSpaCreateReducer from '../UpdateSPAItem/spa_create_reducer'
import updateSpaitemSpaErrorsReducer from '../UpdateSPAItem/update_spa_item_errors_reducer'
import updateSpaitemSpaAccItemModsReducer from '../UpdateSPAItem/acc_item_mods_reducer'

const rootReducer = combineReducers({

  pageSuccess: pageSuccessReducer,
  auth: authReducer,
  currentUser: currentUserReducer,
  currentRole: currentRoleReducer,
  form: formReducer,

  currentManu: currentManuReducer,
  currentItem: currentItemReducer,
  currentAccount: currentAccountReducer,
  currentContract: currentContractReducer,
  contractUnknownItems: contractUnknownItemsReducer,
  currentPage: currentPageReducer,

  totalManus: totalManusReducer,
  totalItems: totalItemsReducer,
  totalAccounts: totalAccountsReducer,
  totalContracts: totalContractsReducer,

  visibleManus: manusPageReducer,
  visibleItems: itemsPageReducer,
  visibleAccounts: accountsPageReducer,
  visibleContracts: contractsPageReducer,
  paginatedAgreements: agreementsPageReducer,

  sources: sourcesReducer,
  contractItems: conItemsReducer,
  unknownItems: unknownItemsReducer,
  contractAccounts: conAccountsReducer,
  source: sourceReducer,
  contractSubmit: conSubmitReducer,
  contractErrors: conErrorsReducer,

  spaCreate: spaCreateReducer,
  selectedSpaItems: selectedItemsReducer,
  spaItemData: itemDataReducer,
  spaItemMods: itemModsReducer,
  createSpaErrors: createSpaErrorReducer,
  recentlyOrdered: recentlyOrderedReducer,

  spaUpdate: spaUpdateReducer,
  updateSpaInputError: updateErrorsReducer,
  masterAgreements: masterAgreementsReducer,
  masterItemData: masterItemDataReducer,
  accItemMods: accItemModsReducer,

  accountAgreements: accountAgreementsReducer,
  accountAgreement: currentAccountAgreeReducer,
  agreementMessages: agreementMessageReducer,
  agreementEdit: editAgreementReducer,
  userOptions: userSearchReducer,



  settings: settingsReducer,

  gpoListValues: gpoListReducer,
  globalSearch: globalSearchReducer,
  gpoManage: gpoManageReducer,

  manuSearchValue: manuSearchReducer,

  spin: spinReducer,
  modal: modalReducer,

  spaUpdateItem: spaUpdateItemReducer,
  masterAgreementUpdateItem: masterAgreementUpdateItemReducer,
  masterItemDataUpdateItem: masterItemDataUpdateItemReducer,
  updateSpaitemError: updateSpaitemErrorReducer,
  updateSpaitemItemData: updateSpaitemItemDataReducer,
  updateSpaitemItemMods: updateSpaitemItemModsReducer,
  updateSpaitemRecentlyOrdered: updateSpaitemRecentlyOrderedReducer,
  updateSpaitemSelectedItems: updateSpaitemSelectedItemsReducer,
  updateSpaitemSpaCreate: updateSpaitemSpaCreateReducer,
  updateSpaitemSpaErrors: updateSpaitemSpaErrorsReducer,
  updateSpaitemSpaAccItemMods: updateSpaitemSpaAccItemModsReducer,
})

export default rootReducer
