import React, { Component } from 'react'
import queryString from 'query-string'
import { connect } from 'react-redux'
import {
 compose as c, composeP as cp, equals, anyPass, propOr, reduce, or, pathOr,
} from 'ramda'
import { Tabs, TabLink, TabContent } from 'react-tabs-redux'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import {
 getModel, accountItemSearch, clearModel, clearModelsToView,
} from '../actions/index'
import { removeAccountSource, addAccountSource } from '../reducers/current_account_reducer'
import { gpoManageChange } from './gpo_manage_reducer'
import ErrorPage from '../Essentials/ErrorPage'
import Details from './Details'
import ContractList from './ContractList'
import AgreementList from './AgreementList'
import ItemList from './ItemList'
import RoleManagement from '../HOC/RoleManagement'
import GpoList from './GpoList'
import { getSources } from '../reducers/sources_reducer'
import {
 mapDispatch, mapSpinToDispatch, isFatal, redSearchHandlerInit,
} from '../utilities/redux-util'
import { sliceToWhiteSpace } from '../utilities/util'


const anyGood = reduce(or)(false)

const handleBack = (history) => {
    if (anyPass([equals(0), equals(1), equals(2)])(history.length)) history.push('/accounts')

    else history.goBack()
}

class AccountPage extends Component {
    componentDidMount() {
        this.props.getSources()
        const { getModel, redSpin } = this.props
        const params = queryString.parse(this.props.location.search)
        const { pathname } = this.props.location
        const accountId = params.id
        const modelP = getModel(pathname, accountId)
        redSpin(modelP, 'detailpage')
    }

    componentDidUpdate(previousProps) {
        const { getModel, location, redSpin } = this.props
        const params = queryString.parse(this.props.location.search)
        const { pathname } = this.props.location
        const accountId = params.id

        if (previousProps.location.search != location.search) redSpin(getModel(pathname, accountId), 'detailpage')
    }

    componentWillUnmount() {
        const { clearModel, clearModelsToView } = this.props
        clearModel('account')
        clearModelsToView('items')
    }

    render() {
        const {
 pageSuccess, currentAccount, history, location, isAdmin, isSalesManager, isInsideSales, isPurchaseManager, isPurchaseAssistant, spin,
} = this.props
        const { accountAgreements, contracts, contractSources } = currentAccount
        // Just show spinner if not loaded
        if (spin.detailpage) return <div className="loader" />
        if (pageSuccess == 'INSUFFICIENT') return <ErrorPage history={history} />

        const companyName = propOr('', 'companyName')(currentAccount)
        const slicedEntity = c(sliceToWhiteSpace, pathOr('', ['entityId']))(currentAccount)

        return (
            <div className="contentDetail">
                <div className="headerDetail">
                    <button className="btnPrev" onClick={() => handleBack(history)} />
                    <div className="titleDetail customers">
                        <div className="infoIcon" />
                        <h2>{slicedEntity} {companyName}</h2>
                    </div>
                    {anyGood([isAdmin, isSalesManager, isPurchaseAssistant, isPurchaseManager, isInsideSales])
                        && <Tooltip title="Manage GPO" placement="top">
                            <Link to="/gpos"><button className="btnGPO" /></Link>
                           </Tooltip>}
                </div>
                <Details currentAccount={currentAccount} history={history} />
                <div className="listDetail">
                    <Tabs>
                        <div className="tabOnDetail">
                            <TabLink to="tab1" className="tab-link">Agreements</TabLink>
                            <TabLink to="tab2" className="tab-link">Contracts</TabLink>
                            <TabLink to="tab3" className="tab-link">Item Search</TabLink>
                            <TabLink to="tab4" className="tab-link">GPO</TabLink>
                        </div>
                        <TabContent for="tab1"><AgreementList agreements={accountAgreements} history={history} /></TabContent>
                        <TabContent for="tab2"><ContractList contracts={contracts} history={history} /></TabContent>
                        <TabContent for="tab3"><ItemList {...this.props} accountItemSearch={accountItemSearch} /></TabContent>
                        <TabContent for="tab4"><GpoList {...this.props} /></TabContent>
                    </Tabs>
                </div>
            </div>
        )
    }
}
const noIdFatal = (action) => (action.payload._id ? Promise.resolve(action) : Promise.reject('No customer found'))

const md2p = mapDispatch({
    getModel: c(isFatal, cp(noIdFatal, getModel)),
    getSources,
    removeAccountSource,
    addAccountSource,
}, {
        clearModel,
        clearModelsToView,
        gpoManageChange,
    }, (dispatch) => ({
        redSpin: mapSpinToDispatch(dispatch),
        redSearchHandlerInit: redSearchHandlerInit(dispatch),
    }))

const mapStateToProps = (state) => ({
        pageSuccess: state.pageSuccess,
        visibleItems: state.visibleItems,
        currentAccount: state.currentAccount,
        sources: state.sources,
        gpoManage: state.gpoManage,
        spin: state.spin,
    })
export default RoleManagement(connect(mapStateToProps, md2p)(AccountPage))
