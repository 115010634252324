const FETCH_ITEM = 'FETCH_ITEM'
const SELECT_ITEM = 'SELECT_ITEM'
const CLEAR_ITEM = 'CLEAR_ITEM'

export default (state, action) => {
    const init = state || {}

    switch (action.type) {
        case FETCH_ITEM:
            return action.payload
        case SELECT_ITEM:
            return action.payload
        case CLEAR_ITEM:
            return {}
        default:
            return init
    }
}
