import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
 map, addIndex, curry, compose as c, pathOr, isEmpty,
} from 'ramda'
import { dateString } from '../utilities/util'


const mapIndexed = addIndex(map)

const renderContract = curry((history, contract, index) => {
    const rawEndDate = pathOr('', ['endDate'])(contract)
    const rawStartDate = pathOr('', ['startDate'])(contract)
    const endDate = isEmpty(rawEndDate) ? '' : dateString(new Date(rawEndDate))
    const startDate = isEmpty(rawStartDate) ? '' : dateString(new Date(rawStartDate))
    const baseGpo = pathOr('Local Vendor Contract', ['contractSource', 'baseGpo'])(contract)
    const sourceName = pathOr('', ['contractSource', 'tier'])(contract)
    const contractIdentifier = contract.entityId || contract._id
    return (

        <li key={index}>
            <Link to={`/contract?id=${contract._id}`}>
                <div className="col-2">{contractIdentifier}</div>
                <div className="col-4 alignLeft bolder">{baseGpo}</div>
                <div className="col-2">{sourceName}</div>
                <div className="col-2">{startDate}</div>
                <div className="col-2">{endDate}</div>
            </Link>
        </li>

    )
})

const renderContracts = c(mapIndexed, renderContract)

class ContractList extends Component {
    render() {
        const { history } = this.props
        const contractsToRender = pathOr([], ['contracts'])(this.props)

        return (
            <div>
                <div className="headerBasicList">
                    <div className="col-2">ID</div>
                    <div className="col-4">Source</div>
                    <div className="col-2">Tier</div>
                    <div className="col-2">Start Date</div>
                    <div className="col-2">End Date</div>
                </div>
                <div className="basicList">
                    <ul>
                        {renderContracts(history)(contractsToRender)}
                    </ul>
                </div>
            </div>

        )
    }
}

export default ContractList
