import axios from '../Essentials/axios'
import {
    GET_CONTRACT_UNKNOWN_ITEMS,
    SET_INIT_CONFIRMED_UNKNOWN_ITEMS,
    SET_PROP_CONFIRMED_ITEMS,
    CONFIRM_ALL,
    SPINZY,
    STOPZY
} from './action_types'

export const getContractUnknownItems = () => async (dispatch) => {
    dispatch({type: SPINZY, where: '/contractUnknownItems'})
    const { data } = await axios.get('/contractUnknownItems/paginated')
    
    if (data) {
        dispatch({
            type: GET_CONTRACT_UNKNOWN_ITEMS,
            payload: data,
        })
        dispatch({
            type: SET_INIT_CONFIRMED_UNKNOWN_ITEMS,
            payload: data,
        })
    } else {
        dispatch({
            type: GET_CONTRACT_UNKNOWN_ITEMS,
            payload: [],
        })
    }

    dispatch({type: STOPZY, where: '/contractUnknownItems'})
}

export const editContractUnknownItem = ({ id, field, value }) => ({
    type: SET_PROP_CONFIRMED_ITEMS,
    payload: { id, field, value }
})

export const confirmAll = () => ({
    type: CONFIRM_ALL
})

export const submitConfirmedItems = (sendData) => async dispatch => {
    dispatch({type: SPINZY, where: '/contractUnknownItems'})
    const {data, message} = await axios.post('/contractUnknownItems/edit',{sendData})

    if(message) {
        dispatch({type: STOPZY, where: '/contractUnknownItems'})
    }
    if (data) {
        dispatch({
            type: GET_CONTRACT_UNKNOWN_ITEMS,
            payload: data,
        })
        dispatch({
            type: SET_INIT_CONFIRMED_UNKNOWN_ITEMS,
            payload: data,
        })
    } else {
        dispatch({
            type: GET_CONTRACT_UNKNOWN_ITEMS,
            payload: [],
        })
    }
    dispatch({type: STOPZY, where: '/contractUnknownItems'})
}
