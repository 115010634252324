import React from 'react'
import {
    isEmpty, map, addIndex, curry, pathOr, compose, sortBy, prop, propOr, toUpper,
} from 'ramda'
import { dateString, lowestNth, sliceToWhiteSpace } from '../utilities/util'
import InactiveItemWarning from '../Reusable/InactiveItemWarning'


const mapIndexed = addIndex(map)
const lowestInt = lowestNth(1)

const computeMargin = (cost, sellPrice) => lowestInt((1 - cost / sellPrice) * 100)

// const getBestCostWithSource = (itemExpDate, itemContracts) => {

//     const filterEligible = filter(prop('isEligible'))
//     /* Ramda's lte seems to behave nicely when comparing two moment dates */
//     const afterEndDate = compose(lte(itemExpDate), prop('endDate'))
//     const filterEndDates = filter(anyPass([afterEndDate, prop('isPurchasePrice')]))
//     const sortByPrice = sortBy(prop('price'))
//     const pickDetails = pick(['price', 'contractEntity', 'isPurchasePrice', 'type', 'baseGpo', 'tier'])
//     const findCost = compose(pickDetails, head, sortByPrice, filterEndDates, filterEligible)

//     return findCost(itemContracts) || {}
// }
// const getBestCost = compose(pathOr('', ['price']), getBestCostWithSource)

const renderPriceAgreement = curry((targetMargin, getBestCostWithSource, priceAgreement, index) => {
    const {
        _id, sellPrice, expDate, name, externalId, isInactive
    } = priceAgreement
    const contracts = pathOr([], ['contracts'])(priceAgreement)
    const cost = pathOr('', ['price'], getBestCostWithSource(expDate, contracts))
    const formattedCost = isEmpty(cost) ? `$${cost}` : `$${cost.toFixed(2)}`

    const sourceDetails = getBestCostWithSource(expDate, contracts)
    const sourceIdentifier = pathOr('', ['contractEntity'])(sourceDetails)
    const baseGpo = pathOr('', ['baseGpo'])(sourceDetails)
    const tier = pathOr('', ['tier'])(sourceDetails)
    const isPurchasePrice = pathOr(false, ['isPurchasePrice'])(sourceDetails)
    const type = isPurchasePrice ? 'Purchase Price' : compose(toUpper, pathOr('', ['type']))(sourceDetails)

    const currentMargin = computeMargin(cost, sellPrice)
    const slicedExternalId = sliceToWhiteSpace(externalId)
    const formattedDate = dateString(new Date(expDate))

    return (
        <li key={index}>

            <div className="col-2">{slicedExternalId}</div>
            <div className="col-3 alignLeft bolder">
                {name}
                {isInactive && <InactiveItemWarning />}
            </div>
            <div className="col-1">&#36;{parseFloat(sellPrice).toFixed(2)}</div>
            <div className={`col-1 ${(currentMargin < targetMargin) ? 'under' : 'over'}TargetMargin`}>
                {currentMargin}&#37;
                <p>&#40;{targetMargin}&#37;&#41;</p>
            </div>
            <div className="col-2">{formattedCost}</div>
            <div className="col-1">
                {type}
                <p>{sourceIdentifier}</p>
            </div>
            <div className="col-1">
                {baseGpo}
                <p>{tier}</p>
            </div>
            <div className="col-1">{formattedDate}</div>
        </li>
    )
})

const renderPriceAgreements = compose(mapIndexed, renderPriceAgreement)

const AgreementItems = (props) => {
    const { accountAgreement, getBestCostWithSource } = props

    const { targetMargin } = accountAgreement
    const priceAgreements = propOr([], 'priceAgreements')(accountAgreement)
    const sortedAgreements = sortBy(prop('externalId'))(priceAgreements)

    return (
        <div>
            <div className="headerBasicList">
                <div className="col-2">ID</div>
                <div className="col-3">Name</div>
                <div className="col-1">Price</div>
                <div className="col-1">Margin</div>
                <div className="col-2">Cost</div>
                <div className="col-1">Source</div>
                <div className="col-1">GPO</div>
                <div className="col-1">Expiration Date</div>
            </div>
            <div className="basicList unLinked">
                <ul>
                    {accountAgreement && priceAgreements && renderPriceAgreements(targetMargin, getBestCostWithSource)(sortedAgreements)}
                </ul>
            </div>
        </div>
    )
}

export default AgreementItems
