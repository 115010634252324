import {
 prop, remove, filter, not, contains, concat, map, compose, __,
} from 'ramda'

const ADD_ITEM_MODS = 'ADD_ITEM_MODS'
const REMOVE_SPA_ITEM = 'REMOVE_SPA_ITEM'
const CLEAR_SPA_CREATE = 'CLEAR_SPA_CREATE'

export const addItemMod = (modObj) => ({ type: ADD_ITEM_MODS, payload: [modObj] })

export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case ADD_ITEM_MODS:
            {
                const payIds = map(prop('_id'))(action.payload)
                const pred = compose(not, contains(__, payIds), prop('_id'))
                return concat(filter(pred)(init))(action.payload)
            }
        case REMOVE_SPA_ITEM:
            return remove(action.payload, 1, init)
        case CLEAR_SPA_CREATE:
            return []
        default:
            return init
    }
}
