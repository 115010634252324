import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'

import {
    equals, anyPass, composeP, map,
} from 'ramda'
import Stage1 from './Stage1'
import Stage2 from './Stage2'


import {
    searchModels, selectModel, clearModel, clearModelsToView,
} from '../actions/index'
import { mapDispatch, mapSpinToDispatch, redSearchHandlerInit } from '../utilities/redux-util'


import { addToSPAUpdate, clearSPAUpdateItem, addToMasterAgreement } from './spa_update_item_reducer'
import { getMasterSPADetails } from './master_agreements_reducer'
import { getMasterItemData } from './master_item_data_reducer'
import { addToCreateSpaErrors } from './update_spa_item_errors_reducer'

// cpied need editig
import { clearSPACreate, postMasterSPA } from './spa_create_reducer'
import { selectItemForSPA, removeItemFromSPA } from './selected_items_reducer'
import { getEnhancedItems } from './item_data_reducer'
import { addItemMod } from './item_mods_reducer'
import { getRecentlyOrdered } from './recetly_ordered_reducer'


const handleBack = (history) => {
    if (anyPass([equals(0), equals(1), equals(2)])(history.length)) history.push('/')
    else history.goBack()
}

class UpdateSPAItem extends Component {
    componentDidMount() {
        const { addToSPAUpdate, getMasterSPADetails, currentAccount } = this.props
        const params = queryString.parse(this.props.location.search)
        const masterId = params.id

        addToSPAUpdate({ stage: 1 })
        getMasterSPADetails(masterId)
        getMasterItemData(masterId, currentAccount)
    }

    componentWillUnmount() {
        const { clearSPAUpdateItem } = this.props
        clearSPAUpdateItem()
    }

    render() {
        const { stage } = this.props.spaUpdateItem
        const { history } = this.props

        return (
            <div className="contentDetail">
                <div className="headerDetail">
                    <button type="button" className="btnPrev" onClick={() => handleBack(history)} />
                    <div className="titleDetail SPA">
                        <div className="infoIcon" />
                        <h2>Add/Update Items</h2>
                        <h2> Added items will overwrite prices for all customers on this agreement</h2>
                        <h2> New customers added will also get the new items</h2>
                    </div>

                </div>

                <div className="progress update">
                    <div>
                        <p>Item Selection</p>
                        <button type="button" className={`${equals(1)(stage) ? 'selected' : ''}`}>1</button>
                    </div>
                    <div className={`step-2${equals(2)(stage) ? ' selected' : ''}`}>
                        <p>Item Cart</p>
                        <button type="button" className={`${equals(2)(stage) ? 'selected' : ''}`}>2</button>
                    </div>

                </div>

                {(stage === 1) && <Stage1 {...this.props} searchModels={searchModels} />}
                {(stage === 2) && <Stage2 {...this.props} searchModels={searchModels} />}

            </div>

        )
    }
}


const mapStateToProps = (state) => ({
    spaUpdateItem: state.spaUpdateItem,
    masterAgreementUpdateItem: state.masterAgreementUpdateItem,
    masterItemDataUpdateItem: state.masterItemDataUpdateItem,
    visibleAccounts: state.visibleAccounts,
    currentAccount: state.currentAccount,
    accItemMods: state.updateSpaitemSpaAccItemMods,
    // updateSpaInputError: state.updateSpaInputError,//dont think need
    spin: state.spin,
    createSpaErrors: state.updateSpaitemError,
    visibleItems: state.visibleItems,
    selectedSpaItems: state.updateSpaitemSelectedItems,
    spaCreate: state.updateSpaitemSpaCreateReducer,
    updateSpaitemItemData: state.updateSpaitemItemData,
    spaItemMods: state.updateSpaitemItemMods,

})

const md2p = mapDispatch({
    getEnhancedItems,
    getMasterItemData,
    addToMasterAgreement,
},
    {
        addToSPAUpdate,
        postMasterSPA,
        getRecentlyOrdered,
        clearSPACreate,
        clearModelsToView,
        selectModel,
        clearModel,
        selectItemForSPA,
        removeItemFromSPA,
        addItemMod,
        addToCreateSpaErrors,
        clearSPAUpdateItem,


    },
    (dispatch) => ({
        getMasterSPADetails: composeP(map(dispatch), getMasterSPADetails),
        redSearchHandlerInit: redSearchHandlerInit(dispatch),
        redSpin: mapSpinToDispatch(dispatch),

    }))

export default connect(mapStateToProps, md2p)(UpdateSPAItem)
