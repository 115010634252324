import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline'
import { getFormValues } from 'redux-form'
import {
  map, head, toUpper, concat, tail, sortBy, converge,
  equals, isEmpty, anyPass, addIndex, prop, compose as c,
  pathOr, composeP as cp, filter, compose, lte, pick, propEq, find, curry
} from 'ramda'
import {
  getAccountAgreements, clearSpaPage, updateAgreeStatus, deleteAccountAgreement,
  submitEdited, netsuiteEstimateAccountAgreement, updateMasterAgreementOwner, switchAccountAgreement,
} from '../actions'
import { mountAgreementToEdit, addEditMods, clearAgreementEdits, updateEditList } from './edit_agreement_reducer'
import { changeComment, changeMessage, removeComment, submitCommentChanges } from './messages_reducer'
import AgreementItems from './AgreementItems'
import EstimateAgreement from './EstimateAgreement'
import EditAgreement from './EditAgreement'
import DeleteAgreement from './DeleteAgreement'
import { dateString } from '../utilities/util'
import roleManagement from '../HOC/RoleManagement'
import { toggleModal } from '../reducers/modal_reducer'
import { mapDispatch, mapSpinToDispatch, isFatal, redSearchHandlerInit } from '../utilities/redux-util'
import downloadPDF from './PDF'
import HistoryModal from '../HistoryLogs/HistoryModal'
import ErrorPage from '../Essentials/ErrorPage'
import { incrementPage, selectFirstPage, clearModelsToView } from '../actions/index'
import UserSearch from '../Reusable/UserSearch'
import '../styles/Export.css'
import '../../node_modules/react-datepicker/dist/react-datepicker.css'

const mapIndexed = addIndex(map)
const capitalizeFirst = converge(concat, [c(toUpper, head), tail])

const getBestCostWithSource = (itemExpDate, itemContracts) => {
  const filterEligible = filter(prop('isEligible'))
  /* Ramda's lte seems to behave nicely when comparing two moment dates */
  const afterEndDate = compose(lte(itemExpDate), prop('endDate'))
  const filterEndDates = filter(anyPass([afterEndDate, prop('isPurchasePrice')]))
  const sortByPrice = sortBy(prop('price'))
  const pickDetails = pick(['price', 'contractEntity', 'isPurchasePrice', 'type', 'baseGpo', 'tier'])
  const findCost = compose(pickDetails, head, sortByPrice, filterEndDates, filterEligible)

  return findCost(itemContracts) || {}
}

const handleBack = (history) => {
  if (anyPass([equals(0), equals(1), equals(2)])(history.length)) history.push('/')
  else history.goBack()
}

class AgreementPage extends Component {

  // eslint-disable-next-line react/state-in-constructor
  state = {
    historyAgreementsOpen: false,
    historyMasterOpen: false,
    editAgreementOwner: false
  }

  componentDidMount() {
    const { getAccountAgreements, redSpin, switchAccountAgreement } = this.props
    const params = queryString.parse(this.props.location.search)
    const { id, agreementId } = params

    redSpin(getAccountAgreements(id), 'detailpage').then((res) => {
      if (!agreementId) return

      const selectedAgreement = compose(
        find(propEq('_id', Number(agreementId))),
        pathOr({}, ['payload']),
        find(propEq('type', 'ADD_ACCOUNT_AGREEMENTS')),
      )(res)

      if (!selectedAgreement) return

      switchAccountAgreement(selectedAgreement)
    })
  }


  componentDidUpdate(previousProps) {
    const { getAccountAgreements, location, redSpin } = this.props
    const params = queryString.parse(this.props.location.search)
    const { id } = params
    if (previousProps.location.search !== location.search) {
      redSpin(getAccountAgreements(id), 'detailpage')
    }
  }

  componentWillUnmount() {
    const { clearSpaPage } = this.props
    clearSpaPage()
  }

  render() {
    const {
      accountAgreements, accountAgreement, switchAccountAgreement, updateAgreeStatus,
      agreementMessages, changeMessage, history, isSalesManager, isAdmin, isPurchaseAssistant, modal, toggleModal,
      mountAgreementToEdit, spin, pageSuccess, updateMasterAgreementOwner
    } = this.props


    if (spin.detailpage) return <div className="loader" />
    if (pageSuccess === 'INSUFFICIENT') return <ErrorPage history={history} />

    const spaEdit = prop('spaEdit')(modal)
    const spaDelete = prop('spaDelete')(modal)
    const spaEstimate = prop('spaEstimate')(modal)

    const { message, comment, commentChanged } = agreementMessages

    const comments = pathOr([], ['comments'], this.props.accountAgreement).filter(f => f)
    const { _id: currentAgreementId } = accountAgreement
    const masterIdentifier = pathOr('', ['masterId', 'entityId'])(accountAgreement)
    const agreementOwnerFullName = pathOr('', ['accountAgreementOwner', 'first'], this.props) + ' ' + pathOr('', ['accountAgreementOwner', 'last'], this.props)
    const params = queryString.parse(this.props.location.search)
    const { id } = params

    const renderAccountAgreement = (accountAgree, index) => {
      const {
        accountId: account, status, createdAt, _id: currentAgreementId,
      } = accountAgree
      const { entityId } = account
      const accountEntity = entityId || ''
      const dateCreated = createdAt ? dateString(new Date(createdAt)) : 'N/A'

      return (
        <li
          key={index}
          className={`${equals(accountAgree._id, accountAgreement._id) ? 'selected ' : ''} icon${capitalizeFirst(status)}`}
          onClick={() => switchAccountAgreement(accountAgree)}
        >
          <div>
            <p
              className="bolder"
              style={{
                maxWidth: '250px',
                wordWrap: 'break-word',
              }}>{accountEntity}
            </p>
            <p>Agreement {currentAgreementId}</p>
            <p>{dateCreated}</p>
          </div>
          {
            (isAdmin || isSalesManager || isPurchaseAssistant)
            && (<div className="displayFlex">
              <button
                className={`btnReject${anyPass([equals('approved'), equals('rejected')])(status) ? ' none' : ''}`}
                onClick={() => updateAgreeStatus(accountAgree, 'rejected', accountAgreements, comment)} />
              <button
                className={`btnApprove${anyPass([equals('approved'), equals('rejected')])(status) ? ' none' : ''}`}
                onClick={() => updateAgreeStatus(accountAgree, 'approved', accountAgreements, comment)} />
            </div>
            )
          }
          {
            (equals(accountAgree._id, accountAgreement._id))
            && (<>
              <Tooltip title="Pricing Contract" placement="top">
                <button
                  className={`btnDownload tooltipped btnSmall ${anyPass([equals('approved')])(status) ? ' ' : ' none'}`}
                  data-position="top"
                  data-tooltip="Pricing Contract"
                  onClick={() => downloadPDF({ accountAgreements, currentAgreementId })} />
              </Tooltip>
              <Tooltip title="Netsuite Estimate" placement="top">
                <button
                  className={`btnEstimate btnSmall  ${anyPass([equals('approved')])(status) ? ' ' : ' none'}`}
                  onClick={() => {
                    toggleModal({ spaEstimate: true })
                  }}
                />
              </Tooltip>
              {spaEstimate && <EstimateAgreement {...this.props} getBestCostWithSource={getBestCostWithSource} changeMessage={changeMessage} />}

              <Tooltip title="Edit Agreement" placement="top">
                <button
                  className="btnEdit btnSmall"
                  onClick={() => {
                    toggleModal({ spaEdit: true })
                    mountAgreementToEdit(accountAgreement)
                  }} />
              </Tooltip>
              {spaEdit && <EditAgreement {...this.props} />}

              <Tooltip title="Delete Agreement" placement="top">
                <button
                  className="btnDelete btnSmall"
                  onClick={() => toggleModal({ spaDelete: true })}
                />
              </Tooltip>

              {spaDelete && <DeleteAgreement {...this.props} />}
            </>)
          }
        </li>
      )
    }

    const renderAccountAgreements = mapIndexed(renderAccountAgreement)

    const renderAlert = (message) => {
      if (!isEmpty(message)) {
        setTimeout(() => changeMessage(''), 3000)

        return (
          <div className="alertMsg">
            <span>{message}</span>
            <button onClick={() => changeMessage('')}>X</button>
          </div>
        )
      }
    }

    const onUserSelect = async (id) => {
      await updateMasterAgreementOwner(id)
      this.setState({ editAgreementOwner: false })
    }

    const renderAccountOwner = () => {
      if (!this.state.editAgreementOwner && (isSalesManager || isAdmin || isPurchaseAssistant))
        return <div className='agreement-owner-full-name editable' onClick={() => this.setState({ editAgreementOwner: true })}>{agreementOwnerFullName}</div>

      if (!this.state.editAgreementOwner)
        return <div className='agreement-owner-full-name'>{agreementOwnerFullName}</div>

      return (
        <div className='agreement-owner-full-name'>
          <UserSearch onSelect={curry(onUserSelect)} />
          {/* <div className="col-2"><button className="btnRemove" onClick={() => this.setState({ editAgreementOwner: false })} /></div> */}
        </div>
      )
    }

    const renderComments = () => {
      return comments.map((m, i) => {
        return (
          <Grid container key={i}>
            <Grid item>
              <RemoveCircleOutline onClick={() => this.props.removeComment({ comment: m, id: currentAgreementId })} style={{ margin: '15 10 0 0' }} />
            </Grid>
            <Grid item>
              <pre>{m}</pre>
            </Grid>
          </Grid>
        )
      })
    }

    const { historyAgreementsOpen, historyMasterOpen } = this.state

    return (
      <div className="contentDetail">
        <div className="headerDetail">
          <button type="button" className="btnPrev" onClick={() => handleBack(history)} />
          <div className="titleDetail agreements">
            <div className="infoIcon" />
            <div className="agreement-name">Master Agreement {masterIdentifier}</div>
            {renderAccountOwner()}
          </div>
          <Tooltip title="Duplicate the agreement" placement="left">
            <button type="button" className="btnCopy" onClick={() => history.push(`/updatespa?id=${id}&clone=1`)} />
          </Tooltip>
          
          {/* <button className="btnDownload" onClick={() => downloadPDF(accountAgreements)}></button>
          <button className="btnEstimate" onClick={() => {
            toggleModal({ spaEstimate: true })
          }}></button>
          {spaEstimate && <EstimateAgreement {...this.props} />}
          <button className="btnEdit" onClick={() => {
            toggleModal({ spaEdit: true })
            mountAgreementToEdit(accountAgreement)
          }} />
          {spaEdit && <EditAgreement {...this.props} />}
          <button
            className="btnDelete"
            onClick={() => toggleModal({ spaDelete: true })}
          />
          {spaDelete && <DeleteAgreement {...this.props} />} */}

        </div>
        <div className="listBody">
          <div className="listBodyHeader">
            <div className="tabOnDetail bolder">Customer List</div>
            <Tooltip title="Add customer to agreement" placement="left">
              <button type="button" className="btnAdd" onClick={() => history.push(`/updatespa?id=${id}`)} />
            </Tooltip>
            <Tooltip title={`Master Agreement ${id} Logs`} className="minWidthSm" placement="right">
              <button type="button" className="btnLogs" onClick={() => this.setState({ historyMasterOpen: true })} />
            </Tooltip>
          </div>
          <div className="selectList">
            <ul>
              {renderAccountAgreements(sortBy(prop('_id'))(accountAgreements))}
            </ul>
          </div>
        </div>
        <div className="listDetail">
          <div className="listBodyHeader">
            <div className="tabOnDetail bolder">Item List</div>
            <Tooltip title="Add items to all customers on this agreement" placement="left">
              <button type="button" className="btnAdd" onClick={() => history.push(`/updatespaitem?id=${id}`)} />
            </Tooltip>
            <Tooltip title={`Agreement ${currentAgreementId} Logs`} className="minWidthSm" placement="left">
              <button type="button" className="btnLogs" onClick={() => this.setState({ historyAgreementsOpen: true })} />
            </Tooltip>
          </div>
          <AgreementItems
            {...this.props}
            getBestCostWithSource={getBestCostWithSource}
          />
          <div className="noteDetail">
            <div className="scroll-200">
              {renderComments()}
            </div>
          </div>
          <div className="noteDetail">
            <textarea
              value={comment}
              onChange={e => this.props.changeComment(e.target.value)}
              placeholder="Leave a comment"
            />
            {commentChanged
              && <Button
                variant="contained"
                color="secondary"
                onClick={() => this.props.submitCommentChanges({ comment, id: currentAgreementId })}>
                Save Comments
                 </Button>}
          </div>
        </div>
        {message && renderAlert(message)}
        {historyAgreementsOpen
          && <HistoryModal
            open={historyAgreementsOpen}
            id={currentAgreementId}
            collection="AccountSellPriceAgreement"
            onClose={() => this.setState({ historyAgreementsOpen: false })}
            title={`Agreement ${currentAgreementId} Logs`}
          />}
        {historyMasterOpen
          && <HistoryModal
            open={historyMasterOpen}
            id={id}
            collection="MasterSellPriceAgreement"
            onClose={() => this.setState({ historyMasterOpen: false })}
            title={`Master Agreement ${id} Logs`}
          />}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    accountAgreements: state.accountAgreements,
    accountAgreement: state.accountAgreement,
    accountAgreementOwner: pathOr('', ['accountAgreement', 'masterId', 'salesRepId', 'name'], state),
    agreementMessages: state.agreementMessages,
    agreementEdit: state.agreementEdit,
    modal: state.modal,
    spin: state.spin,
    pageSuccess: state.pageSuccess,
    searchTerms: getFormValues('Search')(state)
  }
}


const emptyIsFatal = (ls) => (isEmpty(ls) ? Promise.reject('No such SPA') : Promise.resolve(ls))

const md2p = mapDispatch({ netsuiteEstimateAccountAgreement }, {
  switchAccountAgreement,
  mountAgreementToEdit,
  addEditMods,
  updateEditList,
  clearAgreementEdits,
  submitEdited,
  removeComment,
  submitCommentChanges,
  deleteAccountAgreement,
  changeComment,
  changeMessage,
  updateAgreeStatus,
  updateMasterAgreementOwner,
  clearSpaPage,
  toggleModal,
  clearModelsToView, selectFirstPage, incrementPage
}, (dispatch) => ({
  getAccountAgreements: c(isFatal, cp(emptyIsFatal, map(dispatch), getAccountAgreements)),
  redSpin: mapSpinToDispatch(dispatch),
  redSearchHandlerInit: redSearchHandlerInit(dispatch)
}))
export default connect(mapStateToProps, md2p)(roleManagement(AgreementPage))
