import axios from '../Essentials/axios'
import { SET_USER_OPTIONS, CLEAR_USER_OPTIONS } from './action_types'

export const getAgreementOwners = ({ searchTerm }) => {
    return async dispatch => {
        const { data, error } = await axios.get('/getAgreementOwners', { params: { searchTerm } })
        if (error) // eslint-disable-next-line no-console
            return console.error('error', error)
        dispatch({ type: SET_USER_OPTIONS, payload: data })
    }
}

export const clearUserOptions = () => ({ type: CLEAR_USER_OPTIONS })
