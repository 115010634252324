import React, { Component } from 'react'
import { compose } from 'ramda'
import { Link } from 'react-router-dom'
import paginate from '../HOC/paginate'
import ItemSearch from '../ReduxForm/ItemSearch'
import InfinityScroll from '../HOC/Infinity'

class AllItems extends Component {
    componentDidMount() {
        const {
 currentPage, selectFirstPage, getInitModels, redSpin,
} = this.props
        const { pathname } = this.props.location

        selectFirstPage()
        redSpin(getInitModels(pathname, 1), 'mainlist')
    }

    componentWillUnmount() {
        const { clearModelsToView, selectFirstPage } = this.props
        clearModelsToView('items')
        selectFirstPage()
    }

    loadNextPage() {
        const {
 visibleItems, totalItems, currentPage, itemSearchTerms, getPageOfModels, incrementPage, redSpin,
} = this.props
        const { pathname } = this.props.location
        if (visibleItems.length >= totalItems) return Promise.resolve()

        incrementPage()
        return redSpin(getPageOfModels(pathname, currentPage + 1, itemSearchTerms), 'loading')
    }


    renderNoResults() {
        return (
            <div className="errorMsg" />
        )
    }


    renderItems() {
        return this.props.visibleItems.map((item) => (
                <li key={item._id}>
                    <Link to={`/item?id=${item._id}`}>
                        <div className="col-2">{item.externalId}</div>
                        <div className="col-5 alignLeft bolder divider">{item.name}</div>
                        <div className="col-3">{item.manuName}</div>
                        <div className="col-2">{item.mpn}</div>
                    </Link>
                </li>
            ))
    }

    render() {
        const { visibleItems, totalItems, spin } = this.props
        return (
            <div className="listPage">
                <InfinityScroll loadMore={this.loadNextPage.bind(this)} />
                <div className="headerArea">
                    <div className="headerSticky">
                        <div>
                            <h2>Items</h2>
                            <p>Showing <span className="bolder">{visibleItems.length}</span> of <span>{totalItems}</span> Items</p>
                        </div>
                        <div className={`searchLoading${spin['/itemssearch'] ? ' loading' : ''}`}>
                            <ItemSearch {...this.props} />
                        </div>
                        <div className="headerBasicList">
                            <div className="col-2">ID</div>
                            <div className="col-5">Item Name</div>
                            <div className="col-3">Manufacturer</div>
                            <div className="col-2">MPN</div>
                        </div>
                    </div>
                </div>
                {spin.mainlist ? (<div className="basicList"><div className="loader" /></div>)
                    : (<div>
                        <div className="basicList">

                            <ul>
                                {this.renderItems()}
                            </ul>
                            {(totalItems === 0) && this.renderNoResults()}
                        </div>


                        <div className={`btnArea${spin.loading ? ' loader' : ''}`} />
                       </div>)}
            </div>
        )
    }
}

export default paginate(AllItems)
