import React, { Component } from 'react'
import { compose, prop, path } from 'ramda'
import { Link } from 'react-router-dom'
import paginate from '../HOC/paginate'
import Search from '../ReduxForm/Search'
import { dateString } from '../utilities/util'
import RoleManagement from '../HOC/RoleManagement'
import InfinityScroll from '../HOC/Infinity'
import SortableHeader from '../Reusable/SortableHeader'

class AllContracts extends Component {
    state = {
        // The initial state is used to style the table headers and indicate to user which column the table is being sorted by and in which direction:
        sort: {
            field: 'endDate',
            direction: 'desc',
        },
    }

    componentDidMount() {
        const { selectFirstPage, getInitModels, redSpin } = this.props
        const { pathname } = this.props.location

        selectFirstPage()
        redSpin(getInitModels(pathname, 1), 'mainlist')
    }

    componentWillUnmount() {
        const { clearModelsToView, selectFirstPage } = this.props
        clearModelsToView('contracts')
        selectFirstPage()
    }

    loadNextPage() {
        const {
            currentPage, visibleContracts, totalContracts, searchTerms, getPageOfModels, incrementPage, redSpin,
        } = this.props
        const { pathname } = this.props.location
        const sort = { [this.state.sort.field]: this.state.sort.direction }
        if (visibleContracts.length >= totalContracts) return Promise.resolve()

        incrementPage()
        return redSpin(getPageOfModels(pathname, currentPage + 1, searchTerms, sort), 'loading')
    }


    handleClick = () => {
        this.props.history.push('/contractinput')
    }

    sort = (field) => {
        const { dispatch, clearModelsToView } = this.props
        const { searchTerms, getInitModels, selectFirstPage } = this.props
        const { pathname } = this.props.location
        const { direction } = this.state.sort

        const newSortDirection = field !== this.state.sort.field ? 'asc' : direction === 'asc' ? 'desc' : 'asc'
        // if user clicked on new table head the first direction will be ascending, if not the direction will simply be toggled on each click

        window.scrollTo(0, 0)
        // scrolling to the top of the results on each sort

        this.setState({ sort: { field, direction: newSortDirection } },
            async () => {
                const sort = { [field]: this.state.sort.direction }
                selectFirstPage()
                dispatch(clearModelsToView('contracts'))
                await getInitModels(pathname, 1, searchTerms, sort)

            })
    }

    renderContracts() {
        const { visibleContracts } = this.props
        return visibleContracts.map((contract) => {
            const endDate = dateString(new Date(contract.endDate))
            const startDate = dateString(new Date(contract.startDate))
            const baseGpo = path(['contractSource', 'baseGpo'])(contract) || 'LVC'
            const sourceName = path(['contractSource', 'tier'])(contract) || ''
            const manuId = path(['manufacturerId', 'entityId'])(contract) || ''
            const manuName = path(['manufacturerId', 'name'])(contract) || ''
            const manufacturerName = path(['manufacturerName'])(contract) || ''
            const conEntity = prop('entityId')(contract) || ''
            return (
                <li key={contract._id}>
                    <Link to={`/contract?id=${contract._id}`}>
                        <div className="col-2 bolder">{conEntity}</div>
                        <div className="col-2 alignLeft bolder divider"> {`${manuId} - ${manuName}`}</div>
                        <div className="col-2 bolder divider"> {`${manufacturerName}`}</div>
                        <div className="col-1">{baseGpo}</div>
                        <div className="col-1 divider">{sourceName}</div>
                        <div className="col-2">{startDate}</div>
                        <div className="col-2">{endDate}</div>
                    </Link>
                </li>
            )
        })
    }

    renderSortableHeaders = () => {
        const headers = [
            { label: 'ID', name: 'entityId', col: '2', sortable: true },
            { label: 'Vendor', name: 'manufacturerId.entityId', col: '2', sortable: true },
            { label: 'Manufacturer', name: 'manufacturerName', col: '2', sortable: true },
            { label: 'Source', name: 'contractSource', col: '1', sortable: true },
            { label: 'Tier', name: 'type', col: '1', sortable: true },
            { label: 'Start Date', name: 'startDate', col: '2', sortable: true },
            { label: 'End Date', name: 'endDate', col: '2', sortable: true }
        ]

        return headers.map((h, index) => <SortableHeader key={index} label={h.label} name={h.name} columnWidth={h.col} sortable={h.sortable} selected={this.state.sort.field === h.name} direction={this.state.sort.direction} clickHandler={this.sort} />)
    }

    renderNoResults() {
        return (
            <div className="errorMsg" />
        )
    }

    render() {
        const {
            visibleContracts, totalContracts, isAdmin, isPurchaseAssistant, isPurchaseManager, isSalesManager, spin,
        } = this.props
        return (
            <div className="listPage">
                <h1></h1>
                <InfinityScroll loadMore={this.loadNextPage.bind(this)} />
                <div className="headerArea">
                    <div className="headerSticky">
                        <div>
                            <h2>Contracts</h2>
                            {(isAdmin || isPurchaseManager || isPurchaseAssistant || isSalesManager) && (
                                <button className="addContract" onClick={this.handleClick.bind(this)}>Add Contract</button>
                            )}
                            <p>Showing <span className="bolder">{visibleContracts.length}</span> of <span>{totalContracts}</span> Contracts</p>
                        </div>
                        <div className={`searchLoading${spin['/contractssearch'] ? ' loading' : ''}`}>
                            <Search {...this.props} />
                        </div>
                        {/* name?    */}
                        <div className="headerBasicList">
                            {this.renderSortableHeaders()}
                        </div>
                    </div>
                </div>

                {spin.mainlist ? (<div className="basicList"><div className="loader" /></div>)
                    : (<div>
                        <div className="basicList">
                            <ul>
                                {this.renderContracts()}
                            </ul>
                            {(totalContracts === 0) && this.renderNoResults()}
                        </div>
                        <div className={`btnArea${spin.loading ? ' loader' : ''}`} />
                    </div>)}
            </div>
        )
    }
}
export default compose(RoleManagement, paginate)(AllContracts)
// export default compose(RequireAuth, RequirePagination)(AllContracts)
