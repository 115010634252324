export const AUTH_USER = 'AUTH_USER'
export const AUTH_ERROR = 'AUTH_ERROR'
export const UNAUTH_USER = 'UNAUTH_USER'

export const FETCH_ = 'FETCH_'
export const SELECT_ = 'SELECT_'

export const FETCH_PAGE_ = 'FETCH_PAGE_'
export const FETCH_INIT_ = 'FETCH_INIT_'
export const SEARCH_ = 'SEARCH_'
export const CLEAR_ = 'CLEAR_'

export const INCREMENT_PAGE = 'INCREMENT_PAGE'
export const SELECT_FIRST_PAGE = 'SELECT_FIRST_PAGE'

// Agreements Page
export const ADD_ACCOUNT_AGREEMENTS = 'ADD_ACCOUNT_AGREEMENTS'
export const UPDATE_AGREEMENT_STATUS = 'UPDATE_AGREEMENT_STATUS'
export const EDIT_ACCOUNT_AGREEMENT = 'EDIT_ACCOUNT_AGREEMENT'
export const CHANGE_AGREEMENTS_COMMENTS = 'CHANGE_AGREEMENTS_COMMENTS'
export const CHANGE_CURRENT_AGREEMENT_COMMENTS = 'CHANGE_CURRENT_AGREEMENT_COMMENTS'
export const CLEAR_AGREE_EDITS = 'CLEAR_AGREE_EDITS'
export const SWITCH_ACCOUNT_AGREEMENT = 'SWITCH_ACCOUNT_AGREEMENT'
export const REMOVE_ACCOUNT_AGREEMENT = 'REMOVE_ACCOUNT_AGREEMENT'
export const CHANGE_COMMENT = 'CHANGE_COMMENT'
export const REMOVE_AGREEMENTS_COMMENTS = 'REMOVE_AGREEMENTS_COMMENTS'
export const REMOVE_CURRENT_AGREEMENT_COMMENTS = 'REMOVE_CURRENT_AGREEMENT_COMMENTS'
export const CHANGE_MESSAGE = 'CHANGE_MESSAGE'
export const CLEAR_SPA_PAGE = 'CLEAR_SPA_PAGE'
export const CHANGE_AGREEMENT_OWNER = 'CHANGE_AGREEMENT_OWNER'
export const UPDATE_CURRENT_AGREEMENT_OWNER = 'UPDATE_CURRENT_AGREEMENT_OWNER'

// contract unknown items found
export const GET_CONTRACT_UNKNOWN_ITEMS = 'GET_CONTRACT_UNKNOWN_ITEMS'
export const SET_INIT_CONFIRMED_UNKNOWN_ITEMS = 'SET_INIT_CONFIRMED_UNKNOWN_ITEMS'
export const SET_PROP_CONFIRMED_ITEMS = 'SET_PROP_CONFIRMED_ITEMS'
export const CONFIRM_ALL = 'CONFIRM_ALL'

//////Agreement Owners
export const SET_USER_OPTIONS = 'SET_USER_OPTIONS'
export const CLEAR_USER_OPTIONS = 'CLEAR_USER_OPTIONS'

export const SPINZY = 'SPINZY'
export const STOPZY = 'STOPZY'

