import React from 'react'
import {
 map, addIndex, pathOr, prop, isEmpty,
} from 'ramda'
import AccountChoices from './AccountChoices'
import Search from './Search'

const mapIndexed = addIndex(map)

const InputtedAccounts = (props) => {
        const {
 contractAccounts, visibleAccounts, contractErrors, removeAccountFromContract,
} = props

        const renderAccount = (account, index) => {
            const repFirstName = pathOr('', ['salesRepId', 'name', 'first'])(account)
            const repLastName = pathOr('', ['salesRepId', 'name', 'last'])(account)
            const comma = (!isEmpty(repFirstName) && !isEmpty(repLastName)) ? ',' : ''
            const name = prop('name')(account) || prop('companyName')(account) || ''

            return (
                <li key={index}>
                    <div className="col-4">{pathOr('', ['entityId'])(account)}</div>
                    <div className="col-3 alignLeft bolder">{name}</div>
                    <div className="col-2">{pathOr('', ['category'])(account)}</div>
                    <div className="col-2">{`${repLastName}${comma} ${repFirstName}`}</div>
                    <div className="col-1">
                        <button className="btnRemove" onClick={() => removeAccountFromContract(index)} />
                    </div>
                </li>
            )
        }

        const renderAccounts = mapIndexed(renderAccount)

        return (
            <div>
                <div className="customerSearch">
                    <Search {...props} />
                    <AccountChoices {...props} />
                </div>
                <div className="headerBasicList">
                    <div className="col-4">ID</div>
                    <div className="col-3">Name</div>
                    <div className="col-2">Category</div>
                    <div className="col-2">Sales Rep</div>
                </div>
                <div className="basicList unLinked">
                    <ul>
                        {(contractAccounts) && renderAccounts(contractAccounts)}
                    </ul>
                </div>
                <div className={`${contractErrors.accounts ? 'alertNoCustomer errorMsg' : ''}`} />
            </div>
        )
}

export default InputtedAccounts
