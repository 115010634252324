import axios from '../Essentials/axios'

const GET_RECENTLY_ORDERED = 'GET_RECENTLY_ORDERED'

export const getRecentlyOrdered = (data) => axios.post('/recentlyordered', data).then((data) => ({
                type: GET_RECENTLY_ORDERED,
                payload: data,
            })).catch((e) => console.log(e))


export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case GET_RECENTLY_ORDERED:
            return action.payload
        default:
            return init
    }
}
