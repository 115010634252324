import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { equals, anyPass, composeP, map } from 'ramda'
import { addToSPAUpdate, clearSPAUpdate, postAccountAgreement } from './spa_update_reducer'
import { postMasterSPA } from '../reducers/spa_create_reducer'
import { addToCreateSpaErrors } from '../reducers/create_spa_errors_reducer'
import { getMasterSPADetails } from './master_agreements_reducer'
import { getMasterItemData } from './master_item_data_reducer'
import { addAccItemMod } from './acc_item_mods_reducer'
import { searchModels, selectModel, clearModel, clearModelsToView } from '../actions/index'
import { mapDispatch, mapSpinToDispatch, redSearchHandlerInit } from '../utilities/redux-util'
import Stage1 from './Stage1'
import Stage2 from './Stage2'
import { addToUpdateErrors } from './update_stage1_errors_reducer'

const handleBack = (history) => {
    if (anyPass([equals(0), equals(1), equals(2)])(history.length)) history.push('/')
    else history.goBack()
}

class UpdateSPA extends Component {

    // eslint-disable-next-line react/state-in-constructor
    state = {
        isCloning: false
    }


    async componentDidMount() {
        const { addToSPAUpdate, getMasterSPADetails } = this.props
        const params = queryString.parse(this.props.location.search)
        const masterId = params.id
        const isCloning = params.clone == 1
        this.setState({ isCloning })

        addToSPAUpdate({ stage: 1 })
        await getMasterSPADetails(masterId)

        if (isCloning) {
            addToSPAUpdate({ entityId: '' })
        }
    }

    componentWillUnmount() {
        const { clearSPAUpdate } = this.props
        clearSPAUpdate()
    }

    render() {
        const { stage } = this.props.spaUpdate
        const { history } = this.props

        return (
            <div className="contentDetail">
                <div className="headerDetail">
                    <button className="btnPrev" onClick={() => handleBack(history)} />
                    <div className="titleDetail SPA">
                        <div className="infoIcon" />
                        <h2>{this.state.isCloning ? 'Duplicate Agreement' : 'Add Customer'}</h2>
                    </div>
                </div>
                <div className="progress update">
                    <div>
                        <p>Basic Info</p>
                        <button className={`${equals(1)(stage) ? 'selected' : ''}`}>1</button>
                    </div>
                    <div className={`step-2${equals(2)(stage) ? ' selected' : ''}`}>
                        <p>Item Cart</p>
                        <button className={`${equals(2)(stage) ? 'selected' : ''}`}>2</button>
                    </div>
                </div>
                {(stage == 1) && <Stage1 {...this.props} searchModels={searchModels} isCloning={this.state.isCloning} />}
                {(stage == 2) && <Stage2 {...this.props} searchModels={searchModels} isCloning={this.state.isCloning} />}
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    spaUpdate: state.spaUpdate,
    masterAgreements: state.masterAgreements,
    masterItemData: state.masterItemData,
    visibleAccounts: state.visibleAccounts,
    currentAccount: state.currentAccount,
    accItemMods: state.accItemMods,
    updateSpaInputError: state.updateSpaInputError,
    spin: state.spin,
    spaCreate: state.spaCreate,
})

const md2p = mapDispatch({
    getMasterItemData,
    postAccountAgreement,
    postMasterSPA
},
    {
        addAccItemMod,
        clearSPAUpdate,
        addToSPAUpdate,
        addToUpdateErrors,
        clearModelsToView,
        selectModel,
        clearModel,
        addToCreateSpaErrors
    },
    (dispatch) => ({
        redSpin: mapSpinToDispatch(dispatch),
        getMasterSPADetails: composeP(map(dispatch), getMasterSPADetails),
        redSearchHandlerInit: redSearchHandlerInit(dispatch),
    }))

export default connect(mapStateToProps, md2p)(UpdateSPA)
