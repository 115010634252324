import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { connect } from 'react-redux'
import { anyPass, equals, compose as c } from 'ramda'
import {
    postSettings,
    getCurrentSettings,
    changeContractAdvanceNotice,
    changeAgreementAdvanceNotice,
    changeContractFoundItemsRecipients,
    changeRecipients,
    changeAutoMargin,
    changePad,
    clearMessage,
    changeEnabled,
} from './settings_reducer'
import HistoryModal from '../HistoryLogs/HistoryModal'
import roleManagement from '../HOC/RoleManagement'
import { mapDispatch, mapSpinToDispatch } from '../utilities/redux-util'

const handleBack = (history) => {
    if (anyPass([equals(0), equals(1), equals(2)])(history.length)) history.push('/')
    else history.goBack()
}

class Settings extends Component {

    state = {
        historyOpen: false
    }

    componentDidMount() {
        const { getCurrentSettings, redSpin } = this.props
        redSpin(getCurrentSettings(), 'detailpage')
    }

    componentWillUnmount() {
        const { clearMessage } = this.props
        clearMessage()
    }


    render() {
        const {
            settings,
            changeAutoMargin,
            changeEnabled,
            changeContractAdvanceNotice,
            changeAgreementAdvanceNotice,
            changeRecipients,
            changeContractFoundItemsRecipients,
            changePad,
            postSettings,
            clearMessage,
            isAdmin,
            isSalesManager,
            history,
            spin,
        } = this.props
        const {
            autoMargin, message, enabled, defaultPad, expiringContractDays, expiringAgreementDays, contractFoundItemsRecipients, expiringContractRecipients,
        } = settings


        if (spin.detailpage) return <div className="loader" />
        if (!(isAdmin || isSalesManager)) {
            return (
                <h1> Sorry. You are not allowed here. </h1>
            )
        }

        const { historyOpen } = this.state

        return (
            <div className="contentDetail">
                <div className="headerDetail">
                    <button type="button" className="btnPrev" onClick={() => handleBack(history)} />
                    <div className="titleDetail settings">
                        <div className="infoIcon" />
                        <h2>Settings</h2>
                    </div>
                    <Tooltip title="Settings Logs" placement="left" className="minWidthSm">
                        <button type="button" className="btnLogs" onClick={() => this.setState({ historyOpen: true })} />
                    </Tooltip>
                </div>
                <div className="basicPanel">
                    <div>
                        <p>Auto-Approval</p>
                        <label className="switch">
                            <input className="switchAA" type="checkbox" checked={enabled} onClick={() => changeEnabled(!enabled)} />
                            <span className="slider" />
                        </label>
                    </div>
                    <div>
                        <p>Auto-Approval Margin</p>
                        <input
                            name="targetMargin"
                            className="setMargin"
                            type="number"
                            placeholder="Target Margin"
                            required="required"
                            value={autoMargin}
                            onChange={(e) => changeAutoMargin(e.target.value)}
                        />
                        <span />
                    </div>
                    <div className="no-border">
                        <p>Pad Margin</p>
                        <input
                            name="padMargin"
                            className="setMargin"
                            type="number"
                            placeholder="Pad Margin"
                            required="required"
                            value={defaultPad}
                            onChange={(e) => changePad(e.target.value)}
                        />
                        <span />
                    </div>
                    <div className="flex-line-break no-border"><hr /></div>
                    <div className="settings-section-title">Expiring Contract Notifications</div>
                    <div>
                        <p>Recipients</p>
                        <input
                            name="expiringContractRecipients"
                            className="setMargin no-percent"
                            type="string"
                            placeholder="Recipients"
                            required="required"
                            value={expiringContractRecipients}
                            onChange={(e) => changeRecipients(e.target.value)}
                        />
                    </div>
                    <div>
                        <p>Advance Notice (days)</p>
                        <input
                            name="expiringContractDays"
                            className="setMargin no-percent"
                            type="number"
                            placeholder="Advance Notice"
                            required="required"
                            value={expiringContractDays}
                            onChange={(e) => changeContractAdvanceNotice(e.target.value)}
                        />
                    </div>
                    <div className="flex-line-break no-border"><hr /></div>
                    <div className="settings-section-title">Expiring Agreement Notifications</div>
                    <div>
                        <p>Advance Notice (days)</p>
                        <input
                            name="expiringAgreementDays"
                            className="setMargin no-percent"
                            type="number"
                            placeholder="Advance Notice"
                            required="required"
                            value={expiringAgreementDays}
                            onChange={(e) => changeAgreementAdvanceNotice(e.target.value)}
                        />
                    </div>
                    <div className="flex-line-break no-border"><hr /></div>
                    <div className="settings-section-title">Contract Found Items Notifications</div>
                    <div>
                        <p>Recipients</p>
                        <input
                            name="expiringContractRecipients"
                            className="setMargin no-percent"
                            type="string"
                            placeholder="Recipients"
                            required="required"
                            value={contractFoundItemsRecipients}
                            onChange={(e) => changeContractFoundItemsRecipients(e.target.value)}
                        />
                    </div>
                </div>

                {message &&
                    <div className="alertMsg">
                        <span>{message}</span>
                        <button type="button" onClick={clearMessage} />
                    </div>}

                <div className="alignCenter col-12">
                    <button
                        type="button"
                        className="moreBtn aim"
                        onClick={() => {
                            postSettings(autoMargin, enabled, defaultPad, expiringContractDays, expiringContractRecipients, expiringAgreementDays, contractFoundItemsRecipients)
                            setTimeout(clearMessage, 3000)
                        }}
                    >Save
                    </button>
                </div>
                {historyOpen
                    && <HistoryModal
                        open={historyOpen}
                        collection="Settings"
                        onClose={() => this.setState({ historyOpen: false })}
                        title="Settings Logs"
                    />}
            </div>
        )
    }
}

const md2p = mapDispatch({
    getCurrentSettings, postSettings,
}, {
    changeAutoMargin,
    changeEnabled,
    changePad,
    clearMessage,
    changeRecipients,
    changeContractFoundItemsRecipients,
    changeContractAdvanceNotice,
    changeAgreementAdvanceNotice,
}, (dispatch) => ({ redSpin: mapSpinToDispatch(dispatch) }))

const ms2p = ({ settings, spin }) => ({ settings, spin })

export default c(connect(ms2p, md2p), roleManagement)(Settings)
