import { AUTH_USER, AUTH_ERROR, UNAUTH_USER } from '../actions/action_types'

export const currentUserReducer = (state, action) => {
    const init = state || {}

    switch (action.type) {
        case AUTH_USER:
            return action.user
        case UNAUTH_USER:
            return {}
        case AUTH_ERROR:
            return {}
         default:
            return init
    }
}

export const currentRoleReducer = (state, action) => {
        const init = state || {}

        switch (action.type) {
            case AUTH_USER:
                return action.role
            case UNAUTH_USER:
                return {}
            case AUTH_ERROR:
                return {}
             default:
                return init
        }
    }
