import { remove, uniqBy, prop } from 'ramda'
import { changeCP } from './contract_items_reducer'

const CLEAR_CON_SUBMIT = 'CLEAR_CON_SUBMIT'
const REMOVE_UNKNOWN_ITEM = 'REMOVE_UNKNOWN_ITEM'
const CLEAR_CONTRACT_ITEMS = 'CLEAR_CONTRACT_ITEMS'
const ADD_UNKNOWN_ITEMS = 'ADD_UNKNOWN_ITEMS'
const CHANGE_UNKNOWN_PRICE = 'CHANGE_UNKNOWN_PRICE'

export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case REMOVE_UNKNOWN_ITEM:
            return remove(action.payload, 1, init)
        case CHANGE_UNKNOWN_PRICE:
            return changeCP(action.payload.index, 'contractPrice', action.payload.price, init)
        case ADD_UNKNOWN_ITEMS:
            return uniqBy(prop('vendorCode'), [...init, ...action.payload])
        case CLEAR_CONTRACT_ITEMS:
            return []
        case CLEAR_CON_SUBMIT:
            return []
        default:
            return init
    }
}
