const MERGE_SPA_CREATE_ERROR = 'MERGE_SPA_CREATE_ERROR'
const CLEAR_SPA_CREATE = 'CLEAR_SPA_CREATE'

export const addToCreateSpaErrors = (errorObj) => ({ type: MERGE_SPA_CREATE_ERROR, payload: errorObj })

export default (state, action) => {
  const init = state || {
    account: false,
    expDate: false,
    targetMargin: false,
    emptyItems: false,
    sellPriceErrors: [],
  }

  switch (action.type) {
    case MERGE_SPA_CREATE_ERROR:
      return { ...init, ...action.payload }
    case CLEAR_SPA_CREATE:
      return {}
    default:
      return init
  }
}
