import { INCREMENT_PAGE, SELECT_FIRST_PAGE } from '../actions/action_types'

export default (state, action) => {
    const init = state || 1

    switch (action.type) {
        case INCREMENT_PAGE:
            return (init + 1)
        case SELECT_FIRST_PAGE:
            return 1
        default:
            return init
    }
}
