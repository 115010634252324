import axios from './axios'
import { SPINZY, STOPZY } from '../reducers/loader_reducer'

const FETCH_GLOBAL_RESULTS = 'FETCH_GLOBAL_RESULTS'
const CLEAR_GLOBAL_RESULTS = 'CLEAR_GLOBAL_RESULTS'
const SWITCH_VISIBILITY = 'SWITCH_VISIBILITY'
const SWITCH_HOVER = 'SWITCH_HOVER'

const fetchGlobal = (search) => axios.get('/globalsearch', {
    params: {
      search,
    },
  })

export const getGlobalSearchResults = (search) => fetchGlobal(search)
    .then((response) => ({ type: FETCH_GLOBAL_RESULTS, payload: response.data }))
    .catch((e) => console.log(e))

export const switchVisibility = (bool) => ({ type: SWITCH_VISIBILITY, payload: bool })
export const switchHover = (bool) => ({ type: SWITCH_HOVER, payload: bool })
export const clearGlobalResults = () => ({ type: CLEAR_GLOBAL_RESULTS })

export default (state, action) => {
  const init = state || {
    results: [],
    visibility: false,
    hovering: false,
  }

  switch (action.type) {
    case FETCH_GLOBAL_RESULTS:
      return { ...init, results: action.payload }
    case SWITCH_VISIBILITY:
      return { ...init, visibility: action.payload }
    case SWITCH_HOVER:
      return { ...init, hovering: action.payload }
    case CLEAR_GLOBAL_RESULTS:
      return { ...init, results: [] }
    default:
      return init
  }
}
