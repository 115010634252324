import React from 'react'
import { WarningSharp } from '@material-ui/icons'

export default () => {
  return (
    <span style={{ display: 'inline-flex', flexDirection: 'column', paddingLeft: '7px' }}>
      <WarningSharp htmlColor={'red'} style={{ margin: '0 auto' }} />
      <i>Inactive item</i>
    </span>
  )
}