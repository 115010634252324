import axios from '../Essentials/axios'
import { CHANGE_AGREEMENTS_COMMENTS, CHANGE_CURRENT_AGREEMENT_COMMENTS, REMOVE_AGREEMENTS_COMMENTS, REMOVE_CURRENT_AGREEMENT_COMMENTS } from '../actions/action_types'

const CHANGE_MESSAGE = 'CHANGE_MESSAGE'
const CHANGE_COMMENT = 'CHANGE_COMMENT'
const SUBMIT_COMMENT = 'SUBMIT_COMMENT'
const CLEAR_SPA_PAGE = 'CLEAR_SPA_PAGE'
const CLEAR_COMMENT = 'CLEAR_COMMENT'

export const changeComment = (value) => (dispatch) => {
    dispatch({
        type: CHANGE_COMMENT,
        payload: value,
    })
}

export const submitCommentChanges = ({ comment, id }) => (dispatch) => {
    dispatch({ type: CLEAR_COMMENT })

    axios.post('/updateAgreementComments', { comment, id }).then((res) => {
        dispatch({
            type: CHANGE_AGREEMENTS_COMMENTS,
            payload: res.data,
        })
        dispatch({
            type: CHANGE_CURRENT_AGREEMENT_COMMENTS,
            payload: res.data,
        })
    })
}

export const removeComment = ({ comment, id }) => (dispatch) => {
    dispatch({
        type: REMOVE_AGREEMENTS_COMMENTS,
        payload: { comment, id },
    })
    dispatch({
        type: REMOVE_CURRENT_AGREEMENT_COMMENTS,
        payload: { comment, id },
    })
    axios.post('/updateAgreementComments', { comment, id, remove: true }).then((res) => {
       
    })
}

export const changeMessage = (value) => (dispatch) => {
    dispatch({
        type: CHANGE_MESSAGE,
        payload: value,
    })
}


export default (state, action) => {
    const init = state || { message: '', comment: '', commentChanged: false }

    switch (action.type) {
        case CHANGE_COMMENT:
            return { ...init, ...{ commentChanged: true, comment: action.payload } }
        case CLEAR_COMMENT:
            return { ...init, ...{ commentChanged: false, comment: '' } }
        case SUBMIT_COMMENT:
            return { ...init, ...{ commentChanged: false, comment: action.payload } }
        case CHANGE_MESSAGE:
            return { ...init, message: action.payload }
        case CLEAR_SPA_PAGE:
            return {}
        default:
            return init
    }
}
