import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import * as Sentry from '@sentry/react'


import { isEmpty, isNil, keys } from 'ramda'
import axios from './axios'



const downloadCSV = (data, fileName) => {
    const objectURL = new Blob([data])
    const url = window.URL.createObjectURL(objectURL, { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(objectURL)
}

const getExpiringAgreements = (startDate, endDate) => {

    return axios.get('/expiringagreements', {
            params: {
                startDate,
                endDate
            },
        })
}

const getExpiringItems = (startDate, endDate) => {

    return axios.get('/expiringitems', {
            params: {
                startDate,
                endDate
            },
        })
}


class Expiring extends Component {
  constructor(props) {
    super(props)
    this.state = { 
        expItemsStartDate: '', expItemsStartDateError: false, 
        expItemsEndDate: '', expItemsEndDateError: false,
        expItemsLoaderSpinning: false,

        expAgreesStartDate: '', expAgreesStartDateError: false, 
        expAgreesEndDate: '', expAgreesEndDateError: false,
        expAgreesLoaderSpinning: false,

        generalError: false, generalErrorMsg: 'Sorry, something went wrong!' 
           
        
    }
  }

  render() {

    return (
        <div className='centeredOnPage' >

            <div className="col-12">
            
            <div className="basicPanel">
    
            <div className={`${this.state.expAgreesStartDateError ? ' error' : ''}`}>
    
                <p>Start Date</p>
                <div className="relativeP">
                  <DatePicker
                    selected={
                       isEmpty(this.state.expAgreesStartDate) ? null : 
                       this.state.expAgreesStartDate
                   }
                    onChange={date => {
                        if (isNil(date)){
                            this.setState({ expAgreesStartDate: '' })
                        }
                        else {
                            this.setState({ expAgreesStartDate: date })
                        } 
                    }}
                    onFocus={() => this.setState({ expAgreesStartDateError: false })}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
            </div>
    
             <div className={`${this.state.expAgreesEndDateError ? ' error' : ''}`}>
    
                <p>End Date</p>
                <div className="relativeP">
                  <DatePicker
                    selected={
                       isEmpty(this.state.expAgreesEndDate) ? null : 
                       this.state.expAgreesEndDate
                   }
                    onChange={date => {
                        if (isNil(date)){
                            this.setState({ expAgreesEndDate: '' })
                        }
                        else {
                            this.setState({ expAgreesEndDate: date })
                        } 
                    }}
                    onFocus={() => this.setState({ expAgreesEndDateError: false })}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
            </div>
    
           
            <div className="alignCenter">
            {this.state.expAgreesLoaderSpinning ? <div className="loader-med" /> :

              <button 
               className="moreBtn aim fitText" 
               onClick={() => {
                   const emptyStart = isEmpty(this.state.expAgreesStartDate)
                   const emptyEnd = isEmpty(this.state.expAgreesEndDate)
                   if (emptyStart || emptyEnd)
                       return this.setState({ 
                           expAgreesStartDateError: emptyStart,
                           expAgreesEndDateError: emptyEnd
                       })
                   else {
                     
                        /* Add some safe indexing here ? */
                       const startDate = this.state.expAgreesStartDate._d
                       const endDate = this.state.expAgreesEndDate._d

                       /* Confirm that start date preceeds the end date */
                       if (startDate > endDate)
                        return this.setState({
                                generalError: true, 
                                generalErrorMsg: 'The start date must preceed the end date.' 
                            })

                       this.setState({ expAgreesLoaderSpinning: true })

                       getExpiringAgreements(startDate, endDate)
                       .then(({ data, ...response }) => {
                        console.log('Response : ', response)

                           console.log('Data : ', data)
                           const d = new Date()
                           const fileName = `Expiring-Agreements-${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}-${d.getTime()}.csv`
                           downloadCSV(data, fileName)
                           this.setState({ expAgreesLoaderSpinning: false })
                       })
                       .catch(e => { 
                        /* To do, add the axios request to the Sentry error ? */
                        console.log('Error : ', e)
                        
                        Sentry.captureException({ 
                            comment: 'Error caught making a request for expiring agreements.',
                            error: e,
                            requestStartDate: startDate,
                            requestEndDate: endDate,
                            requestBody: 'Should I add the whole axios request to the error?'
                        })
                        
                        this.setState({ 
                            generalError: true, 
                            generalErrorMsg: 'Sorry, something went wrong!',
                            expAgreesLoaderSpinning: false 
                        })
                    })
                   }
               }}>
                Download Expiring Agreements
              </button>}
            </div>
          </div>
          </div>

            <div className="col-12">

            <div className="basicPanel">

            <div className={`${this.state.expItemsStartDateError ? ' error' : ''}`}>

                <p>Start Date</p>
                <div className="relativeP">
              <DatePicker
                selected={(isEmpty(this.state.expItemsStartDate)) ? null : this.state.expItemsStartDate}
                onChange={date => {
                    if (isNil(date)){
                        this.setState({ expItemsStartDate: '' })
                    }
                    else {
                        this.setState({ expItemsStartDate: date })
                    } 
                }}
                onFocus={() => this.setState({ expItemsStartDateError: false })}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
        </div>

         <div className={`${this.state.expItemsEndDateError ? ' error' : ''}`}>

            <p>End Date</p>
            <div className="relativeP">
              <DatePicker
                selected={(isEmpty(this.state.expItemsEndDate)) ? null : this.state.expItemsEndDate}
                onChange={date => {
                    if (isNil(date)){
                        this.setState({ expItemsEndDate: '' })
                    }
                    else {
                        this.setState({ expItemsEndDate: date })
                    } 
                }}
                onFocus={() => this.setState({ expItemsEndDateError: false })}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
        </div>

       
        <div className="alignCenter">
        {this.state.expItemsLoaderSpinning ? <div className="loader-med" /> :
        <button 
            className="moreBtn aim fitText" 
            onClick={() => {
                const emptyStart = isEmpty(this.state.expItemsStartDate)
                const emptyEnd = isEmpty(this.state.expItemsEndDate)
                if (emptyStart || emptyEnd)
                    return this.setState({ 
                        expItemsStartDateError: emptyStart,
                        expItemsEndDateError: emptyEnd
                    })
                else {
                    /* Add some safe indexing here ? */
                    const startDate = this.state.expItemsStartDate._d
                    const endDate = this.state.expItemsEndDate._d
 
                    /* Confirm that start date preceeds the end date */
                    if (startDate > endDate)
                    return this.setState({
                            generalError: true, 
                            generalErrorMsg: 'The start date must preceed the end date.' 
                        })

                    this.setState({ expItemsLoaderSpinning: true })

                    getExpiringItems(startDate, endDate)
                    .then(({ data }) => {

                        console.log('Data : ', data)
                        const d = new Date()
                        const fileName = `Expiring-Items-${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}-${d.getTime()}.csv`

                        downloadCSV(data, fileName)
                        this.setState({ expItemsLoaderSpinning: false })

                    })
                    .catch(e => { 
                        /* To do, add the axios request to the Sentry error ? */
                        console.log('Error : ', e)

                        Sentry.captureException({ 
                            comment: 'Error caught making a request for expiring items.',
                            error: e,
                            requestStartDate: startDate,
                            requestEndDate: endDate,
                            requestBody: 'Should I add the whole axios request to the error?'
                        })
                        this.setState({ 
                            generalError: true,  
                            generalErrorMsg: 'Sorry, something went wrong!',
                            expItemsLoaderSpinning: false 
                        })
                    })
                }
            }}>
            Download Expiring Items
          </button>}
        </div>

      </div>   

      </div>
        {this.state.generalError &&  
            <div className="dangerMsg">
                <span>{this.state.generalErrorMsg}</span>
                <button 
                    className="hoverable"
                    onClick={() => this.setState({ generalError: false })}
                >X</button>
            </div>
        }

       </div>
      
    )
  }

}

export default Expiring
