import { CLEAR_CON_SUBMIT } from './contract_submit_reducer'

const CHANGE_SEARCH_VALUE = 'CHANGE_SEARCH_VALUE'

export const changeSearchValue = (string) => ({ type: CHANGE_SEARCH_VALUE, payload: string })

export default (state, action) => {
    const init = state || ''

    switch (action.type) {
        case CHANGE_SEARCH_VALUE:
            return action.payload
        case CLEAR_CON_SUBMIT:
            return ''
        default:
            return init
    }
}
