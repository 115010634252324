import axios from 'axios'
import { pathToFileURL } from 'url'

const instanceAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
})

export default instanceAxios




/////THE FOLLWING CODE ID FROM A DIFFERENT APP (DEALMED CENTRAL)
////This is the proper way to refresh axios headers and not do a window reload like is being done in this app
// export const refreshTokenMiddleware = (axios) => {
//   axios.interceptors.response.use(async (response) => {
//       try {
//           const { exp, iat } = getTokenDecoded()
//           if (!exp) return response

//           if (exp > moment().unix() && exp < moment().add('minutes', 20).unix()) {

//               if (localStorage.getItem('tokenRefreshing') !== '1') {
//                   localStorage.setItem('tokenRefreshing', '1')
//                   const { data } = await ax.get('/api/auth/refreshToken', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })

//                   console.log('refreshed token')
//                   localStorage.setItem('token', data.token)
//                   axios.defaults.headers['Authorization'] = `Bearer ${data.token}`
//                   localStorage.setItem('tokenRefreshing', '0')
//               }
//           }
//       } catch (e) {
//           console.log(e)
//       }

//       return response
//   })
// }

// export const scheduleExpiredTokenCheck = () => {
//   try {
//       const { exp } = getTokenDecoded()

//       const checkExpiration = (time) => {
//           console.log('checking if token expired')
//           setTimeout(() => {
//               if (exp < moment().unix()) {
//                   localStorage.removeItem('token')
//                   window.location.reload()
//               }
//               checkExpiration(time)
//           }, time)//300000)
//       }

//       if (exp)
//           checkExpiration(600000)
//   } catch (e) {
//       console.log(e)
//   }
// }

// export const getTokenDecoded = () => {
//   try {
//       const token = localStorage.getItem('token')
//       var base64Url = token.split('.')[1]
//       var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
//       return JSON.parse(window.atob(base64))
//   } catch (e) {
//       return {}
//   }
// }

// // eslint-disable-next-line import/prefer-default-export
// export const resolveReq = async (promise, noRedirect) => {
//   try {
//       const { data } = await promise
//       return { data }
//   } catch (error) {
//       const { data, statusText, status } = error.response
//       if (status === 401 && !noRedirect)
//           window.location.href = '/login'
//       const message = data.message || data
//       return { error: mergeRight(error, { message: message }) }
//   }
// }