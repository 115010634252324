import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AUTH_USER, UNAUTH_USER } from '../actions/action_types'
import axios from './axios'

const lstoreUserCreds = (payload) => {
  Object.keys(payload).forEach((key) => {
    localStorage.setItem(
      key,
      typeof payload[key] === 'object'
        ? JSON.stringify(payload[key])
        : payload[key]
    )
  })

  return payload
}

const applyCreds = (creds, history) => {
  localStorage.clear()
  return (dispatch) => {
    axios
      .post('/devcreds', creds)
      .then(({ data }) => lstoreUserCreds(data))
      .then((data) => {
        axios.defaults.headers.common.Authorization = `Bearer ${data.token}`
        return data
      })
      .then(({ user, role }) => dispatch({ type: AUTH_USER, user, role }))
      .then(() => {
        history.push('/')
        window.location.reload()
      })
      .catch((e) => dispatch({ type: UNAUTH_USER, payload: e }))
  }
}

class DevCreds extends Component {
  constructor(props) {
    super(props)
    this.state = { nsuser: '', nsrole: '' }
  }

  render() {
    return (
      <div className="listPage">
        <div className="listPage">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              this.props.applyCreds(this.state, this.props.history)
            }}
          >
            <input
              type="text"
              name="nsuser"
              value={this.state.nsuser}
              placeholder="Netsuite User ID"
              onChange={(e) => this.setState({ nsuser: e.target.value })}
            />
            <input
              type="text"
              name="nsrole"
              value={this.state.nsrole}
              placeholder="Netsuite Role ID"
              onChange={(e) => this.setState({ nsrole: e.target.value })}
            />
            <input type="submit" value="Log In" />
          </form>
        </div>
      </div>
    )
  }
}

export default connect(null, { applyCreds })(DevCreds)
