
export const CLEAR_CON_SUBMIT = 'CLEAR_CON_SUBMIT'
export const MERGE_CON_ERROR = 'MERGE_CON_ERRORS'

export const addToContractErrors = (errorObj) => ({ type: MERGE_CON_ERROR, payload: errorObj })

export default (state, action) => {
    const init = state || {
        manufacturerId: false,
        type: false,
        startDate: false,
        endDate: false,
        pad: false,
        contractSource: false,
        baseGpo: false,
        tier: false,
        contractPrices: false,
        contractPriceErrors: [],
        accounts: false,
        }


    switch (action.type) {
        case MERGE_CON_ERROR:
            return { ...init, ...action.payload }
        case CLEAR_CON_SUBMIT:
            return {}
        default:
            return init
    }
}
