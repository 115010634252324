import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import RequireAuth from '../HOC/RequireAuth'

import AccountPage from '../AccountPage/AccountPage'
import ItemPage from '../ItemPage/ItemPage'
import ManuPage from '../ManuPage/ManuPage'
import ContractPage from '../ContractPage/ContractPage'

import AllManus from '../ListBased/AllManus'
import AllAccounts from '../ListBased/AllAccounts'
import AllItems from '../ListBased/AllItems'
import AllContracts from '../ListBased/AllContracts'
import AllSpas from '../AllSpas/AllSpas'
import AllGpos from '../AllGpos/AllGpos'

import Menu from './Menu'
import TopBar from './TopBar'
import ContractInput from '../ContractInput/ContractInput'
import LogOut from './LogOut'
import SSO from './SSO'

import DevCreds from './DevCreds'

import Expiring from './Expiring'

import CreateSPA from '../CreateSPA/CreateSPA'
import UpdateSPA from '../UpdateSPA/UpdateSPA'
import DownloadSPA from '../DownloadSPA/DownloadSPA'
import UpdateSPAItem from '../UpdateSPAItem/UpdateSPAItem'

import AgreementPage from '../AgreementPage/AgreementPage'

import '../styles/App.css'
import Settings from '../Settings/Settings'
import ErrorPage from '../Testing/ErrorPage'
import { isFatal } from '../utilities/redux-util'
import ContractUnknownItems from '../ContractUnknownItems/ContractUnknownItems'
// import SlackChat from '../SlackChat/SlackChat'


const App = () => {
  const allGood = () => {
    isFatal(Promise.resolve({}), 'All Good')
    return ''
  }

  return (
    <BrowserRouter>
      <Menu />
      <Route component={TopBar} />
      <Route component={allGood} />

      <Switch>

        <Route exact path="/" component={AllSpas} />
        <Route exact path="/downloadspa" component={DownloadSPA} />
        <Route exact path="/item" component={ItemPage} />
        <Route exact path="/manufacturer" component={ManuPage} />
        <Route exact path="/contract" component={ContractPage} />
        <Route exact path="/contractinput" component={ContractInput} />
        <Route exact path="/contracts" component={AllContracts} />
        <Route exact path="/contractUnknownItems" component={ContractUnknownItems} />
        <Route exact path="/account" component={AccountPage} />
        <Route exact path="/manus" component={AllManus} />
        <Route exact path="/accounts" component={AllAccounts} />
        <Route exact path="/items" component={AllItems} />
        <Route exact path="/createspa" component={CreateSPA} />
        <Route exact path="/updatespa" component={UpdateSPA} />
        <Route exact path="/updatespaitem" component={UpdateSPAItem} />
        <Route exact path="/spa" component={AgreementPage} />
        <Route exact path="/gpos" component={AllGpos} />
        <Route exact path="/logout" component={LogOut} />
        <Route exact path="/signin" component={() => <div />} />
        <Route exact path="/ssologin/:token" component={SSO} />
        <Route exact path="/devlogin" component={DevCreds} />
        <Route exact path="/expiring" component={Expiring} />
        <Route exact path="/settings" component={Settings} />
        <Route component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  )
}

export default RequireAuth(App)
