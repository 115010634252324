import React, { useState, useEffect } from 'react'
import {
    head, keys, values, map, omit, compose, type,
} from 'ramda'
import ReactJson from 'react-json-view'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from '../Essentials/axios'

const fullWidth = true
const maxWidth = 'xl'

const convertDataType = (key, val) => {
    if (type(val) === 'Array' && val.length === 1) {
        return { [key]: head(val) }
    }

    return val
}

const HistoryModal = ({
    open, title, message, onClose, collection, id,
}) => {
    const cols = [
        { name: 'timestamp', label: 'Date', options: { filter: false } },
        { name: 'user', label: 'SetBy', options: { filter: false } },
        { name: 'version', label: 'Version', options: { filter: false } },
        {
            name: 'values',
            label: 'Values',
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value, tableMeta) => <ReactJson name={null} iconStyle="square" enableClipboard={false} collapsed displayDataTypes={false} displayObjectSize={false} src={value} index={tableMeta.columnIndex} />,
            },
        },
    ]

    const [history, setHistory] = useState([])
    const [columns, setColumns] = useState(cols)

    const getHistory = async (tableState) => axios.get(`/history/${collection}`, { params: { ...tableState, id } })
        .then((s) => s.data.map((log) => {
            const diffValues = compose(
                map((m) => convertDataType(head(keys(m)), head(values(m)))),

            )(omit(['updatedAt'], log.diff))

            const user = log.user && `${log.user.name.first} ${log.user.name.last}`
            return {
                timestamp: moment(log.timestamp).format('MM/DD/YYYY HH:mm'),
                user,
                version: log.version,
                values: omit(['updatedAt'], diffValues)
            }
        })).then((s) => {
            setHistory(s)
        })

    useEffect(() => {
        getHistory({ rowsPerPage: 50, searchText: '', page: 0 })

        return () => {
            setHistory([])
        }
    }, [])


    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={onClose}
            aria-labelledby="max-width-dialog-title"
        >
            {message && <DialogTitle id="max-width-dialog-title">{message}</DialogTitle>}
            <MUIDataTable
                title={title}
                data={history}
                columns={columns}
                options={{
                    search: false,
                    pagination: false,
                    filter: false,
                    download: false,
                    print: false,
                    serverSide: true,
                    onColumnSortChange: async (sortColumn, sortDirection) => {
                        const directionShortName = sortDirection === 'ascending' ? 'asc' : 'desc'
                        setColumns((s) => s.map((c) => {
                            if (c.name === sortColumn) return { ...c, options: { ...c.options, sortDirection: directionShortName } }

                            return { ...c, options: omit(['sortDirection'], c.options) }
                        }))
                        await getHistory({ sortColumn, sortDirection: directionShortName })
                    },
                    selectableRows: 'none',
                }}
            />
        </Dialog>
    )
}

export default HistoryModal
