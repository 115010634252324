import { curry } from 'ramda'

// start and stop are function of name,
// run at the start and resolution of until - a promise - respectively
// eslint-disable-next-line import/prefer-default-export
export const interim = curry((start, stop, fail, until, name) => {
    start(name)
    return until.then((x) => {
        stop(name)
        return x
    }).catch((x) => {
        fail(name)
        return x
    })
})
