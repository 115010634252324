import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    slice, map, equals, addIndex, toLower, compose, reject, prop, sortBy, o, join, pathOr
} from 'ramda'
import { Link } from 'react-router-dom'
import GlobalSearch from './GlobalSearch'
import DefaultProfile from '../images/img_profile_sales.png'
import {
    getGlobalSearchResults, clearGlobalResults, switchVisibility, switchHover,
} from './global_search_reducer'
import { mapDispatch, redSearchHandlerInit } from '../utilities/redux-util'

const mapIndexed = addIndex(map)

const filterNumeric = compose(join(''), reject(isNaN))
const numberFromId = o(filterNumeric, prop('_id'))
const globalSearchSort = sortBy(numberFromId)

const profile = {
    backgroundImage: `url(${DefaultProfile})`,
}

class TopBar extends Component {
    render() {
        const {
            globalSearch, currentUser, currentRole, spin, switchHover, switchVisibility,
        } = this.props
        const { results, visibility } = globalSearch

        const toRoute = (modelType) => {
            if (equals(modelType)('Master Agreement') || equals(modelType)('Account Agreement')) return 'spa'
            if (equals(modelType)('Customer')) return 'account'
            if (equals(modelType)('Vendor')) return 'manufacturer'
            else return toLower(modelType)
        }


        const renderResult = (result, index) => {
            const { modelType, _id } = result
            const agreementCustomer = pathOr('', ['accountId', 'entityId'], result)
            const isAccountAgreement = modelType === 'Account Agreement'
            const isMasterAgreement = modelType === 'Master Agreement'

            const agreementName = isMasterAgreement ? pathOr('', ['entityId'], result) : ''
            const agreementId = isAccountAgreement || isMasterAgreement ? result.id : ''
            const resultName = result.name || result.companyName || agreementCustomer || agreementName
            const identifier = agreementId || result.externalId || result.entityId || ''
            const rerouteId = isAccountAgreement ? result.masterId : _id

            return (
                <li
                    key={index}
                    className="highLighted"
                    onMouseOver={() => switchHover(true)}
                    onMouseLeave={() => switchHover(false)}
                >
                    <Link
                        to={`/${toRoute(modelType)}?id=${rerouteId}`}
                        onClick={() => switchVisibility(false)}
                    >
                        <div className="col-2">{modelType}</div>
                        <div className="col-3">{identifier}</div>
                        <div className="col-7 alignLeft bolder">{resultName}</div>
                    </Link>
                </li>
            )
        }

        const renderResults = (results) => {
            const sortedResults = globalSearchSort(results)
            const visibleResults = slice(0, 21, sortedResults)

            return mapIndexed(renderResult)(visibleResults)
        }
        const { first, last } = prop('name', currentUser) || { first: '', last: '' }
        const userName = `${first} ${last}`
        const userRole = prop('text', currentRole)

        return (
            <div className="topBar">
                <div className={`globalSearch${spin.global ? ' loading' : ''}`}>
                    <GlobalSearch
                        {...this.props}
                        toRoute={toRoute}
                        globalSearchSort={globalSearchSort}
                    />
                    <div className="resultBySearch">
                        <div>
                            <ul>
                                {visibility && renderResults(results)}
                            </ul>
                        </div>
                    </div>
                </div>
                <div style={profile} className="profile">
                    <span className="role sales">{userRole}</span>
                    <p className="accountName">{userName}</p>
                </div>
                <a href={process.env.NODE_ENV !== 'production' ? 'https://system.netsuite.com/app/center/card.nl' : 'https://system.na1.netsuite.com/app/center/card.nl'} rel="noopener noreferrer" target="_blank">
                    <button className="btnNet" />
                </a>
            </div>
        )
    }
}

const mapStateToProps = ({
    globalSearch, currentUser, currentRole, spin,
}) => ({
    globalSearch, currentUser, currentRole, spin,
})

const md2p = mapDispatch({}, {
    clearGlobalResults,
    switchVisibility,
    switchHover,
}, (dispatch) => ({
    getGlobalSearchResults: redSearchHandlerInit(dispatch, getGlobalSearchResults, 'global'),
}))
export default connect(mapStateToProps, md2p)(TopBar)
