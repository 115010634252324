import React, { Component } from 'react'

class IdInput extends Component {
    render() {
        const { contractSubmit, addToContractSubmit } = this.props
        const { editMode } = contractSubmit
        return (

            <div>
                <p>Contract ID (optional)</p>
                <input
                    type="text"
                    name="id"
                    disabled={editMode}
                    value={(contractSubmit.entityId) ? contractSubmit.entityId : ''}
                    placeholder="Contract ID"
                    autoComplete="off"
                    onChange={(e) => addToContractSubmit({ entityId: e.target.value })} />
            </div>
        )
    }
}

export default IdInput
