const CLEAR_CON_SUBMIT = 'CLEAR_CON_SUBMIT'
const FETCH_INIT_ITEMS = 'FETCH_INIT_ITEMS'
const FETCH_PAGE_ITEMS = 'FETCH_PAGE_ITEMS'
const SEARCH_ITEMS = 'SEARCH_ITEMS'
const CLEAR_ITEMS = 'CLEAR_ITEMS'
const CLEAR_SPA_CREATE = 'CLEAR_SPA_CREATE'

export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case FETCH_INIT_ITEMS:
            return action.payload
        case FETCH_PAGE_ITEMS:
            return init.concat(action.payload)
        case SEARCH_ITEMS:
            return action.payload
        case CLEAR_CON_SUBMIT:
            return []
        case CLEAR_ITEMS:
            return []
        case CLEAR_SPA_CREATE:
            return []
        default:
            return init
    }
}
