import React, { Component } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

export default class InfinityScroll extends Component {
    constructor(props) {
        super(props)
        this.state = { loading: false }
        this.loadMore = debounce(() => {
            if (!this.state.loading) this.props.loadMore().then(() => this.setState({ loading: false }))
        }, 250)
    }


    componentDidMount() {
        window.addEventListener('scroll', this.onScroll, false)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false)
    }

    onScroll = (e) => {
        if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 700)) {
            this.loadMore()
        }
    }

    render() {
        return <div />
    }
}
