import axios from '../Essentials/axios'
import { pathOr } from 'ramda'
import { enhanceModelString } from '../utilities/util'
import {
  AUTH_ERROR,
  FETCH_,
  SELECT_,
  FETCH_PAGE_,
  FETCH_INIT_,
  SEARCH_,
  CLEAR_,
  INCREMENT_PAGE,
  SELECT_FIRST_PAGE
} from './action_types'


export const authError = error => {
  return dispatch => {
    dispatch({
      type: AUTH_ERROR,
      payload: error
    })
  }
}


export const getModel = (model, id) => {
  return axios.get(`${model}`, {
    params: {
      id: id
    }
  })
    .then(response => {
      console.log(response)
      return {
        type: FETCH_ + enhanceModelString(model),
        payload: response.data
      }
    })
}
//model starts with backslash
export const selectModel = (modelName, modelObj) => ({ type: SELECT_ + enhanceModelString(modelName), payload: modelObj })

export const clearModel = modelName => ({ type: CLEAR_ + enhanceModelString(modelName) })

export const getPageOfModels = (model, page, searchTerms, sort = null) => {

  return axios.get(`${model}/paginated`, {
    params: {
      page,
      ...searchTerms,
      sort
    }
  })
    .then(response => ({
      type: FETCH_PAGE_ + enhanceModelString(model),
      payload: response.data.docs,
      total: response.data.total
    }))
    .catch(e => ({ ...e, location: 'getModels: ' + model }))
}

//Higher order action creator...depends on if you want items, manus, or customers...think of action.type to dispatch
export const searchModels = (model, page, searchTerms) => {
  return axios.get(`${model}/paginated`, {
    params: {
      page,
      ...searchTerms
    }
  })
    .then(response => ({
      type: SEARCH_ + enhanceModelString(model),
      payload: response.data.docs,
      total: response.data.total
    })).catch(e => console.log(e))

}

export const getInitModels = (model, page, searchTerms, sort = null) => {

  return axios.get(`${model}/paginated`, {
    params: {
      page: page,
      searchTerms: searchTerms,
      sort
    }
  })
    .then(response => ({
      type: FETCH_INIT_ + enhanceModelString(model),
      payload: response.data.docs,
      total: response.data.total
    }))
}

// needredspin
export const accountItemSearch = searchTerms => {
  return axios
    .get('/accountitemsearch', {
      params: {
        ...searchTerms
      }
    })
    .then(response => {
      return ({
        type: 'SEARCH_ITEMS',
        payload: response.data,
        total: pathOr(0, ['data'])(response).length,
      })
    })
}

export const clearModelsToView = model => ({ type: CLEAR_ + enhanceModelString(model) })

//Actions with same payload...
export const incrementPage = () => ({ type: INCREMENT_PAGE })

export const selectFirstPage = () => ({ type: SELECT_FIRST_PAGE })
