import React, { Component } from 'react'
import { pathOr, isEmpty } from 'ramda'
import { Link } from 'react-router-dom'
import { sliceToWhiteSpace } from '../utilities/util'

const renderContactInfo = (account) => {
    const phone = pathOr('', ['contact', 'phone'])(account)
    const email = pathOr('', ['contact', 'email'])(account)
    const address = pathOr('', ['contact', 'address'])(account)
    return (
        <div className="borderTop">
            <li>
                <p>PHONE</p>
                <Link to={`tel:${phone}`} target="_parent">
                    <span className="phoneDetail">{phone}</span>
                </Link>
            </li>
            <li>
                <p>EMAIL</p>
                <Link to={`mailto:${email}`} target="_parent">
                    <span className="mailtoDetail">{email}</span>
                </Link>
            </li>
            <li>
                <p>ADDRESS</p>
                <span>{address}</span>
            </li>
        </div>
    )
}

class Details extends Component {
    render() {
        const { currentAccount, history } = this.props
        const {
 salesRepId, name, companyName, entityId, category,
} = currentAccount

        const accountName = name || companyName
        const slicedEntityId = sliceToWhiteSpace(entityId)
        const accountCategory = category || ''
        const priceLevel = pathOr('', ['priceLevel'])(currentAccount)
        const repFirst = pathOr('', ['salesRepId', 'name', 'first'])(currentAccount)
        const repLast = pathOr('', ['salesRepId', 'name', 'last'])(currentAccount)
        const comma = (isEmpty(repLast) || isEmpty(repFirst)) ? '' : ','

        return (
            <div className="infoBody">
                <ul>
                    <li>
                        <p>ID</p>
                        <span>{slicedEntityId}</span>
                    </li>
                    <li>
                        <p>CATEGORY</p>
                        <span>{accountCategory}</span>
                    </li>
                    <li>
                        <p>NAME</p>
                        <span>{accountName}</span>
                    </li>
                    <li>
                        <p>SALES REP</p>
                        <span>{`${repLast}${comma} ${repFirst}`}</span>
                    </li>
                    <li>
                        <p>PRICE LEVEL</p>
                        <span>{priceLevel}</span>
                    </li>
                    {renderContactInfo(currentAccount)}
                </ul>
            </div>
        )
    }
}

export default Details
