import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import {
  anyPass, isEmpty, map, isNil, reduce,
  and, values, complement, mapObjIndexed, equals, mergeAll,
  compose as c, reject, prop, composeP,
} from 'ramda'
import CSVReader from 'react-csv-reader'
import { Tabs, TabLink, TabContent } from 'react-tabs-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { selectSourceType, selectBaseGpo, selectTier } from './source_reducer'
import { getSources } from '../reducers/sources_reducer'
import {
 getItemsOffCSV, removeItemFromContract, changeContractPrice, addItemToContract, clearContractItems,
} from './contract_items_reducer'
import { addAccountToContract, removeAccountFromContract } from './contract_accounts_reducer'
import { changeSearchValue } from './manu_search_reducer'
import {
 addToContractSubmit, postContract, getPad, mountContractToEdit,
} from './contract_submit_reducer'
import { addToContractErrors } from './contract_errors_reducer'
import {
 searchModels, clearModelsToView, selectModel, clearModel,
} from '../actions/index'
import {
 mapDispatch, mapSpinToDispatch, redSearchHandlerInit, isFatal,
} from '../utilities/redux-util'
import { unpick } from '../utilities/util'
import RoleManagement from '../HOC/RoleManagement'
import InputtedItems from './InputtedItems'
import InputtedAccounts from './InputtedAccounts'
import IdInput from './IdInput'
import PadInput from './PadInput'
import ManuSearch from './ManuSearch'
import SelectGPO from './SelectGPO'
import SelectTier from './SelectTier'
import '../../node_modules/react-datepicker/dist/react-datepicker.css'
import ErrorPage from '../Essentials/ErrorPage'

const handleBack = (history) => {
  if (anyPass([equals(0), equals(1), equals(2)])(history.length)) history.push('/accounts')
  else history.goBack()
}

class ContractInput extends Component {
  componentDidMount() {
    const {
      getSources, clearModelsToView, clearModel,
      addToContractSubmit, getPad, selectSourceType, mountContractToEdit,
      isAdmin, isSalesManager, isPurchaseAssistant, isPurchaseManager, history, redSpin,
    } = this.props

    if (!(isAdmin || isPurchaseAssistant || isPurchaseManager || isSalesManager)) history.push('/')

    const params = queryString.parse(this.props.location.search)
    const { edit, id } = params

    redSpin(getSources(), 'sources')
    clearModelsToView('manus')

    if (edit && id) redSpin(mountContractToEdit(id), 'detailpage')

    else {
      clearModel('manufacturer')
      addToContractSubmit({
        entityId: '',
        manufacturerId: '',
        type: 'gpo',
        startDate: '',
        endDate: '',
        baseGpo: '',
        tier: '',
        contractPrices: [],
        accounts: [],
      })
      selectSourceType('gpo')
      redSpin(getPad(), 'detailpage')
    }
  }

  componentWillUnmount() {
    const { clearModel } = this.props
    clearModel('CON_SUBMIT')
  }

  render() {
    const {
      sources, source, contractSubmit, selectBaseGpo, selectSourceType,
      addToContractSubmit, currentManu, contractItems, unknownItems, selectTier, postContract,
      contractAccounts, addToContractErrors, contractErrors, history,
      spin, getItemsOffCSV, pageSuccess,
    } = this.props

    if (spin.detailpage || spin.sources) return <div className="loader" />
    if (pageSuccess === 'INSUFFICIENT') return <ErrorPage history={history} />

    const {
 startDate: rawStartDate, endDate: rawEndDate, pad, editMode,
} = contractSubmit
    const startDate = moment.utc(rawStartDate)
    const endDate = moment.utc(rawEndDate)

    const handleContractSubmit = () => {
      const contractPrices = map((item) => ({ itemId: item._id, contractPrice: item.contractPrice, uom: item.selectedUom }), contractItems)
      const accounts = map((account) => account._id, contractAccounts)

      const contract = {
        ...contractSubmit,
        unknownItems,
        contractPrices,
        type: source.type,
        contractSource: equals('gpo')(source.type) ? source._id : '',
        manufacturerId: currentManu._id || '',
        accounts,
      }

      const ignoreErrors = (source, contract) => {
        if (source.type === 'lvc') return unpick(['baseGpo', 'tier', 'contractSource', 'entityId', 'editMode', 'unknownItems'])(contract)

        else return unpick(['entityId', 'editMode', 'accounts', 'unknownItems'])(contract)
      }

      const valuesOnCon = values(ignoreErrors(source, contract))

      const checkItemPrice = (item) => {
        const price = prop('contractPrice', item)
        if (anyPass([equals('0'), isEmpty, isNil, isNaN, equals(0)])(price)) return item._id

        else return false
      }

      const checkItemPrices = map(checkItemPrice)
      const filterFalse = reject(equals(false))
      const itemErrors = c(filterFalse, checkItemPrices)(contractItems)
      const mapToBools = map(complement(isEmpty))

      const listOfBools = [...mapToBools(valuesOnCon), isEmpty(itemErrors)]

      const allGood = reduce(and)(true)

      if (allGood(listOfBools)) {
        const params = queryString.parse(this.props.location.search)
        const { id } = params

        postContract(contract, this.props.history, id)
      } else {
        const checkErrorOnCon = (val, key) => {
          if (key === 'contractPrices') {
            if (isEmpty(val)) return { [key]: true }
            else return { contractPriceErrors: itemErrors }
          }

          if (isEmpty(val)) return { [key]: true }
          else return { [key]: false }
        }

        const errorObject = mergeAll(values(mapObjIndexed(checkErrorOnCon)(ignoreErrors(source, contract))))

        addToContractErrors(errorObject)
      }
    }

    const handleStartDate = (event) => addToContractSubmit({ startDate: event })

    const handleEndDate = (event) => addToContractSubmit({ endDate: event })

    const handleCSVInput = (input) => getItemsOffCSV(input, currentManu._id)

    return (
      <div className="contentDetail">
        <div className="headerDetail">
          <button className="btnPrev" onClick={() => handleBack(history)} />
          <div className="titleDetail contracts">
            <div className="infoIcon" />
            <h2>{`Contract ${editMode ? 'Edit' : 'Input'}`}</h2>
          </div>
          <CSVReader
            cssClass={`csvReader${(isEmpty(currentManu)) ? ' disabled' : ''}`}
            onFileLoaded={handleCSVInput}
            onError={() => console.log('Sorry try again')}
          />
        </div>
        <div className="infoBody">
          <ul>
            <li>
              <IdInput
                addToContractSubmit={addToContractSubmit}
                contractSubmit={contractSubmit}
                addToContractErrors={addToContractErrors}
              />
            </li>
            <li
              className={`manuSearch${
                contractErrors.manufacturerId ? ' error' : ''
                }`}
            >
              <p>Select Vendor </p>
              <ManuSearch {...this.props} searchModels={searchModels} />
            </li>
            <li>
              <p>Source</p>
              <form className="radioComponent">
                <div>
                  <input
                    type="radio"
                    name="source"
                    value="GPO"
                    checked={source.type === 'gpo'}
                    onChange={() => {
                      selectSourceType('gpo')
                      addToContractSubmit({ baseGpo: '', tier: '' })
                    }}
                  />
                  <label htmlFor="GPO">GPO</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="source"
                    value="LVC"
                    checked={source.type === 'lvc'}
                    onChange={() => {
                      selectSourceType('lvc')
                      addToContractSubmit({ baseGpo: '', tier: '' })
                    }}
                  />
                  <label htmlFor="GPO">LVC</label>
                </div>
              </form>
              {source.type === 'gpo' && (
                <SelectGPO
                  sources={sources}
                  selectBaseGpo={selectBaseGpo}
                  source={source}
                  error={contractErrors.baseGpo}
                  addToContractSubmit={addToContractSubmit}
                  addToContractErrors={addToContractErrors}
                />
              )}
              {!isNil(source.baseGpo)
                && !isEmpty(source.baseGpo)
                && source.type !== 'lvc' && (
                  <SelectTier
                    tiers={sources.filter((src) => src.baseGpo === source.baseGpo)}
                    source={source}
                    selectTier={selectTier}
                    addToContractSubmit={addToContractSubmit}
                    addToContractErrors={addToContractErrors}
                    error={contractErrors.tier}
                  />
                )}
            </li>
            <li className={`${contractErrors.startDate ? ' error' : ''}`}>
              <p>Start Date</p>
              <div className="relativeP">
                <DatePicker
                  selected={(isEmpty(rawStartDate)) ? null : startDate}
                  onChange={handleStartDate}
                  onFocus={() => addToContractErrors({ startDate: false })}
                  {...(!isEmpty(rawEndDate) ? { maxDate: endDate } : {})}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </li>
            <li className={`${contractErrors.endDate ? ' error' : ''}`}>
              <p>End Date</p>
              <div className="relativeP">
                <DatePicker
                  selected={(isEmpty(rawEndDate)) ? null : endDate}
                  onChange={handleEndDate}
                  onFocus={() => addToContractErrors({ endDate: false })}
                  {...(!isEmpty(rawStartDate) ? { minDate: startDate } : {})}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </li>
            <li>
              <PadInput
                addToContractSubmit={addToContractSubmit}
                pad={pad}
                error={contractErrors.pad}
                addToContractErrors={addToContractErrors}
              />
            </li>
          </ul>
        </div>

        <div className="listDetail">
          <Tabs
            {...source.type === 'gpo' && { selectedTab: 'tab1' }}
            renderActiveTabContentOnly
          >
            <div className="tabOnDetail">
              <TabLink to="tab1" className={`tab-link${contractErrors.contractPrices ? ' error' : ''}`}>
                <p>Items List</p>
              </TabLink>
              {source.type === 'lvc' && (
                <TabLink to="tab2" className={`tab-link${contractErrors.accounts ? ' error' : ''}`}>
                  <p>Customers List</p>
                </TabLink>
              )}
            </div>
            <TabContent for="tab1">
              <InputtedItems {...this.props} searchModels={searchModels} />
            </TabContent>
            {source.type === 'lvc' && (
              <TabContent for="tab2">
                <InputtedAccounts {...this.props} searchModels={searchModels} />
              </TabContent>
            )}
          </Tabs>
        </div>
        <div className="alignCenter lastChild">
          <button className="moreBtn aim" onClick={handleContractSubmit}>
            Finish
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    currentPage: state.currentPage,
    currentManu: state.currentManu,
    visibleManus: state.visibleManus,
    visibleItems: state.visibleItems,
    visibleAccounts: state.visibleAccounts,
    contractItems: state.contractItems,
    unknownItems: state.unknownItems,
    contractAccounts: state.contractAccounts,
    contractSubmit: state.contractSubmit,
    contractErrors: state.contractErrors,
    source: state.source,
    sources: state.sources,
    manuSearchValue: state.manuSearchValue,
    spin: state.spin,
    pageSuccess: state.pageSuccess,
  })

const md2p = mapDispatch({
  getSources,
  getPad,
  postContract,
},
  {
    selectModel,
    clearModel,
    changeContractPrice,
    addToContractSubmit,
    addToContractErrors,
    addItemToContract,
    removeItemFromContract,
    addAccountToContract,
    removeAccountFromContract,
    selectSourceType,
    selectBaseGpo,
    selectTier,
    clearModelsToView,
    changeSearchValue,
  }, (dispatch) => ({
    redSpin: mapSpinToDispatch(dispatch),
    redSearchHandlerInit: redSearchHandlerInit(dispatch),
    mountContractToEdit: c(isFatal, composeP(map(dispatch), mountContractToEdit)),
    clearContractItems: c(map(dispatch), clearContractItems),
    getItemsOffCSV: composeP(map(dispatch), getItemsOffCSV),
  }))

export default RoleManagement(connect(mapStateToProps, md2p)(ContractInput))
