import {
 prop, filter, not, contains, concat, map, compose, __,
} from 'ramda'

const ADD_ACC_ITEM_MODS = 'ADD_ACC_ITEM_MODS'
const CLEAR_SPA_UPDATE = 'CLEAR_SPA_UPDATE'


export const addAccItemMod = (modObj) => ({ type: ADD_ACC_ITEM_MODS, payload: [modObj] })

export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case ADD_ACC_ITEM_MODS:
            {
                const payIds = map(prop('item'))(action.payload)
                const pred = compose(not, contains(__, payIds), prop('item'))
                return concat(filter(pred)(init))(action.payload)
            }
        case CLEAR_SPA_UPDATE:
            return []
        default:
            return init
    }
}
