import React, { Component } from 'react'
import {
 compose as c, isEmpty, reject, objOf, pathOr, map, prop,
} from 'ramda'

class ItemFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            externalId: '',
            name: '',
            manuName: '',
            mpn: '',
        }
    }

    searchHandlerInit = () => {
        const handleSearch = (e) => {
            const { accountItemSearch, currentAccount, clearModelsToView } = this.props
            const { name, value } = e
            const change = objOf(name, value)
            const searchTerms = { ...this.state, ...change }

            if (isEmpty(reject(isEmpty, searchTerms))) return Promise.resolve(clearModelsToView('items'))

            const { _id, priceLevel } = currentAccount
            const contractSources = c(map(prop('_id')), pathOr([], ['contractSources']))(currentAccount)

            const searchP = accountItemSearch({
 ...searchTerms, ...change, accountId: _id, contractSources, level: priceLevel,
})
            this.setState(change)
            return searchP
        }
        return this.props.redSearchHandlerInit(handleSearch, '/accountitemsearch')
    }

    searchHandler = c(this.searchHandlerInit(), ({ target }) => ({ name: target.name, value: target.value }))


    render() {
        return (
            <div className="itemSearch">
                <form onChange={this.searchHandler}>
                    <div className="col-2">
                        <input className="padded" name="externalId" type="text" placeholder="Item #" autoComplete="off" />
                    </div>
                    <div className="col-2">
                        <input className="padded" name="name" type="text" placeholder="Item Name" autoComplete="off" />
                    </div>
                    <div className="col-2">
                        <input className="padded" name="manuName" type="text" placeholder="Manufacturer" autoComplete="off" />
                    </div>
                    {/* if MPN is unnecessary just get rid of below div */}
                    <div className="col-2">
                        <input className="padded" name="mpn" component="input" type="text" placeholder="MPN" autoComplete="off" />
                    </div>
                    {this.props.spin['/accountitemsearch']
                        && <div className="loader-sm" />}
                </form>
            </div>

        )
    }
}

export default ItemFilter
