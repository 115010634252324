import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
 head, isEmpty, pathOr, or, useWith as ruseWith, map, addIndex,
} from 'ramda'
import RequireAuth from '../HOC/RequireAuth'
import paginate from '../HOC/paginate'
import InfinityScroll from '../HOC/Infinity'
import Search from '../ReduxForm/Search'

const mapIndexed = addIndex(map)

const sliceToWhiteSpace = (str) => {
    if (isEmpty(str)) return str

    else return head((/\w+/).exec(str))
}


const renderSalesRepInfo = (account) => {
    const repLast = pathOr('', ['salesRepId', 'name', 'last'])(account)
    const repFirst = pathOr('', ['salesRepId', 'name', 'first'])(account)
    const comma = ruseWith(or, [isEmpty, isEmpty])(repLast)(repFirst) ? '' : ','
    return (
        <div>
            {`${repLast}${comma} ${repFirst}`}
        </div>
    )
}

const renderAccount = (account, index) => {
    const {
 salesRepId, entityId, name, companyName,
} = account
    const accountName = name || companyName
    const slicedEntityId = sliceToWhiteSpace(entityId)

    return (
        <li key={index}>
            <Link to={`/account?id=${account._id}`}>
                <div className="col-2">{slicedEntityId}</div>
                <div className="col-4 alignLeft bolder">{accountName}</div>
                <div className="col-1 divider">{account.category}</div>
                <div className="col-1">{renderSalesRepInfo(account)}</div>
                <div className="col-2">
                    <p className="mailtoList">
                        {/* <Link to={'mailto:' + account.contact.email} target="_parent"> */}
                        {pathOr('', ['contact', 'email'])(account)}
                        {/* </Link> */}
                    </p>
                </div>
                <div className="col-2">{pathOr('', ['contact', 'phone'])(account)}</div>
            </Link>
        </li>
    )
}

const renderAccounts = mapIndexed(renderAccount)

class AllAccounts extends Component {
    componentDidMount() {
        const {
 currentPage, selectFirstPage, getInitModels, redSpin,
} = this.props
        const { pathname } = this.props.location

        selectFirstPage()
        redSpin(getInitModels(pathname, 1), 'mainlist')
    }

    componentWillUnmount() {
        const { clearModelsToView, selectFirstPage } = this.props
        clearModelsToView('accounts')
        selectFirstPage()
    }

    loadNextPage() {
        const {
 currentPage, searchTerms, getPageOfModels, incrementPage, visibleAccounts, totalAccounts, redSpin,
} = this.props
        if (visibleAccounts.length >= totalAccounts) return Promise.resolve()

        const { pathname } = this.props.location
        incrementPage()
        return redSpin(getPageOfModels(pathname, currentPage + 1, searchTerms), 'loading')
    }

    renderButton() {
        const { spin } = this.props

        return (
            <button className="moreBtn" onClick={this.loadNextPage.bind(this)}>Load More</button>
        )
    }

    renderNoResults() {
        return (
            <div className="errorMsg" />
        )
    }

    render() {
        const { visibleAccounts, totalAccounts, spin } = this.props
        
        return (
            <div className="listPage">
                <InfinityScroll loadMore={this.loadNextPage.bind(this)} />
                <div className="headerArea">
                    <div className="headerSticky">
                        <div>
                            <h2>Customers</h2>
                            <p>Showing <span className="bolder">{visibleAccounts.length}</span> of <span>{totalAccounts}</span> Customers</p>
                        </div>
                        <div className={`searchLoading${spin['/accountssearch'] ? ' loading' : ''}`}>
                            <Search {...this.props} />
                        </div>
                        <div className="headerBasicList">
                            <div className="col-2">ID</div>
                            <div className="col-4">Name</div>
                            <div className="col-1">Category</div>
                            <div className="col-1">Sales Rep</div>
                            <div className="col-2">Email</div>
                            <div className="col-2">Phone</div>
                        </div>
                    </div>
                </div>

                {spin.mainlist ? (<div className="basicList"><div className="loader" /></div>)
                    : (<div>
                        <div className="basicList">
                            <ul>
                                {renderAccounts(visibleAccounts)}
                            </ul>
                            {(totalAccounts === 0) && this.renderNoResults()}
                        </div>
                        <div className={`btnArea${spin.loading ? ' loader' : ''}`} />
                       </div>)}
            </div>

        )
    }
}
export default paginate(AllAccounts)
