import React, { Component } from 'react'
import {
 isNil, filter, head, propOr, prop, pathOr, compose,
} from 'ramda'

class Details extends Component {
    render() {
        const { currentItem, isAdmin } = this.props

        const { purchasePrice } = currentItem
        const conversionRate = pathOr(1, ['conversionRate'])(currentItem)
        const vendors = pathOr([], ['vendors'])(currentItem)
        const isPreferred = prop('isPreferred')
        const preferredVendorCost = compose(prop('cost'), head, filter(isPreferred))(vendors)
        const WAC = preferredVendorCost ? (preferredVendorCost / conversionRate).toFixed(2) : 'Not Applicable'
        const PPU = (isNil(purchasePrice)) ? 'Not Applicable' : (purchasePrice / conversionRate).toFixed(2)

        return (

            <div className="infoBody">
                <ul>
                    {!process.env.production && (
                        <li>
                            <p>INTERNAL ID</p>
                            <span>{currentItem._id}</span>
                        </li>
                    )}
                    <li>
                        <p>ITEM</p>
                        <span>{propOr('', 'externalId')(currentItem)}</span>
                    </li>
                    <li>
                        <p>Description</p>
                        <span>{pathOr('', ['name'])(currentItem)}</span>
                    </li>
                    <li>
                        <p>Manufacturer Name</p>
                        <span>{pathOr('', ['manuName'])(currentItem)}</span>
                    </li>
                    <li>
                        <p>MPN</p>
                        <span>{pathOr('', ['mpn'])(currentItem)}</span>
                    </li>
                    <li>
                        <p>Units Type</p>
                        <span>{pathOr('', ['unitsTypeId', 'name'])(currentItem)}</span>
                    </li>
                    <li>
                        <p>COST</p>
                        <span>&#36;{PPU}</span>
                    </li>

                    {isAdmin && (
                        <li>
                            <p>WAC</p>
                            <span>&#36;{WAC}</span>
                        </li>
                    )}
                </ul>
            </div>
        )
    }
}

export default Details
