import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { loginSSO } from '../actions'
import { clearGlobalResults } from './global_search_reducer'

const SSO = (props) => {
  const { isAuthenticated } = props
  useEffect(() => {
    const { token } = props.match.params
    props.loginSSO({ token })
    console.log("HERE", token)
  }, [props.match.params])

  useEffect(() => {
    if (!isAuthenticated)
      return

    props.history.push('/')
    setTimeout(() => {
      ///The reason a reload is being done here is because the axios headers were already set without a token, so all requests come back as unauthorized
      ////The proper way to deal with this is to have a function that resets the axios headers, once logged in. An example of this can be seen in dealmed central
      ////However since this doesn't exist from the creators of this app, and elsewhere in the app it is being done with a reload (see Essentials/DevCreds.js) I am not fixing this now
      window.location.reload()
    }, 750)
  }, [isAuthenticated])

  return (
    <div />
  )
}

const mapStateToProps = ({ auth }) => {
  return { isAuthenticated: auth.authenticated }
}

export default connect(mapStateToProps, { loginSSO })(withRouter(SSO))