import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { curry } from 'ramda'
import Tooltip from '@material-ui/core/Tooltip'
import roleManagement from '../HOC/RoleManagement'

const isActive = curry((alt, m, l) => m || l.pathname === alt)

class Menu extends Component {
  constructor() {
    super()
    this.state = {
      height: true,
    }
  }

  changeToggle() {
    this.setState({ height: !this.state.height })
  }

  setCollapse() {
    this.setState({ height: 'false' })
  }

  render() {
    const {
      isAdmin,
      isSalesManager,
      isSalesRep,
      isPurchaseManager,
      isPurchaseAssistant,
      isInsideSales,
    } = this.props
    return (
      <nav className="nav">
        <button className="btnMenu" onClick={this.changeToggle.bind(this)} />
        {/* {(isPurchaseAssistant || isPurchaseManager) && (
          <NavLink exact to="/contracts" onClick={() => this.setCollapse()}>
            <div className="companyLogo menu-hover"></div>
          </NavLink>
        )} */}
        {(isAdmin || isSalesManager || isSalesRep || isInsideSales || isPurchaseAssistant || isPurchaseManager) && (
          <NavLink exact to="/" onClick={() => this.setCollapse()}>
            <div className="companyLogo" />
          </NavLink>
        )}
        {(isAdmin || isSalesManager || isSalesRep || isInsideSales || isPurchaseAssistant) && (
          <Tooltip title="Create Agreement" placement="right">
            <NavLink to="/createspa" onClick={() => this.setCollapse()}>
              <div className="createspa menu-hover">
                <p className="menu-label">CREATE AGREEMENT</p>
              </div>
            </NavLink>
          </Tooltip>
        )}

        <ul className={this.state.height ? 'collapse' : 'expand'}>
          {(isAdmin || isSalesManager || isSalesRep || isInsideSales || isPurchaseAssistant || isPurchaseManager) && (
            <Tooltip title="Agreements" placement="right">
              <NavLink exact to="/" isActive={isActive('/spa')} onClick={() => this.setCollapse()}>
                <li className="agreements menu-hover">
                  <p className="menu-label">AGREEMENTS</p>
                </li>
              </NavLink>
            </Tooltip>
          )}
          <Tooltip title="Customers" placement="right">
            <NavLink to="/accounts" isActive={isActive('/account')} onClick={() => this.setCollapse()}>
              <li className="customers menu-hover">
                <p className="menu-label">CUSTOMERS</p>
              </li>
            </NavLink>
          </Tooltip>
          {(isPurchaseAssistant || isPurchaseManager || isAdmin || isSalesManager) && (
            <Tooltip title="Vendors" placement="right">
              <NavLink to="/manus" isActive={isActive('/manufacturer')} onClick={() => this.setCollapse()}>
                <li className="vendors menu-hover">
                  <p className="menu-label">VENDORS</p>
                </li>
              </NavLink>
            </Tooltip>)}
          <Tooltip title="Items" placement="right">
            <NavLink to="/items" isActive={isActive('/item')} onClick={() => this.setCollapse()}>
              <li className="items menu-hover">
                <p className="menu-label">ITEMS</p>
              </li>
            </NavLink>
          </Tooltip>
          {(isPurchaseAssistant || isPurchaseManager || isAdmin || isSalesManager) && (
            <Tooltip title="Contracts" placement="right">
              <NavLink to="/contracts" isActive={isActive('/contract')} onClick={() => this.setCollapse()}>
                <li className="contracts menu-hover">
                  <p className="menu-label">CONTRACTS</p>
                </li>
              </NavLink>
            </Tooltip>)}
          {(isPurchaseAssistant || isPurchaseManager || isAdmin || isSalesManager)
            && <Tooltip title="GPO" placement="right">
              <NavLink to="/gpos" onClick={() => this.setCollapse()}>
                <li className="gpos menu-hover">
                  <p className="menu-label">GPO</p>
                </li>
              </NavLink>
            </Tooltip>}
          {(isAdmin || isSalesManager) && (
            <Tooltip title="Settings" placement="right">
              <NavLink to="/settings" onClick={() => this.setCollapse()}>
                <li className="settings menu-hover">
                  <p className="menu-label">SETTINGS</p>
                </li>
              </NavLink>
            </Tooltip>
          )}
        </ul>
      </nav>
    )
  }
}

export default roleManagement(Menu)
