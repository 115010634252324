import { prop, propOr } from 'ramda'
import axios from '../Essentials/axios'

const ADD_MASTER_ITEM_DATA = 'ADD_MASTER_ITEM_DATA'
const CLEAR_SPA_UPDATE = 'CLEAR_SPA_UPDATE'

const fetchItemData = (masterId, sources) => axios.post('/spaitems', { masterId, sources })


export const getMasterItemData = (masterId, account) => {
    const sources = prop('contractSources')(account)

    return fetchItemData(masterId, sources).then(({ data }) => {
        const itemData = propOr([], 'itemData')(data)

        return {
            type: ADD_MASTER_ITEM_DATA,
            payload: itemData,
        }
    })
        .catch((e) => console.log(e))
}


export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case ADD_MASTER_ITEM_DATA:
            return action.payload
        case CLEAR_SPA_UPDATE:
            return []
        default:
            return init
    }
}
