import React, { Component } from 'react'
import queryString from 'query-string'
import {
    propEq, find, equals, prop, anyPass, propOr,
    complement, isNil, isEmpty, compose, pathOr, toUpper,
    curry, map, pick, values, and, reduce, lte, sortBy,
    filter, not, dissoc, addIndex, head, contains,
    reject, mergeAll, mapObjIndexed
} from 'ramda'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import InactiveItemWarning from '../Reusable/InactiveItemWarning'
import { dateString, greatestNth, lowestNth, unpick } from '../utilities/util'
import '../../node_modules/react-datepicker/dist/react-datepicker.css'

const mapIndexed = addIndex(map)

const greatestHundredth = greatestNth(100)
const lowestInt = lowestNth(1)

const computeMargin = (cost, sellPrice) => lowestInt((1 - cost / sellPrice) * 100)
const computeDefaultPrice = (item, agreements) => compose(greatestHundredth, prop('sellPrice'), find(propEq('item', item._id)))(agreements)
const getBestCost = (itemExpDate, itemContracts) => {
    const filterEligible = filter(prop('isEligible'))
    /* Ramda's lte seems to behave nicely when comparing two moment dates */
    const afterEndDate = compose(lte(itemExpDate), moment, prop('endDate'))
    const filterEndDates = filter(anyPass([afterEndDate, prop('isPurchasePrice')]))
    const sortByPrice = sortBy(prop('price'))
    const findCost = compose(prop('price'), head, sortByPrice, filterEndDates, filterEligible)

    return findCost(itemContracts) || ''
}

const getItemDefaults = curry((spa, agreements, accItemMods, item) => {
    const { _id } = item
    const itemMods = find(propEq('item', _id))(accItemMods) || {}
    const showContracts = pathOr(false, ['showContracts'])(itemMods)
    const exclude = pathOr(false, ['exclude'])(itemMods) || pathOr(false, ['isInactive'])(item)
    const modPrice = prop('sellPrice')(itemMods)
    const sellPrice = isNil(modPrice) ? computeDefaultPrice(item, agreements) : modPrice
    const expDate = prop('expDate')(itemMods) || prop('endDate')(spa)

    const contracts = pathOr([], ['contracts'])(item)
    const cost = getBestCost(expDate, contracts)

    return {
        item: _id,
        expDate,
        cost,
        sellPrice,
        showContracts,
        exclude,
    }
})

const renderContract = curry((item, cost, contract, index) => {
    const rawEndDate = pathOr('', ['endDate'])(contract)
    const endDate = isEmpty(rawEndDate) ? '' : dateString(new Date(rawEndDate))
    const manuName = pathOr('', ['manuName'])(item)
    const conPrice = pathOr('', ['price'])(contract)
    const formattedConPrice = isEmpty(conPrice) ? `$${conPrice}` : `$${conPrice.toFixed(2)}`
    const contractIdentifier = pathOr('', ['contractEntity'])(contract)
    const isPurchasePrice = pathOr(false, ['isPurchasePrice'])(contract)
    const type = isPurchasePrice ? 'Purchase Price' : compose(toUpper, pathOr('', ['type']))(contract)
    const isEligible = pathOr(false, ['isEligible'])(contract)
    return (
        <li key={index} className={equals(cost, conPrice) && isEligible ? 'selected' : ''}>
            <div className="col-2">{contractIdentifier}</div>
            <div className="col-4 alignLeft bolder">{manuName}</div>
            <div className={`col-1 bolder${isEligible ? ' eligible' : ''}`}>{formattedConPrice}</div>
            <div className="col-2">{type}</div>
            <div className="col-2">{endDate}</div>
            <div className="col-1" />
        </li>
    )
})

const renderContracts = compose(mapIndexed, renderContract)

const renderContractList = (item, cost) => {
    const { contracts } = item

    if (isEmpty(contracts)) return <div className="errorMsg" />

    else return renderContracts(item, cost)(contracts)
}

const renderContractsPerItem = (item, cost) => (
    <div className="showContracts col-12">
        <div className="headerBasicList">
            <div className="col-2">ID</div>
            <div className="col-4">Vendor</div>
            <div className="col-1">Price</div>
            <div className="col-2">Type</div>
            <div className="col-2">Expiration Date</div>
            <div className="col-1" />
        </div>
        <div className="basicList">
            <ul>
                {renderContractList(item, cost)}
            </ul>
        </div>
    </div>
)


class Stage2 extends Component {

    // eslint-disable-next-line react/state-in-constructor
    state = {
        isSubmitting: false
    }

    componentDidMount() {
        const { currentAccount, getMasterItemData, masterAgreements, redSpin } = this.props
        const params = queryString.parse(this.props.location.search)
        const { id: masterId } = params
        redSpin(getMasterItemData(masterId, currentAccount), 'enhanceditems')
    }

    render() {
        const {
            masterItemData, masterAgreements, spaUpdate, addToSPAUpdate,
            addAccItemMod, accItemMods, postAccountAgreement, history,
            updateSpaInputError, addToUpdateErrors, clearMessage, spin,
            isCloning, postMasterSPA, addToCreateSpaErrors
        } = this.props
        if (spin.enhanceditems) return <div />

        const { targetMargin, endDate } = spaUpdate

        const handlePriceChange = (oldMods, item, spa, val) => {
            const { _id } = item

            if (equals(val)(computeDefaultPrice(item, masterAgreements))) addAccItemMod({ ...oldMods, item: _id, sellPrice: undefined })

            else addAccItemMod({ ...oldMods, item: _id, sellPrice: val })
        }

        const handleDateChange = (oldMods, item, spa, date) => {
            const { _id } = item

            if (equals(date)(spa.endDate)) addAccItemMod({ ...oldMods, item: _id, expDate: undefined })

            else addAccItemMod({ ...oldMods, item: _id, expDate: date })
        }


        const getPriceAgreementDetails = pick(['item', 'sellPrice', 'expDate', 'exclude'])
        const getPriceAgreement = compose(getPriceAgreementDetails, getItemDefaults(spaUpdate, masterAgreements, accItemMods))
        const getPriceAgreements = map(getPriceAgreement)
        const isIncluded = compose(not, prop('exclude'))
        const filterExcludes = filter(isIncluded)
        const emptyAgreements = compose(isEmpty, filterExcludes, getPriceAgreements)(masterItemData)

        const handleAgreementClone = async () => {

            this.setState({ isSubmitting: true })

            const { targetMargin, endDate, accountId, entityId } = spaUpdate
            const allPriceAgreements = getPriceAgreements(masterItemData)
            const priceAgreements = compose(map(dissoc('exclude')), filterExcludes)(allPriceAgreements)

            const agreementToCreate = {
                entityId,
                accountId, //accountId: account._id,
                targetMargin,
                endDate,
                priceAgreements,
            }

            const ignoreErrors = unpick(['entityId'])

            const checkSellPrice = (agreement) => {
                const price = prop('sellPrice')(agreement)
                if (anyPass([equals(0), isEmpty, isNil, isNaN])(price)) return prop('item')(agreement)
                else return false
            }

            const checkSellPrices = map(checkSellPrice)
            const filterFalse = reject(equals(false))
            const priceErrors = compose(filterFalse, checkSellPrices)(priceAgreements)

            const valuesOnAgreement = values(ignoreErrors(agreementToCreate))
            const mapToBools = map(complement(isEmpty))
            const listOfBools = [...mapToBools(valuesOnAgreement), isEmpty(priceErrors)]

            const checkErrorOnSpa = (val, key) => {
                if (key == 'priceAgreements') {
                    if (isEmpty(val)) return { [key]: true }
                    else return { sellPriceErrors: priceErrors }
                }
                if (isEmpty(val)) return { [key]: true }

                else return { [key]: false }
            }

            const getErrorObject = compose(mergeAll, values, mapObjIndexed(checkErrorOnSpa))

            const allGood = reduce(and)(true)

            if (allGood(listOfBools)) {
                await postMasterSPA(agreementToCreate, history)
                this.setState({ isSubmitting: false }) ////This is not really needed because if successful 
                ////it is redirected in which case the component unmounts and the state is reset anyway
            }

            else {
                compose(addToCreateSpaErrors, getErrorObject, ignoreErrors)(agreementToCreate)
                this.setState({ isSubmitting: false })
                return getErrorObject(agreementToCreate)
            }
        }

        const handleAccountAgreeSubmit = async () => {
            this.setState({ isSubmitting: true })
            const { name, accountId, targetMargin, _id, } = spaUpdate

            const allPriceAgreements = getPriceAgreements(masterItemData)

            const priceAgreements = compose(map(dissoc('exclude')), filterExcludes)(allPriceAgreements)

            const agreementToCreate = {
                accountId,
                masterId: _id,
                targetMargin,
                priceAgreements,
            }

            const checkSellPrice = (agreement) => {
                const price = prop('sellPrice')(agreement)
                if (anyPass([equals(0), isEmpty, isNil])(price)) return prop('item')(agreement)
                else return false
            }


            const checkSellPrices = map(checkSellPrice)
            const filterFalse = reject(equals(false))
            const priceErrors = compose(filterFalse, checkSellPrices)(priceAgreements)

            const checkErrorOnSpa = (val, key) => {
                if (key == 'priceAgreements') {
                    if (isEmpty(val)) return { emptyItems: true }
                    else return { sellPriceErrors: priceErrors }
                }
                if (isEmpty(val)) return { [key]: true }
                else return { [key]: false }
            }

            const getErrorObject = compose(mergeAll, values, mapObjIndexed(checkErrorOnSpa))

            const mapToBools = map(complement(isEmpty))
            const valuesOnAgreement = values(agreementToCreate)
            const listOfBools = [...mapToBools(valuesOnAgreement), isEmpty(priceErrors)]
            const allGood = reduce(and)(true)

            if (allGood(listOfBools)) {
                await postAccountAgreement(agreementToCreate, history)
                this.setState({ isSubmitting: false })
            }

            else {
                compose(addToUpdateErrors, getErrorObject)(agreementToCreate)
                this.setState({ isSubmitting: false })
            }
        }

        const renderItem = curry((spaErrors, item, index) => {
            const {
                name, contracts, _id, externalId, isInactive = false
            } = item
            const itemMods = find(propEq('item', _id))(accItemMods) || {}
            const itemDefaults = getItemDefaults(spaUpdate, masterAgreements, accItemMods, item)
            const mainAgreementPrice = compose(prop('sellPrice'), find(propEq('item', _id)))(masterAgreements)

            const priceErrors = propOr([], 'sellPriceErrors')(spaErrors)
            const error = contains(_id, priceErrors)
            const updatedPriceErrors = reject(equals(_id))(priceErrors)

            const { cost, sellPrice, expDate: itemExpDate, showContracts, exclude } = itemDefaults

            const formattedCost = isEmpty(cost) ? `$${cost}` : `$${cost.toFixed(2)}`

            const currentMargin = computeMargin(cost, sellPrice)

            return (
                <div key={index}>
                    <li
                        key={index}
                        className={`${exclude ? 'excluded ' : ''}${(currentMargin < targetMargin) ? 'under' : 'over'}TargetMargin`}
                    >
                        <div className={`col-2 icon${!equals(sellPrice, mainAgreementPrice) || !moment(endDate).isSame(itemExpDate) ? ' modified' : ''}`}>{externalId}</div>
                        <div className="col-3 alignLeft bolder divider">
                            {name}
                            {isInactive && <InactiveItemWarning />}
                        </div>
                        <div className="col-1 targetMargin">{currentMargin}&#37;</div>
                        <div className="col-1">{formattedCost}</div>
                        <div className={`col-2 price${error ? ' error' : ''}`}>
                            <input
                                type="number"
                                className="alignRight"
                                name="sellprice"
                                value={equals(0)(sellPrice) ? '' : sellPrice}
                                placeholder="Insert cost"
                                onFocus={() => addToUpdateErrors({ sellPriceErrors: updatedPriceErrors })}
                                onChange={(e) => handlePriceChange(itemMods, item, spaUpdate, Number(e.target.value))}
                            />
                        </div>
                        <div className="col-2 relativeP ofInit divider">
                            <DatePicker
                                selected={itemExpDate}
                                onChange={(date) => handleDateChange(itemMods, item, spaUpdate, date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>
                        <div className="col-1 button">
                            <button
                                className="btnList"
                                onClick={() => addAccItemMod({ ...itemMods, item: _id, showContracts: !showContracts })}
                            />
                            <button
                                className={`btnRemove${exclude ? ' include' : ' exclude'}`}
                                onClick={() => addAccItemMod({ ...itemMods, item: _id, exclude: !exclude })}
                            />
                        </div>
                    </li>
                    {showContracts && contracts && renderContractsPerItem(item, cost)}
                </div>
            )
        })

        const renderItemList = compose(mapIndexed, renderItem)

        return (
            <div className="col-12">
                {emptyAgreements
                    && <div className="alertMsg">
                        <span>Please specify at least one item.</span>
                    </div>}
                <div>
                    <div className="headerBasicList">
                        <div className="col-2">Item ID</div>
                        <div className="col-3">Item Name</div>
                        <div className="col-1">Margin</div>
                        <div className="col-1">Cost</div>
                        <div className="col-2">Sell Price</div>
                        <div className="col-2">Expiration Date</div>
                        <div className="col-1" />
                    </div>
                    <div className="basicList unLinked mt8">
                        <ul>
                            {renderItemList(updateSpaInputError)(masterItemData)}
                        </ul>
                    </div>
                </div>
                <div className="alignCenter buttons">
                    <button className="moreBtn" onClick={() => addToSPAUpdate({ stage: spaUpdate.stage - 1 })}>Previous</button>
                    <button
                        disabled={this.state.isSubmitting}
                        className="moreBtn aim"
                        onClick={!isCloning ? handleAccountAgreeSubmit : handleAgreementClone}

                    // onClick={() => {
                    //     if (!isCloning)
                    //         return handleAccountAgreeSubmit()

                    //     const errored = handleAgreementClone()
                    //     if (errored && !isEmpty(errored.sellPriceErrors)) {
                    //         // The top bar is fixed at top of page, so in order to prevent it from covering the div we want to scroll to,
                    //         // we must add an offset to compensate:
                    //         const element = refs[head(errored.sellPriceErrors)].current;
                    //         const offset = 87;
                    //         const bodyRect = document.body.getBoundingClientRect().top;
                    //         const elementRect = element.getBoundingClientRect().top;
                    //         const elementPosition = elementRect - bodyRect;
                    //         const offsetPosition = elementPosition - offset;

                    //         window.scrollTo({
                    //             top: offsetPosition,
                    //             behavior: 'smooth',
                    //         });
                    //     }
                    // }}


                    >
                        Submit
                    </button>
                </div>
            </div >
        )
    }
}

export default Stage2
