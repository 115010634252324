import React, { Component } from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import { pick, compose, filter, isEmpty, isNil } from 'ramda'
import { mapDispatch, mapSpinToDispatch, redSearchHandlerInit } from '../utilities/redux-util'
import roleManagement from '../HOC/RoleManagement'
import { downloadSpaCSV } from '../actions'

class DownloadSPA extends Component {
  state = {
    isLoading: false,
    earliestDate: null,
    latestDate: null,
    status: '',
    customerName: '',
    masterAgreementNumber: '',
    subAgreementNumber: '',
    manufacturerName: '',
  }


  downloadCSV = (data) => {
    const objectURL = new Blob([data])
    const url = window.URL.createObjectURL(objectURL, { type: 'text/csv;charset=utf-8;' })
    const d = new Date()
    const fileName = `Agreements-${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}-key${d.getTime()}.csv`
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(objectURL)
  }

  handleFieldChange = (fieldName, event) => this.setState({ [fieldName]: event.target.value })

  handleDateChange = (fieldName, date) => {
    if (isNil(date)) this.setState({ [fieldName]: '' })
    else this.setState({ [fieldName]: date })
  }

  handleDownloadCSV = async () => {
    this.setState({ isLoading: true })

    const filters = compose(
      filter(elem => elem),
      pick(['earliestDate', 'latestDate', 'status', 'customerName', 'masterAgreementNumber', 'subAgreementNumber', 'manufacturerName'])
    )(this.state)

    const { data, error } = await downloadSpaCSV({ filters })
    if (error) {
      console.log('error', error)
      return this.setState({ isLoading: false })
    }
    this.downloadCSV(data)
    this.setState({ isLoading: false })
  }

  renderContent = () => {
    const { isLoading, subAgreementNumber, masterAgreementNumber, earliestDate, latestDate, customerName, manufacturerName } = this.state
    if (isLoading)
      return <div className="loader" />

    return (
      <>
        <div className="col-12">
          <div className="basicPanel">
            <div>
              <p>Master Agreement Name</p>
              <input
                name="masterAgreementNumber"
                type="text"
                placeholder="Master Agreement Name"
                value={masterAgreementNumber}
                onChange={(e) => this.handleFieldChange('masterAgreementNumber', e)}
              />
            </div>
            <div>
              <p>Agreement Name</p>
              <input
                name="subAgreementNumber"
                type="text"
                placeholder="Agreement Name"
                value={subAgreementNumber}
                onChange={(e) => this.handleFieldChange('subAgreementNumber', e)}
              />
            </div>
            <div>
              <p>Customer Name</p>
              <input
                name="customerName"
                type="text"
                placeholder="Customer Name"
                value={customerName}
                onChange={(e) => this.handleFieldChange('customerName', e)}
              />
            </div>
            <div>
              <p>Manufacturer</p>
              <input
                name="manufacturerName"
                type="text"
                placeholder="Manufacturer Name"
                value={manufacturerName}
                onChange={(e) => this.handleFieldChange('manufacturerName', e)}
              />
            </div>
            <div>
              <p>Earliest Expiration Date</p>
              <div className="relativeP">
                <DatePicker
                  selected={(isEmpty(earliestDate)) ? null : earliestDate}
                  onChange={(e) => this.handleDateChange('earliestDate', e)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
            <div>
              <p>Latest Expiration Date</p>
              <div className="relativeP">
                <DatePicker
                  selected={(isEmpty(latestDate)) ? null : latestDate}
                  onChange={(e) => this.handleDateChange('latestDate', e)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
          </div>
          <div className="alignCenter">
            <button className="moreBtn aim" onClick={this.handleDownloadCSV}            >
              Download CSV
            </button>
          </div>
        </div>
      </>
    )
  }

  render() {
    const { isAdmin, isPurchaseAssistant, isSalesManager, isInsideSales, isSalesRep } = this.props
    console.log('this.state', this.state)
    if (isAdmin || isPurchaseAssistant || isSalesManager || isSalesRep || isInsideSales) {
      return <>
        <div className="contentDetail">
          <div className="headerDetail">
            <div className="titleDetail Download SPA">
              <div className="infoIcon" />
              <h2>Download Agreements</h2>
            </div>
          </div>
          {this.renderContent()}
        </div>
      </>
    }
  }
}

const mapStateToProps = (state) => ({
  // spin: state.spin,
})

const md2p = mapDispatch({}, {}, (dispatch) => ({
  // redSpin: mapSpinToDispatch(dispatch),
  // redSearchHandlerInit: redSearchHandlerInit(dispatch),
}))

export default connect(mapStateToProps, md2p)(roleManagement(DownloadSPA))