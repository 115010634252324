import React, { Component } from 'react'

class PadInput extends Component {
    render() {
        const {
 pad, addToContractSubmit, error, addToContractErrors,
} = this.props

        return (

            <div className={`${error ? ' error' : ''}`}>
                <p>Pad Percentage</p>
                <input
type="number"
name="pad"
                    value={pad || ''}
                    placeholder="Pad Percentage"
                    onFocus={() => addToContractErrors({ pad: false })}
                    onChange={(event) => addToContractSubmit({ pad: event.target.value })} />
            </div>
        )
    }
}

export default PadInput
