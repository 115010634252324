import React, { Component } from 'react'
import { isEmpty, compose, head } from 'ramda'

class Search extends Component {
    constructor(props) {
        super(props)
        this.state = { search: '' }
    }

    searchHandlerInit = () => {
        const { selectFirstPage, searchModels } = this.props
        const handleSearch = (search) => searchModels('/accounts', 1, search)
        return this.props.redSearchHandlerInit(handleSearch, '/accountssearch')
    }

    searchHandler = this.searchHandlerInit()

    render() {
        const {
            handleSubmit, clearModelsToView, searchModels, addAccountToContract,
            accountSearchValues, addToContractErrors, spin, visibleAccounts,
        } = this.props


        const handleKeyPress = (e) => {
            if ((e.key === 'Enter') && isEmpty(visibleAccounts)) return e.preventDefault()
            if (e.key === 'Enter') {
                compose(addAccountToContract, head)(visibleAccounts)
                clearModelsToView('accounts')
                e.preventDefault()
            }
            if (e.key === 'ArrowDown') {
                this.searchHandler(this.state)
                e.preventDefault()
            }
        }

        return (
            <div className={`commonSearch${spin['/accountssearch'] ? ' loading' : ''}`}>
                <form
                    onKeyDown={handleKeyPress}
                    onBlur={() => clearModelsToView('accounts')}
                    onFocus={() => {
                        addToContractErrors({ accounts: false })
                        this.searchHandler(this.state)
                    }}
                >
                    <input
name="search"
type="text"
placeholder="Search By Name or ID"
autoComplete="off"
value={this.state.search}
                        onChange={(e) => {
                            this.searchHandler({ search: e.target.value })
                            this.setState({ search: e.target.value })
                        }}
                    />
                </form>
            </div>
        )
    }
}

export default Search
/*
import React, { Component } from 'react'


import { map, flatMap, filter, switchMap, debounceTime } from 'rxjs/operators'
import queryString from 'query-string'
import { curry, isEmpty, converge, concat, toUpper, head, tail, compose, replace, pathOr, prop } from 'ramda'


class Search extends Component {

    searchHandlerInit = () => {
        const { selectFirstPage, searchModels} = this.props
        const { pathname } = this.props.location

        const handleSearch = (search) => {
            selectFirstPage()
            return searchModels(pathname, 1, search)
        }
        return this.props.redSearchHandlerInit(handleSearch, pathname + 'search')
    }

    searchHandler = this.searchHandlerInit()

    render() {

        const { searchTerms,  history } = this.props

        const { pathname } = this.props.location

        const changeRoute = (currentPath) => {
            if (pathname === '/manus') return '/manufacturer'
            else return replace(/s/, '', currentPath)
        }

        const loseSlash = replace(/\//, '')
        const capitalizeFirst = converge(concat, [compose(toUpper, head), tail])
        const enhancePathName = compose(concat('visible'), capitalizeFirst, loseSlash)
        const modelsToView = pathOr([], [enhancePathName(pathname)])(this.props)


        const handleEnterKey = (e) => {
            if ((e.key === 'Enter') && isEmpty(modelsToView)) return e.preventDefault()
            if (e.key === 'Enter') {
                const routeId = compose(prop('_id'), head)(modelsToView)
                history.push(`${changeRoute(pathname)}?id=${routeId}`)
                return e.preventDefault()
            }
        }

        return (
            <div className="commonSearch">
                <form
                    onKeyPress={handleEnterKey}
                    className={`${(searchTerms) ? ' inputted' : ''}`} >
                    <input name="search" type="text" placeholder="Search" autoComplete='off' onChange={e =>
                        this.searchHandler({ search: e.target.value })
                    } />
                </form>
            </div>

        )
    }
}

export default Search
*/
