import {
 remove, uniqBy, prop, map, sortBy, compose, propOr,
} from 'ramda'
import axios from '../Essentials/axios'

const ADD_ENHANCED_ITEMS = 'ADD_ENHANCED_ITEMS'
const CLEAR_SPA_UPDATE_ITEM = 'CLEAR_SPA_UPDATE_ITEM'
const REMOVE_SPA_ITEM = 'REMOVE_SPA_ITEM'


const getSPAItemData = (itemIds, sources) => axios.post('/spaitems', { itemIds, sources})

export const getEnhancedItems = (account, selectedItems) => {
    const itemIds = map(prop('_id'))(selectedItems)
    const sources = propOr([], 'contractSources')(account)

    return getSPAItemData(itemIds, sources).then(({ data }) => {
        const itemData = data || []

        return {
            type: ADD_ENHANCED_ITEMS,
            payload: itemData,
        }
    })
}

export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case ADD_ENHANCED_ITEMS:
            return compose(sortBy(prop('_id')), uniqBy(prop('_id')))([...action.payload, ...init])
        case REMOVE_SPA_ITEM:
            return remove(action.payload, 1, init)
        case CLEAR_SPA_UPDATE_ITEM:
            return []
        default:
            return init
    }
}
