import { CLEAR_CON_SUBMIT } from './contract_submit_reducer'
import { enhanceModelString } from '../utilities/util'

const SELECT_GPO = 'SELECT_GPO'
const SELECT_LVC = 'SELECT_LVC'
const SELECT_BASE = 'SELECT_BASE'
const SELECT_TIER = 'SELECT_TIER'
const MOUNT_EDIT = 'MOUNT_EDIT'

export const selectSourceType = (type) => ({ type: `SELECT_${enhanceModelString(type)}`, payload: type })

export const selectBaseGpo = (baseGpo) => ({ type: SELECT_BASE, payload: baseGpo })

export const selectTier = (tier) => ({ type: SELECT_TIER, payload: tier })

export default (state, action) => {
    const init = state || { type: 'gpo' }

    switch (action.type) {
        case SELECT_GPO:
            return { type: action.payload }
        case SELECT_LVC:
            return { type: action.payload }
        case SELECT_BASE:
            return { ...init, baseGpo: action.payload }
        case SELECT_TIER:
            return { ...init, _id: action.payload }
        case MOUNT_EDIT:
            return action.payload
        case CLEAR_CON_SUBMIT:
            return {}
        default:
            return init
    }
}
