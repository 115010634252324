import React, { Component, createRef } from 'react'
import DatePicker from 'react-datepicker'
import {
    propEq, find, equals, prop, anyPass, reject, sortBy,
    complement, isNil, isEmpty, compose, propOr, filter,
    curry, map, pick, values, and, reduce, contains, toUpper,
    addIndex, mergeAll, mapObjIndexed, pathOr, head,
} from 'ramda'
import moment from 'moment'
import {
    dateString, greatestNth, lowestNth, unpick, roundDecimal,
} from '../utilities/util'
import '../../node_modules/react-datepicker/dist/react-datepicker.css'

const mapIndexed = addIndex(map)
const greatestHundredth = greatestNth(100)
const lowestInt = lowestNth(1) // Math.floor is ugly

const computeDefaultPrice = (cost, spa) => greatestHundredth(cost / ((1 - spa.targetMargin / 100)))

const computeMargin = (cost, sellPrice) => lowestInt((1 - cost / sellPrice) * 100)

const getBestCost = (itemExpDate, itemContracts, notInitAdd) => {
    const filterEligible = filter(prop('isEligible'))
    /* Ramda's lte seems to behave nicely when comparing two moment dates */
    const afterEndDate = compose((endDate) => {
        // console.log('Today is less then or equal to endDate', moment() <= endDate)
        if (notInitAdd) return itemExpDate <= endDate

        return moment() <= endDate
    }, moment, prop('endDate'))
    // MAYER LENCH, Changed to give best contract date even if contract endDate >= today on inital load. Iv taken the liverty of commenting so that you can revert it easily because its a dumbass change
    // const afterEndDate = compose(lte(itemExpDate), moment, prop('endDate'))


    const filterEndDates = filter(anyPass([afterEndDate, prop('isPurchasePrice')]))
    const sortByPrice = sortBy(prop('price'))
    const findCurContract = compose(head, sortByPrice, filterEndDates, filterEligible)
    return findCurContract(itemContracts)
}

const getItemDefaults = curry((spa, mods, item) => {
    const showContracts = prop('showContracts')(find(propEq('_id', item._id))(mods))
    const rawExpDate = prop('expDate')(find(propEq('_id', item._id))(mods))
    const expDate = rawExpDate || prop('expDate')(spa)
    const contracts = pathOr([], ['contracts'])(item)
    const notInitAdd = prop('initAdd')(find(propEq('_id', item._id))(mods)) === false

    const bestContract = getBestCost(expDate, contracts, notInitAdd)
    const { price: cost, endDate } = bestContract

    const formatDate = (isEmpty(endDate) || notInitAdd) ? expDate : compose(moment, dateString, (d) => new Date(d))(endDate)
    const modPrice = prop('sellPrice')(find(propEq('_id', item._id))(mods))
    const purchasePrice = (find(propEq('isPurchasePrice', true))(contracts))
    const sellPrice = isNil(modPrice) ? computeDefaultPrice(pathOr(cost, ['price'])(purchasePrice), spa) : modPrice

    return {
        item: item._id,
        expDate: formatDate,
        cost,
        sellPrice,
        showContracts: showContracts || false,
    }
})

class Stage3 extends Component {
    componentDidMount() {
        const {
            getEnhancedItems, currentAccount, selectedSpaItems, spaItemData, clearModelsToView, redSpin,
        } = this.props
        clearModelsToView('items')
        redSpin(getEnhancedItems(currentAccount, selectedSpaItems), 'enhanceditems')
    }

    render() {
        const {
            spaItemData, spaCreate, addToCreateSpaErrors, createSpaErrors, spin,
            addToSPACreate, spaItemMods, addItemMod, removeItemFromSPA, postMasterSPA, history, clearMessage,
        } = this.props

        const refs = spaItemData.reduce((acc, value) => {
            acc[value._id] = createRef()
            return acc
        }, {})

        const { targetMargin, expDate } = spaCreate

        if (spin.enhanceditems) return <div />

        const selectStage = (val) => addToSPACreate({ stage: spaCreate.stage + val })

        const renderContractList = (item, cost) => {
            const { contracts } = item

            if (isEmpty(contracts)) return <div className="errorMsg" />

            const renderContract = (contract, index) => {
                const rawEndDate = pathOr('', ['endDate'])(contract)
                const endDate = isEmpty(rawEndDate) ? '' : dateString(new Date(rawEndDate))
                const manuName = pathOr('', ['manuName'])(item)
                const conPrice = pathOr('', ['price'])(contract)
                const formattedConPrice = isEmpty(conPrice) ? `$${conPrice}` : `$${conPrice.toFixed(2)}`
                const contractIdentifier = pathOr('', ['contractEntity'])(contract)
                const isPurchasePrice = pathOr(false, ['isPurchasePrice'])(contract)
                const type = isPurchasePrice ? 'Purchase Price' : compose(toUpper, pathOr('', ['type']))(contract)
                const isEligible = pathOr(false, ['isEligible'])(contract)

                return (
                    <li key={index} className={equals(cost, conPrice) && isEligible ? 'selected' : ''}>
                        <div className="col-2">{contractIdentifier}</div>
                        <div className="col-4 alignLeft bolder">{manuName}</div>
                        <div className={`col-1 bolder${isEligible ? ' eligible' : ''}`}>{formattedConPrice}</div>
                        <div className="col-2">{type}</div>
                        <div className="col-2">{endDate}</div>
                        <div className="col-1" />
                    </li>
                )
            }

            const renderContracts = mapIndexed(renderContract)

            return renderContracts(contracts)
        }

        const handlePriceChange = (oldMods, item, spa, val, cost) => {
            const { _id } = item

            if (equals(val)(computeDefaultPrice(cost, spa))) addItemMod({ ...oldMods, _id, sellPrice: undefined })

            else addItemMod({ ...oldMods, _id, sellPrice: val })
        }

        const handleDateChange = (oldMods, item, spa, date) => {
            const { _id } = item
            if (equals(date)(spa.expDate)) {
                addItemMod({
                    ...oldMods, _id, expDate: undefined, initAdd: false,
                })
            } else {
                addItemMod({
                    ...oldMods, _id, expDate: date, initAdd: false,
                })
            }
        }

        const renderItem = curry((refs, spaErrors, item, index) => {
            const { name, _id, externalId } = item

            const contracts = pathOr([], ['contracts'])(item)

            const priceErrors = propOr([], 'sellPriceErrors')(spaErrors)

            const error = contains(_id, priceErrors)
            const updatedPriceErrors = reject(equals(_id))(priceErrors)

            const itemMods = find(propEq('_id', _id))(spaItemMods) || {}
            const itemDefaults = getItemDefaults(spaCreate, spaItemMods, item)
            const {
                sellPrice: currentPrice, cost = 0, showContracts, expDate: itemExpDate,
            } = itemDefaults

            const formattedCost = isEmpty(cost) || isNil(cost) ? `$${0}` : `$${cost.toFixed(2)}`
            const originalPrice = computeDefaultPrice(cost, spaCreate)
            const currentMargin = computeMargin(cost, currentPrice)

            return (
                <div key={index} ref={refs[_id]}>
                    <li
                        key={index}
                        className={`${(currentMargin < targetMargin) ? 'under' : 'over'}TargetMargin`}
                    >
                        <div className={`col-2  icon${!equals(currentPrice, originalPrice) || !moment(expDate).isSame(itemExpDate) ? ' modified' : ''}`}>{externalId}</div>
                        <div className="col-3 alignLeft bolder divider">{name}</div>
                        <div className="col-1 targetMargin">{currentMargin}&#37;</div>
                        <div className="col-1">{formattedCost}</div>
                        <div className={`col-2 price${error ? ' error' : ''}`}>
                            <input
                                type="number"
                                className="alignRight"
                                name="sellprice"
                                value={equals(0)(currentPrice) ? '' : currentPrice}
                                placeholder="Insert price"
                                onFocus={() => addToCreateSpaErrors({ sellPriceErrors: updatedPriceErrors })}
                                onChange={(e) => handlePriceChange(itemMods, item, spaCreate, roundDecimal(e.target.value), cost)}
                            />
                            {error && <p className="enterPriceError">Please enter price</p>}
                        </div>
                        <div className="col-2 relativeP ofInit divider">
                            <DatePicker
                                selected={itemExpDate}
                                onChange={(date) => handleDateChange(itemMods, item, spaCreate, date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>

                        <div className="col-1">
                            <button
                                className="btnList"
                                onClick={() => addItemMod({ ...itemMods, _id, showContracts: !showContracts })} />
                            <button className="btnRemove" onClick={() => removeItemFromSPA(index)} />
                        </div>
                    </li>
                    {showContracts && contracts
                        && (
                            <div className="showContracts col-12">
                                <div className="headerBasicList">
                                    <div className="col-2">ID</div>
                                    <div className="col-4">Vendor</div>
                                    <div className="col-1">Price</div>
                                    <div className="col-2">Type</div>
                                    <div className="col-2">Expiration Date</div>
                                    <div className="col-1" />
                                </div>
                                <div className="basicList">
                                    <ul>
                                        {renderContractList(item, cost)}
                                    </ul>
                                </div>
                            </div>
                        )}
                </div>
            )
        })

        const renderItemList = compose(mapIndexed, renderItem(refs))

        const getPriceAgreementDetails = pick(['item', 'sellPrice', 'expDate'])
        const getPriceAgreement = compose(getPriceAgreementDetails, getItemDefaults(spaCreate, spaItemMods))
        const getPriceAgreements = map(getPriceAgreement)
        const emptyAgreements = compose(isEmpty, getPriceAgreements)(spaItemData)

        const handleSPASubmit = () => {
            const {
                entityId, expDate, account, targetMargin,
            } = spaCreate

            const priceAgreements = getPriceAgreements(spaItemData)

            const agreementToCreate = {
                entityId,
                accountId: account._id,
                targetMargin,
                endDate: expDate,
                priceAgreements,
            }

            const ignoreErrors = unpick(['entityId'])

            const checkSellPrice = (agreement) => {
                const price = prop('sellPrice')(agreement)
                if (anyPass([equals(0), isEmpty, isNil, isNaN])(price)) return prop('item')(agreement)
                else return false
            }

            const checkSellPrices = map(checkSellPrice)
            const filterFalse = reject(equals(false))
            const priceErrors = compose(filterFalse, checkSellPrices)(priceAgreements)

            const valuesOnAgreement = values(ignoreErrors(agreementToCreate))
            const mapToBools = map(complement(isEmpty))
            const listOfBools = [...mapToBools(valuesOnAgreement), isEmpty(priceErrors)]

            const checkErrorOnSpa = (val, key) => {
                if (key == 'priceAgreements') {
                    if (isEmpty(val)) return { [key]: true }
                    else return { sellPriceErrors: priceErrors }
                }
                if (isEmpty(val)) return { [key]: true }

                else return { [key]: false }
            }

            const getErrorObject = compose(mergeAll, values, mapObjIndexed(checkErrorOnSpa))

            const allGood = reduce(and)(true)

            if (allGood(listOfBools)) postMasterSPA(agreementToCreate, history)

            else {
                compose(addToCreateSpaErrors, getErrorObject, ignoreErrors)(agreementToCreate)
                return getErrorObject(agreementToCreate)
            }
        }

        return (
            <div className="col-12">
                <div>
                    {emptyAgreements && <div className="alertMsg">
                        <span>Please specify at least one item.</span>
                    </div>}
                    <div className="headerBasicList">
                        <div className="col-2">ID</div>
                        <div className="col-3">Name</div>
                        <div className="col-1">Margin</div>
                        <div className="col-1">Cost</div>
                        <div className="col-2">Sell Price</div>
                        <div className="col-2">Expiration Date</div>
                        <div className="col-1" />
                    </div>
                    <div className="basicList mt8 unLinked">
                        <ul>
                            {renderItemList(createSpaErrors)(spaItemData)}
                        </ul>
                    </div>
                </div>
                <div className="alignCenter buttons">
                    <button className="moreBtn" onClick={() => selectStage(-1)}>Previous</button>
                    <button
                        className="moreBtn aim"
                        onClick={() => {
                            const errored = handleSPASubmit()
                            if (errored && !isEmpty(errored.sellPriceErrors)) {
                                // The top bar is fixed at top of page, so in order to prevent it from covering the div we want to scroll to,
                                // we must add an offset to compensate:
                                const element = refs[head(errored.sellPriceErrors)].current;
                                const offset = 87;
                                const bodyRect = document.body.getBoundingClientRect().top;
                                const elementRect = element.getBoundingClientRect().top;
                                const elementPosition = elementRect - bodyRect;
                                const offsetPosition = elementPosition - offset;

                                window.scrollTo({
                                    top: offsetPosition,
                                    behavior: 'smooth',
                                });
                            }
                        }}>Submit
                    </button>
                </div>
            </div>
        )
    }
}

export default Stage3
