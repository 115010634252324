import React, { Component } from 'react'
import {
    isEmpty, find, propEq, filter, compose, propOr,
    prop, reject, contains, equals, map, addIndex,
    pathOr, head, anyPass, gt, lte,
} from 'ramda'
import ItemSearch from './ItemSearch'
import ItemChoices from './ItemChoices'

import { lowestNth, roundDecimal } from '../utilities/util'

const outOfRange = anyPass([gt(0), lte(85)])
const lowestInt = lowestNth(1)
const mapIndexed = addIndex(map)

class InputtedItems extends Component {

    componentDidMount() {
        pathOr([], ['contractItems'], this.props).map((item, index) => {
            const uoms = pathOr([], ['unitsTypeId', 'uom'])(item)
            const selectedUom = item.selectedUom ? item.selectedUom : find(propEq('internalId', prop('uom', item)), uoms) ? find(propEq('internalId', prop('uom', item)), uoms).internalId : ''
            if (!item.selectedUom)
                this.props.changeContractPrice(index, 'selectedUom', selectedUom)
        })
    }

    render() {
        const {
            contractItems, unknownItems, currentManu, contractErrors,
            addToContractErrors, removeItemFromContract, changeContractPrice,
        } = this.props

        const currentVendorId = prop('_id')(currentManu)
        const filterForVendor = filter(propEq('vendorId', currentVendorId))
        const getWAC = compose(prop('cost'), head, filterForVendor, pathOr([], ['vendors']))

        const contractPriceErrors = propOr([], 'contractPriceErrors')(contractErrors)

        // Create a select button from a list of uoms, selects purchase unit by default default
        const uomSelector = (index, uoms, selected) => <div className="selector">
                <select
                    className="gray"
                    value={selected}
                    onChange={(e) => changeContractPrice(index, 'selectedUom', e.target.value)}
                >
                    {uoms.map((uom) => <option key={uom.internalId} value={uom.internalId}>
                            {uom.unitName}
                                       </option>)}
                </select>
                                                       </div>

        const renderContractItem = (item, index) => {
            const { _id } = item
            const contractPrice = prop('contractPrice')(item) || ''

            const wac = getWAC(item)

            const uoms = pathOr([], ['unitsTypeId', 'uom'])(item)
            const selectedUom = item.selectedUom ? item.selectedUom : find(propEq('internalId', prop('uom', item)), uoms) ? find(propEq('internalId', prop('uom', item)), uoms).internalId : ''
            const purchUnit = find(propEq('internalId', prop('purchaseUnit', item)), uoms) || {}
            const error = contains(_id, contractPriceErrors)
            const purchRate = pathOr(1, ['value'], purchUnit)
            const selectedRate = pathOr(1, ['value'], selectedUom)
            const updatedPriceErrors = reject(equals(_id))(contractPriceErrors)
            const discount = contractPrice ? lowestInt(100 * (1 - contractPrice * purchRate / (wac * selectedRate))) : ''

            return (
                <li key={index} className={`relativeP${outOfRange(discount) ? ' overDiscount' : ''}`}>
                    <div className="col-2">{pathOr('', ['externalId'])(item)}</div>
                    <div className="col-3 alignLeft bolder">{pathOr('', ['name'])(item)}</div>
                    <div className="col-1">{pathOr('', ['mpn'])(item)}</div>
                    <div className={`col-2 price${error ? ' error' : ''}`}>
                        <input
                            type="number"
                            name="cost"
                            value={contractPrice}
                            step="0.1"
                            placeholder="0"
                            onChange={(e) => changeContractPrice(index, 'contractPrice', e.target.value ? roundDecimal(e.target.value) : '')}
                            onFocus={() => addToContractErrors({ contractPriceErrors: updatedPriceErrors })} />
                    </div>
                    <div className="col-2">{uomSelector(index, uoms, selectedUom)}</div>
                    <div className="col-1">{discount}&#37;</div>
                    <div className="col-1">
                        <button
                            className="btnRemove"
onClick={() => removeItemFromContract('KNOWN', index)} />
                    </div>
                </li>
            )
        }

        const renderContractItems = mapIndexed(renderContractItem)

        const renderUnknownItem = (item, index) => {
            const contractPrice = propOr(0, 'contractPrice')(item)
            const vendorCode = propOr('', 'vendorCode')(item)
            return (
                <li key={index}>
                    <div className="col-1" />
                    <div className="col-8 alignLeft">{vendorCode}</div>
                    <div className="col-2">&#36;{contractPrice}
                    </div>
                    <div className="col-1" />
                </li>
            )
        }

        const renderUnknownItems = mapIndexed(renderUnknownItem)

        return (
            <div>
                <div className="relativeP">
                    <ItemSearch {...this.props} />
                    <ItemChoices {...this.props} />
                </div>
                <div className="headerBasicList withIcon">
                    <div className="col-2">ID</div>
                    <div className="col-3">Name</div>
                    <div className="col-1">MPN</div>
                    <div className="col-2">Cost</div>
                    <div className="col-2">Purchase Unit</div>
                    <div className="col-1">Discount</div>
                    <div className="col-1" />
                </div>
                <div className="basicList unLinked relativeP">
                    <ul>
                        {(contractItems) && renderContractItems(contractItems)}
                    </ul>
                    {(!isEmpty(unknownItems)) && <div className="relativeP unknown">
                        <div className="alignCenter">
                            <h3>Unknown Items</h3>
                        </div>
                        <div className="headerBasicList">
                            <div className="col-9">Vendor Code</div>
                            <div className="col-2">Cost</div>
                        </div>
                        <div className="basicList unLinked">
                            <ul>
                                {renderUnknownItems(unknownItems)}
                            </ul>
                        </div>
                                                 </div>}
                </div>
                {!isEmpty(currentManu) && isEmpty(contractItems) && <div className="alertNoItem errorMsg" />}
            </div>
        )
    }
}

export default InputtedItems
