import {
    propEq, prop, reject, map, objOf, compose, head,
    pathOr, pick, converge, merge, propOr, last, eqProps,
    mergeAll, filter, xprod, differenceWith, assoc, dissoc,
} from 'ramda'
import axios from '../Essentials/axios'

const INITIAL_EDIT = 'INITIAL_EDIT'
const ADD_EDIT_MODS = 'ADD_EDIT_MODS'
const CLEAR_AGREE_EDITS = 'CLEAR_AGREE_EDITS'
const CLEAR_SPA_PAGE = 'CLEAR_SPA_PAGE'
const ADD_EDIT_ERRORS = 'ADD_EDIT_ERRORS'
const CHANGE_STATUS_OPTIONS = 'CHANGE_STATUS_OPTIONS'
const UPDATE_EDIT_LIST = 'UPDATE_EDIT_LIST'

const fetchItemData = (masterId, sources) => axios.post('/spaitems', { masterId, sources, })

export const mountAgreementToEdit = (accountAgreement) => (dispatch) => {
    const masterId = pathOr('', ['masterId', '_id'])(accountAgreement)
    const stripItemAgreements = compose(map(dissoc('_id')), propOr([], 'priceAgreements'))
    const itemAgreements = stripItemAgreements(accountAgreement)
    const status = pathOr('pending', ['status'])(accountAgreement)
    const sources = pathOr([], ['accountId', 'contractSources'])(accountAgreement)

    fetchItemData(masterId, sources)
        .then(({ data }) => {
            const checkPair = converge(eqProps('item'), [head, last])
            const innerJoinByItem = compose(map(mergeAll), filter(checkPair), xprod)

            const makeItemIdObj = compose(objOf('item'), prop('_id'))
            const stripDataPerItem = converge(merge, [pick(['contracts', 'externalId', 'name', 'manuName']), makeItemIdObj])
            const stripItemData = compose(map(stripDataPerItem), propOr([], 'itemData'))

            const stripDataPerAgree = pick(['expDate', 'item', 'sellPrice'])
            const stripAgreeData = compose(map(stripDataPerAgree), propOr([], 'masterAgreements'))

            const masterData = stripAgreeData(data)
            const itemData = stripItemData(data)

            const baseSPAItems = innerJoinByItem(masterData, itemData)
            const baseAccountItems = innerJoinByItem(itemAgreements, itemData)

            const removeIncluded = differenceWith(eqProps('item'))
            const addExcludedFlags = map(assoc('exclude', true))

            const excludedItems = addExcludedFlags(removeIncluded(baseSPAItems, baseAccountItems))

            dispatch({
                type: INITIAL_EDIT,
                payload: {
                    editsList: [...baseAccountItems, ...excludedItems],
                    initialBase: excludedItems,
                    initialAccount: baseAccountItems,
                    errors: {
                        emptyItems: false,
                        sellPriceErrors: [],
                    },
                    statusToggle: false,
                    status,
                },
            })
        })
        .catch((e) => console.log(e))
}

export const addEditMods = (modObj) => (dispatch) => {
    dispatch({
        type: ADD_EDIT_MODS,
        payload: modObj,
    })
}

export const updateEditList = (editList) => (dispatch) => {
    dispatch({
        type: UPDATE_EDIT_LIST,
        payload: editList,
    })
}

export const clearAgreementEdits = () => ({ type: CLEAR_AGREE_EDITS })

export default (state, action) => {
    const init = state || {
        editsList: [],
        initialBase: [],
        initialAccount: [],
        errors: {
            emptyItems: false,
            sellPriceErrors: [],
        },
        statusToggle: false,
        status: '',
    }

    switch (action.type) {
        case INITIAL_EDIT:
            return action.payload
        case ADD_EDIT_MODS: {
            const editsList = propOr([], 'editsList')(init)
            const itemId = prop('item')(action.payload)
            const filterOutOld = reject(propEq('item')(itemId))
            return { ...init, editsList: [...filterOutOld(editsList), action.payload] }
        }
        case UPDATE_EDIT_LIST: {
            return { ...init, editsList: action.payload }
        }
        case ADD_EDIT_ERRORS:
            return { ...init, errors: action.payload }
        case CHANGE_STATUS_OPTIONS:
            return { ...init, ...action.payload }
        case CLEAR_AGREE_EDITS:
            return {}
        case CLEAR_SPA_PAGE:
            return {}
        default:
            return init
    }
}
