import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { isEmpty, head, compose } from 'ramda'

class ItemFilter extends Component {
    render() {
        const { filteredItems, routeToItem } = this.props

        const handleEnterKey = (e) => {
            if ((e.key === 'Enter') && isEmpty(filteredItems)) return
            if (e.key === 'Enter') compose(routeToItem, head)(filteredItems)
        }

        return (

            <div className="itemSearch">
                <form onKeyPress={handleEnterKey}>
                    <div className="col-2">
                        <Field name="externalId" component="input" type="text" placeholder="Item ID" autoComplete="off" />
                    </div>
                    <div className="col-6">
                        <Field name="itemName" component="input" type="text" placeholder="Item Name" autoComplete="off" />
                    </div>
                    <div className="col-2">
                        <Field name="mpn" component="input" type="text" placeholder="MPN" autoComplete="off" />
                    </div>
                </form>
            </div>


        )
    }
}

export default reduxForm({
    form: 'ItemFilter',
})(ItemFilter)
