import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import {
  compose as c, equals, anyPass, pathOr,
} from 'ramda'
import { Tabs, TabLink, TabContent } from 'react-tabs-redux'
import { getModel, clearModel } from '../actions/index'
import ContractList from './ContractList'
import Details from './Details'
import AgreementList from './AgreementList'
import roleManagement from '../HOC/RoleManagement'
import { mapDispatch, mapSpinToDispatch, isFatal } from '../utilities/redux-util'
import ErrorPage from '../Essentials/ErrorPage'

const handleBack = (history) => {
  if (anyPass([equals(0), equals(1), equals(2)])(history.length)) history.push('/items')
  else history.goBack()
}

class ItemPage extends Component {
  componentDidMount() {
    const { getModel, redSpin } = this.props
    const params = queryString.parse(this.props.location.search)
    const { pathname } = this.props.location
    const itemId = params.id

    redSpin(getModel(pathname, itemId), 'detailpage')
  }

  componentDidUpdate(previousProps) {
    const { getModel, location } = this.props
    const params = queryString.parse(this.props.location.search)
    const { pathname } = this.props.location
    const accountId = params.id

    if (previousProps.location.search !== location.search) {
      getModel(pathname, accountId)
    }
  }

  componentWillUnmount() {
    const { clearModel } = this.props
    clearModel('item')
  }

  render() {
    const {
      pageSuccess, currentItem, history, isAdmin, spin,
    } = this.props
    const { contracts, agreements } = currentItem
    // Just show spinner if not loaded
    if (spin.detailpage) return <div className="loader" />
    if (pageSuccess === 'INSUFFICIENT') return <ErrorPage history={history} />

    return (
      <div className="contentDetail">
        <div className="headerDetail">
          <button className="btnPrev" onClick={() => handleBack(history)} />
          <div className="titleDetail items">
            <div className="infoIcon" />
            <h2>{`${currentItem.externalId} ${pathOr('', ['name'])(currentItem)}`}</h2>
          </div>
        </div>
        <Details isAdmin={isAdmin} currentItem={currentItem} />
        <div className="listDetail">
          <Tabs>
            <div className="tabOnDetail">
              <TabLink to="tab1" className="tab-link">
                Sell Price Agreements
              </TabLink>
              <TabLink to="tab2" className="tab-link">
                Contract Prices
              </TabLink>
            </div>
            <TabContent for="tab1">
              <AgreementList agreements={agreements} history={history} />
            </TabContent>
            <TabContent for="tab2">
              <ContractList contracts={contracts} history={history} />
            </TabContent>
          </Tabs>
        </div>
      </div>
    )
  }
}
const md2p = mapDispatch({ getModel: c(isFatal, getModel) }, { clearModel }, (dispatch) => ({ redSpin: mapSpinToDispatch(dispatch) }))

const mapStateToProps = ({ pageSuccess, currentItem, spin }) => ({ pageSuccess, currentItem, spin })


export default c(roleManagement, connect(mapStateToProps, md2p))(ItemPage)
