const FETCH_INIT_CONTRACTS = 'FETCH_INIT_CONTRACTS'
const FETCH_PAGE_CONTRACTS = 'FETCH_PAGE_CONTRACTS'
const SEARCH_CONTRACTS = 'SEARCH_CONTRACTS'

export default (state, action) => {
    const init = state || 0
    switch (action.type) {
        case FETCH_INIT_CONTRACTS: case FETCH_PAGE_CONTRACTS: case SEARCH_CONTRACTS:
            return action.total
        default:
            return init
    }
}
