import React from "react"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import Send from "@material-ui/icons/Send"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
    },
    deleteIcon: {
        color: "#000"
    }
}, 'CustomToolbarSelect')

const CustomToolbarSelect = (props) => {
    const { confirmedUnknownItems, submitConfirmedItems } = props

    const submit = () => {
        const ItemsToSend = confirmedUnknownItems.filter(f => f.confirmed).map(val => (
            {
                id: val.id,
                uom: val.uom,
                confirmed: val.confirmed
            }
        ))

        submitConfirmedItems(ItemsToSend)
    }

    const classes = useStyles()

    return (
        <div className={"custom-toolbar-select"}>
            <Tooltip title={"Submit"}>
                <IconButton className={classes.iconButton} onClick={submit}>
                    <Send className={classes.deleteIcon} />
                </IconButton>
            </Tooltip>
        </div>
    )
}

export default CustomToolbarSelect
