import { pathOr, find, propEq } from 'ramda'
import {
    GET_CONTRACT_UNKNOWN_ITEMS,
    SET_INIT_CONFIRMED_UNKNOWN_ITEMS,
    SET_PROP_CONFIRMED_ITEMS,
    CONFIRM_ALL
} from '../actions/action_types'

const INITIAL_STATE = {
    foundUnknownItems: [{}],
    confirmedUnknownItems: [{}]
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CONTRACT_UNKNOWN_ITEMS:
            return { ...state, foundUnknownItems: action.payload }
        case SET_INIT_CONFIRMED_UNKNOWN_ITEMS:
            return {
                ...state,
                confirmedUnknownItems: action.payload.map((ui) => (
                    {
                        id: ui._id,
                        itemId: pathOr(null, ['itemId', '_id'], ui),
                        confirmed: false,
                        contractPrice: ui.contractPrice,
                        unknownItemId: ui.unknownItemId,
                        uom: pathOr(undefined, ['itemId', 'unitsTypeId', 'uom', '0', 'value'], ui)
                    }
                ))
            }
        case SET_PROP_CONFIRMED_ITEMS:
            return {
                ...state,
                confirmedUnknownItems: state.confirmedUnknownItems.map((confirmedItem) => {
                    if (confirmedItem.id === action.payload.id) {
                        return ({
                            ...confirmedItem,
                            [action.payload.field]: action.payload.value
                        })
                    }
                    return confirmedItem
                })
            }
        case CONFIRM_ALL:
            const allChecked = find(propEq('confirmed', false), state.confirmedUnknownItems) === undefined
            return {
                ...state,
                confirmedUnknownItems: state.confirmedUnknownItems.map((confirmedItem) => ({
                    ...confirmedItem,
                    confirmed: !allChecked
                }))
            }
        default:
            return state
    }
}