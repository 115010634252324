import axios from '../Essentials/axios'

const FETCH_ACCOUNT = 'FETCH_ACCOUNT'
const SELECT_ACCOUNT = 'SELECT_ACCOUNT'
const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT'
const CLEAR_SPA_CREATE = 'CLEAR_SPA_CREATE'
const CLEAR_SPA_UPDATE = 'CLEAR_SPA_UPDATE'
const UPDATE_SOURCES = 'UPDATE_SOURCES'

export const removeAccountSource = (accountid, sourceid) => axios.post('/sources/remove', {
        accountid,
        sourceid,
    })
        .then((response) => {
            const { contractSources } = response.data
            return ({
                type: UPDATE_SOURCES,
                payload: contractSources,
            })
        })
        .catch((e) => console.log(e))


export const addAccountSource = (accountid, sourceid) => axios.post('/sources/add', {
        accountid,
        sourceid,
    })
        .then((response) => {
            const { contractSources } = response.data
            return ({
                type: UPDATE_SOURCES,
                payload: contractSources,
            })
        })
        .catch((e) => console.log(e))

export default (state, action) => {
    const init = state || {}

    switch (action.type) {
        case FETCH_ACCOUNT:
            return action.payload
        case SELECT_ACCOUNT:
            return action.payload
        case UPDATE_SOURCES:
            return { ...init, contractSources: action.payload }
        case CLEAR_ACCOUNT:
            return {}
        case CLEAR_SPA_CREATE:
            return {}
        case CLEAR_SPA_UPDATE:
            return {}
        default:
            return init
    }
}
