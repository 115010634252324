import { objOf } from 'ramda'

export const SPINZY = 'SPINZY'
export const STOPZY = 'STOPZY'

export default (state, action) => {
  const init = state || {}

  switch (action.type) {
    case SPINZY:
      return { ...init, ...objOf(action.where, true) }
    case STOPZY:
      return { ...init, ...objOf(action.where, false) }
    default:
      return init
  }
}
