import React, { Component } from 'react'
import {
 compose as c, anyPass, equals, pathOr,
} from 'ramda'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ContractList from './ContractList'
import { mapDispatch, mapSpinToDispatch, isFatal } from '../utilities/redux-util'
import { sliceToWhiteSpace } from '../utilities/util'
import { getModel, clearModel } from '../actions/index'
import ErrorPage from '../Essentials/ErrorPage'

const handleBack = (history) => {
    if (anyPass([equals(0), equals(1), equals(2)])(history.length)) history.push('/manus')

    else history.goBack()
}


const renderContactInfo = (vendor) => {
    const phone = pathOr('', ['contact', 'phone'])(vendor)
    const email = pathOr('', ['contact', 'email'])(vendor)
    const address = pathOr('', ['contact', 'address'])(vendor)

    return (
        <div className="borderTop">
            <li>
                <p>PHONE</p>
                <Link to={`tel:${phone}`} target="_parent">
                    <span className="phoneDetail">{phone}</span>
                </Link>
            </li>
            <li>
                <p>E-MAIL</p>
                <Link to={`mailto:${email}`} target="_parent">
                    <span className="mailtoDetail">{email}</span>
                </Link>
            </li>
            <li>
                <p>ADDRESS</p>
                <span>{address}</span>
            </li>
        </div>
    )
}

class ManuPage extends Component {
    componentDidMount() {
        const { getModel, redSpin } = this.props
        const params = queryString.parse(this.props.location.search)
        const { pathname } = this.props.location
        const manuId = params.id
        redSpin(getModel(pathname, manuId), 'detailpage')
    }

    componentDidUpdate(previousProps) {
        const { getModel, location } = this.props
        const params = queryString.parse(this.props.location.search)
        const { pathname } = this.props.location
        const accountId = params.id
        if (previousProps.location.search !== location.search) getModel(pathname, accountId)
    }

    componentWillUnmount() {
        const { clearModel } = this.props
        clearModel('manufacturer')
    }

    render() {
        const {
 pageSuccess, currentManu, history, spin,
} = this.props
        // Just show spinner if not loaded
        if (spin.detailpage) return <div className="loader" />
        if (pageSuccess === 'INSUFFICIENT') return <ErrorPage history={history} />

        const name = pathOr('', ['name'])(currentManu)
        const slicedEntity = c(sliceToWhiteSpace, pathOr('', ['entityId']))(currentManu)
        return (
            <div className="contentDetail">
                <div className="headerDetail">
                    <button className="btnPrev" onClick={() => handleBack(history)} />
                    <div className="titleDetail vendors">
                        <div className="infoIcon" />
                        <h2>{slicedEntity} {name}</h2>
                    </div>
                </div>
                <div className="infoBody">
                    <ul>
                        <li>
                            <p>ID</p>
                            <span>{slicedEntity}</span>
                        </li>
                        <li>
                            <p>NAME</p>
                            <span>{name}</span>
                        </li>
                        {renderContactInfo(currentManu)}
                    </ul>
                </div>
                <div className="listDetail">
                    <div className="tabOnDetail bolder">Contracts</div>
                    <ContractList contracts={currentManu.contracts} history={history} />
                </div>
            </div>
        )
    }
}

// export default compose(RequireDetails,RequireAuth)(ManuPage)
const md2p = mapDispatch({ getModel: c(isFatal, getModel) }, { clearModel }, (dispatch) => ({ redSpin: mapSpinToDispatch(dispatch) }))

const ms2p = ({ pageSuccess, currentManu, spin }) => ({ pageSuccess, currentManu, spin })

export default connect(ms2p, md2p)(ManuPage)
