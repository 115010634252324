import axios from '../Essentials/axios'
import { AUTH_USER, UNAUTH_USER } from './action_types'

const lstoreUserCreds = (payload) => {
  Object.keys(payload).forEach((key) => {
    localStorage.setItem(key, typeof payload[key] === 'object' ? JSON.stringify(payload[key]) : payload[key])
  })
  return payload
}

export const loginSSO = ({ token }) => {
  return async dispatch => {
    localStorage.clear()
    const { data, error } = await axios.post('/loginWithSSO', { token })
    if (error) {
      dispatch({ type: UNAUTH_USER, payload: error })
      console.log(error)
    }
    else {
      const { user, role, token } = data
      lstoreUserCreds(data)
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      dispatch({ type: AUTH_USER, user, role })
      return data
    }
  }
}