import React, { Component } from 'react'
import {
  compose, isEmpty, equals, values, complement, reduce, pathOr, prop,
  map, mapObjIndexed, and, mergeAll, isNil, addIndex, head, omit,
} from 'ramda'
import DatePicker from 'react-datepicker'
import { sliceToWhiteSpace } from '../utilities/util'
import '../../node_modules/react-datepicker/dist/react-datepicker.css'

const mapIndexed = addIndex(map)

class Stage1 extends Component {
  accountSearchHandlerInit = () => {
    const handleSearch = (search) => {
      const { clearModel, searchModels } = this.props
      clearModel('account')
      return searchModels('/accounts', 1, search)
    }

    return this.props.redSearchHandlerInit(handleSearch, '/accountssearch')
  }

  accountSearchHandler = this.accountSearchHandlerInit()

  componentDidMount() {
    const { addToSPACreate, spaCreate } = this.props
    const entityId = spaCreate.entityId || ''
    const targetMargin = spaCreate.targetMargin || 30
    const expDate = spaCreate.expDate || ''
    addToSPACreate({ targetMargin, expDate, entityId })
  }

  render() {
    const {
      spaCreate, visibleAccounts, currentAccount, addToSPACreate,
      clearModelsToView, searchModels, selectModel, clearModel,
      createSpaErrors, addToCreateSpaErrors, spin, redSpin,
    } = this.props

    const { expDate, targetMargin, entityId } = spaCreate

    const currentAccountName = prop('companyName')(currentAccount) || prop('name')(currentAccount)
    const currentAccountIdentifier = compose(sliceToWhiteSpace, pathOr('', ['entityId']))(currentAccount)

    const handleNext = () => {
      const basicFields = { ...spaCreate, account: currentAccount }
      const ignoreLaterFields = omit(['entityId', 'sellPriceErrors', 'emptyItems', 'stage'])
      const valuesOnSpa = compose(values, ignoreLaterFields)(basicFields)
      const mapToBools = map(complement(isEmpty))
      const listOfBools = mapToBools(valuesOnSpa)
      const allGood = reduce(and)(true)

      const checkErrorOnSpa = (val, key) => {
        if (isEmpty(val)) return { [key]: true }
        else return { [key]: false }
      }

      const getErrorObject = compose(mergeAll, values, mapObjIndexed(checkErrorOnSpa))

      if (allGood(listOfBools)) {
        addToSPACreate({ account: currentAccount, stage: spaCreate.stage + 1 })
        compose(addToCreateSpaErrors, getErrorObject, ignoreLaterFields)(basicFields)
      } else compose(addToCreateSpaErrors, getErrorObject, ignoreLaterFields)(basicFields)
    }

    const selectAccount = (account) => {
      clearModelsToView('accounts')
      selectModel('account', account)
    }

    const renderAccount = (account, index) => {
      const { name, companyName } = account
      const accountName = companyName || name
      const accountIdentifier = compose(sliceToWhiteSpace, pathOr('', ['entityId']))(account)

      return (
        <li key={index} onMouseDown={() => selectAccount(account)}>
          <div className="bolder">{accountIdentifier} {accountName}</div>
        </li>
      )
    }

    const renderAccounts = mapIndexed(renderAccount)

    const renderChoices = () => (
        <div className="resultBySearch">
          <ul>{renderAccounts(visibleAccounts)}</ul>
        </div>
      )

    const handleFieldChange = (fieldName, event) => addToSPACreate({ [fieldName]: event.target.value })


    const handleDateChange = (date) => {
      if (isNil(date)) addToSPACreate({ expDate: '' })

      else addToSPACreate({ expDate: date })
    }

    const handleEnter = (e) => {
      if (e.key === 'Enter' && isEmpty(visibleAccounts)) return e.preventDefault()
      if (e.key === 'Enter') {
        selectAccount(head(visibleAccounts))
        e.preventDefault()
      }
    }

    return (
      <div className="col-12">
        <div className="basicPanel">
          <div>
            <p>Agreement Name (optional)</p>
            <input
              name="entityId"
              type="text"
              placeholder="Agreement Name"
              required="required"
              value={entityId}
              onChange={(e) => handleFieldChange('entityId', e)}
            />
          </div>
          <div className={`${createSpaErrors.account ? ' error' : ''}`}>
            <p>Select Customer</p>
            <div className={`relativeP${!isEmpty(currentAccount) ? ' selected' : ''}`}>
              <div className={`${spin['/accountssearch'] ? ' loading' : ''}`}>   </div>
              <input
                type="text"
                name="customerSearch"
                onFocus={() => addToCreateSpaErrors({ account: false })}
                placeholder="Customer Search"
                onChange={(e) => this.accountSearchHandler({ search: e.target.value })}
                onKeyPress={handleEnter}
                onBlur={() => clearModelsToView('accounts')}
                {...(!isEmpty(currentAccount) ? { value: `${currentAccountIdentifier} ${currentAccountName}` } : {})}
              />
              {!isEmpty(visibleAccounts) && renderChoices()}
            </div>
          </div>
          <div className={`${createSpaErrors.expDate ? ' error' : ''}`}>
            <p>Expiration Date</p>
            <div className="relativeP">
              <DatePicker
                selected={(isEmpty(expDate)) ? null : expDate}
                onChange={handleDateChange}
                onFocus={() => addToCreateSpaErrors({ expDate: false })}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
          <div className={`${createSpaErrors.targetMargin ? ' error' : ''}`}>
            <p>Target Margin</p>
            <input
              name="targetMargin"
              className="setMargin"
              type="number"
              placeholder="Target Margin"
              required="required"
              value={equals(0, targetMargin) ? '' : targetMargin}
              onChange={(e) => addToSPACreate({ targetMargin: Math.round(Number(e.target.value)) })}
            /><span />
          </div>
        </div>
        <div className="alignCenter">
          <button className="moreBtn aim" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    )
  }
}

export default Stage1
