import React from 'react'
import { connect } from 'react-redux'
import { contains, path } from 'ramda'
import { withRouter } from 'react-router-dom'


// admin ids
const admin = ['3']
// sales manager ids
const salesManager = ['9', '1048', '1000', '1202', '1202']
// sales rep ids
const salesRep = ['1044', '1066', '1058', '1402']
// pruchasing manager id
const purchaseManager = ['1005']
// purchasing assistant id
const purchaseAssistant = ['1057']
// inside sales roles
const insideSalesRoles = ['1000', '1202', '1065', '1027', '1302']

// function object to show roles
const hasRoles = (currentRole) => ({
  isAdmin: contains(currentRole, admin),
  isSalesManager: contains(currentRole, salesManager),
  isSalesRep: contains(currentRole, salesRep),
  isPurchaseManager: contains(currentRole, purchaseManager),
  isPurchaseAssistant: contains(currentRole, purchaseAssistant),
  isInsideSales: contains(currentRole, insideSalesRoles),
})

// higher order component
export default (ComposedComponent) => {
  const Roles = (props) => (
    <ComposedComponent {...props} {...hasRoles(path(['currentRole', 'value'])(props))} />
  )

  return withRouter(connect(({ currentRole }) => ({ currentRole }))(Roles))
}
