import React, { Component } from 'react'
import {
 addIndex, map, curry, compose,
} from 'ramda'
import { dateString } from '../utilities/util'

const mapIndexed = addIndex(map)

const AgreementList = (props) => {
        const { agreements, history } = props

        const renderAgreement = curry((history, agreement, index) => {
            const { endDate, _id } = agreement
            const formattedDate = dateString(new Date(endDate))

            return (
                    <li onClick={() => history.push(`/spa?id=${_id}`)} key={index}>
                        <div className="col-6 bolder">{_id}</div>
                        <div className="col-6">{formattedDate}</div>
                    </li>
            )
        })

        const renderAgreements = compose(mapIndexed, renderAgreement)

        return (
            <div>
                <div className="headerBasicList">
                    <div className="col-6">ID</div>
                    <div className="col-6">Expiration Date</div>
                </div>
                <div className="basicList">
                    <ul>
                        {agreements && renderAgreements(history)(agreements)}
                    </ul>
                </div>
            </div>
        )
}

export default AgreementList
