const FETCH_INIT_MANUS = 'FETCH_INIT_MANUS'
const FETCH_PAGE_MANUS = 'FETCH_PAGE_MANUS'
const SEARCH_MANUS = 'SEARCH_MANUS'

export default (state, action) => {
    const init = state || 0

    switch (action.type) {
        case FETCH_INIT_MANUS: case FETCH_PAGE_MANUS: case SEARCH_MANUS:
            return action.total
        default:
            return init
    }
}
