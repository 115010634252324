import React, { Component } from 'react'
import queryString from 'query-string'
import {
  map, complement, reduce, and, compose, isNil, pathOr, head,
  mergeAll, values, mapObjIndexed, isEmpty, equals, addIndex,
  prop, omit
} from 'ramda'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { sliceToWhiteSpace } from '../utilities/util'
import '../../node_modules/react-datepicker/dist/react-datepicker.css'

const mapIndexed = addIndex(map)

class Stage1 extends Component {

  state = { message: '' }
  accountSearchHandlerInit = () => {
    const handleSearch = (search) => {
      const { clearModel, searchModels } = this.props
      clearModel('account')
      return searchModels('/accounts', 1, search)
    }

    return this.props.redSearchHandlerInit(handleSearch, '/accountssearch')
  }

  accountSearchHandler = this.accountSearchHandlerInit()

  componentDidMount() {
    const { addToSPAUpdate, spaUpdate } = this.props
    const targetMargin = spaUpdate.targetMargin || 30
    const endDate = moment.utc(spaUpdate.endDate)

    addToSPAUpdate({ targetMargin, endDate })
  }

  render() {
    const { message } = this.state
    const {
      spaUpdate, visibleAccounts, currentAccount, addToSPAUpdate, clearModelsToView,
      searchModels, selectModel, clearModel, addToUpdateErrors, updateSpaInputError, spin, isCloning
    } = this.props

    const { endDate, targetMargin, entityId, _id, existingAccountIds } = spaUpdate
    const currentAccountName = prop('companyName')(currentAccount) || prop('name')(currentAccount)
    const currentAccountIdentifier = compose(sliceToWhiteSpace, pathOr('', ['entityId']))(currentAccount)

    const changeMessage = (message) => this.setState({ ...this.state, message })

    const renderAlert = (message) => {
      if (!isEmpty(message)) {
        setTimeout(() => changeMessage(''), 5000)

        return (
          <div className="dangerMsg">
            <span>{message}</span>
            <span className="marginLeft" onClick={() => changeMessage('')}>X</span>
          </div>
        )
      }
    }

    const handleNext = () => {
      const mapToBools = map(complement(isEmpty))
      const basicFields = { ...spaUpdate, account: currentAccount }
      const isDuplicateAgreementForAccount = !isCloning && existingAccountIds.includes(basicFields.accountId)
      if (isDuplicateAgreementForAccount)
        return changeMessage('This customer already has an agreement for this Master Agreement. Cannot create multiple agreements')

      const ignoreLaterFields = omit(['entityId'])
      const valuesOnSpa = compose(values, ignoreLaterFields)(basicFields)
      const listOfBools = mapToBools(valuesOnSpa)
      const allGood = reduce(and)(true)

      const checkErrorOnSpa = (val, key) => isEmpty(val) ? { [key]: true } : { [key]: false }
      //   if (isEmpty(val))
      //     return { [key]: true }
      //   else
      //     return { [key]: false }
      // }

      const getErrorObject = compose(mergeAll, values, mapObjIndexed(checkErrorOnSpa))

      const errorObject = getErrorObject(basicFields)

      if (allGood(listOfBools)) {
        addToSPAUpdate({ stage: spaUpdate.stage + 1 })
        addToUpdateErrors(errorObject)
      } else addToUpdateErrors(errorObject)
    }

    const selectAccount = (account) => {
      clearModelsToView('accounts')
      selectModel('account', account)
      addToSPAUpdate({ accountId: account._id })
    }

    const renderAccount = (account, index) => {
      const { name, companyName } = account
      const accountName = companyName || name
      const accountIdentifier = compose(sliceToWhiteSpace, pathOr('', ['entityId']))(account)

      return (
        <li key={index} onMouseDown={() => selectAccount(account)}>
          <div className="bolder">{accountIdentifier} {accountName}</div>
        </li>
      )
    }

    const renderAccounts = mapIndexed(renderAccount)

    const renderChoices = () => (
      <div className="resultBySearch">
        <ul>{renderAccounts(visibleAccounts)}</ul>
      </div>
    )

    const handleFieldChange = (fieldName, event) => {
      addToSPAUpdate({ [fieldName]: event.target.value })
    }

    const handleDateChange = (date) => {
      if (isNil(date)) addToSPAUpdate({ endDate: '' })

      else addToSPAUpdate({ endDate: date })
    }


    const handleEnter = (e) => {
      if (e.key === 'Enter' && isEmpty(visibleAccounts)) return e.preventDefault()
      if (e.key === 'Enter') {
        selectAccount(head(visibleAccounts))
        e.preventDefault()
      }
    }

    const datePickerEndDate = endDate && endDate.isValid() ? endDate : null
    return (
      <div className="col-12">
        <div className="basicPanel">
          <div>
            <p>Agreement Name </p>

            {
              !isCloning ? <input readOnly name="name" type="text" value={entityId || _id} />
                : <input
                  name="entityId"
                  type="text"
                  placeholder="Agreement Name"
                  //required="required"
                  value={entityId}
                  onChange={(e) => handleFieldChange('entityId', e)}
                />
            }
          </div>
          <div className={`${updateSpaInputError.account ? ' error' : ''}`}>
            <p>Select Customer</p>
            <div className={`relativeP${!isEmpty(currentAccount) ? ' selected' : ''}`}>
              <div className={`${spin['/accountssearch'] ? ' loading' : ''}`}>   </div>
              <input
                type="text"
                name="customerSearch"
                placeholder="Customer Search"
                onFocus={() => addToUpdateErrors({ account: false })}
                onKeyPress={handleEnter}
                onChange={(e) => this.accountSearchHandler({ search: e.target.value })}
                onBlur={() => clearModelsToView('accounts')}
                {...(!isEmpty(currentAccount) ? { value: `${currentAccountIdentifier} ${currentAccountName}` } : {})}
              />
              <br />
              {!isEmpty(visibleAccounts) && renderChoices()}
            </div>
          </div>
          <div className={`${updateSpaInputError.endDate ? ' error' : ''}`}>
            <p>Expiration Date</p>
            <div className="relativeP">
              <DatePicker
                selected={datePickerEndDate}
                onChange={handleDateChange}
                onFocus={() => addToUpdateErrors({ endDate: false })}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
          <div>
            <p>Target Margin</p>
            <input
              className="setMargin"
              name="targetMargin"
              type="number"
              placeholder="Target Margin"
              required="required"
              value={equals(0, targetMargin) ? '' : targetMargin}
              onChange={(e) => addToSPAUpdate({ targetMargin: Math.round(Number(e.target.value)) })} />
            <span />
          </div>
        </div>
        <div className="alignCenter">
          {message && renderAlert(message)}
          <button className="moreBtn aim" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    )
  }
}

export default Stage1
