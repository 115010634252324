import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
 filter, or, prop, map, keys, compose, reduce, pathOr, addIndex,
} from 'ramda'
import AccountFilter from './AccountFilter'
import { getRegexObj, sliceToWhiteSpace } from '../utilities/util'


const mapIndexed = addIndex(map)

class AccountList extends Component {
    render() {
        const { currentContract, history } = this.props

        const accounts = pathOr([], ['currentContract', 'accounts'])(this.props)

        const accountFilter = pathOr({}, ['accountFilter'])(this.props)

        const accFilter = {
            name: pathOr('', ['value'])(accountFilter),
            entityId: pathOr('', ['value'])(accountFilter),
        }

        const filterObj = getRegexObj(accFilter)

        const checkProp = (property, searchObj, acc) => {
            const propValue = prop(property)(acc)
            return searchObj[property].test(propValue)
        }

        const checkAcc = (acc) => map((key) => checkProp(key, filterObj, acc), keys(filterObj))
        const reduceToTrue = reduce(or, false)
        const filterAccs = filter(compose(reduceToTrue, checkAcc))
        const filteredAccs = filterAccs(accounts)

        const renderAccount = (account, index) => {
            const {
 name, companyName, entityId, _id,
} = account
            const accountName = name || companyName
            const firstName = pathOr('', ['salesRepId', 'name', 'first'])(account)
            const lastName = pathOr('', ['salesRepId', 'name', 'last'])(account)
            const slicedEntityId = sliceToWhiteSpace(entityId)

            return (
                <li key={index}>
                    <Link to={`/account?id=${_id}`}>
                        <div className="col-3">{slicedEntityId}</div>
                        <div className="col-6 alignLeft bolder">{accountName}</div>
                        <div className="col-3">{firstName} &nbsp; {lastName}</div>
                    </Link>
                </li>
            )
        }

        const renderAccounts = mapIndexed(renderAccount)


        const routeToAccount = (account) => {
            history.push(`/account?id=${account._id}`)
        }

        return (
            <div>
                <AccountFilter {...this.props} routeToAccount={routeToAccount} filteredAccs={filteredAccs} />
                <div className="headerBasicList">
                    <div className="col-3">ID</div>
                    <div className="col-6">Name</div>
                    <div className="col-3">Sales Rep</div>
                </div>
                <div className="basicList">
                    <ul>
                        {renderAccounts(filteredAccs)}
                    </ul>
                </div>
            </div>
        )
    }
}

export default AccountList
