
const GPO_MANAGE_CHANGES = 'GPO_MANAGE_CHANGES'

export const gpoManageChange = (fieldObj) => ({ type: GPO_MANAGE_CHANGES, payload: fieldObj })

export default (state, action) => {
    const init = state || {
        selectedGpo: '',
        selectedTier: '',
    }

    switch (action.type) {
        case GPO_MANAGE_CHANGES:
            return { ...init, ...action.payload }
        default:
            return init
    }
}
