import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import paginate from '../HOC/paginate'
import Search from '../ReduxForm/Search'
import InfinityScroll from '../HOC/Infinity'

class AllManus extends Component {
    componentDidMount() {
        const { selectFirstPage, getInitModels, redSpin } = this.props
        const { pathname } = this.props.location

        selectFirstPage()
        redSpin(getInitModels(pathname, 1), 'mainlist')
    }

    componentWillUnmount() {
        const { clearModelsToView, selectFirstPage } = this.props
        clearModelsToView('manus')
        selectFirstPage()
    }

    loadNextPage() {
        const {
 visibleManus, totalManus, currentPage, searchTerms, getPageOfModels, incrementPage, redSpin,
} = this.props
        const { pathname } = this.props.location
        if (visibleManus.length >= totalManus) return Promise.resolve()

        incrementPage()
        return redSpin(getPageOfModels(pathname, currentPage + 1, searchTerms), 'loading')
    }


    renderManus() {
        return this.props.visibleManus.map((manu) => (
                <li key={manu._id}>
                    <Link to={`/manufacturer?id=${manu._id}`}>
                        <div className="col-2">{manu.entityId}</div>
                        <div className="col-5 alignLeft bolder divider">{manu.name}</div>
                        <div className="col-3"><p className="mailtoList">{(manu.contact) && manu.contact.email}</p></div>
                        <div className="col-2">{(manu.contact) && manu.contact.phone}</div>
                    </Link>
                </li>
            ))
    }

    renderNoResults() {
        return (
            <div className="errorMsg" />
        )
    }

    render() {
        const { visibleManus, totalManus, spin } = this.props

        return (
            <div className="listPage">
                <InfinityScroll loadMore={this.loadNextPage.bind(this)} />
                <div className="headerArea">
                    <div className="headerSticky">

                        <div>
                            <h2>Vendors</h2>
                            <p>Showing <span className="bolder">{visibleManus.length}</span> of <span>{totalManus}</span> Vendors</p>
                        </div>
                        <div className={`searchLoading${spin['/manussearch'] ? ' loading' : ''}`}>
                            <Search {...this.props} />
                        </div>
                        <div className="headerBasicList">
                            <div className="col-2">ID</div>
                            <div className="col-5">Name</div>
                            <div className="col-3">Email</div>
                            <div className="col-2">Phone</div>

                        </div>
                        <div className={`${spin.mainlist ? ' loader' : ''}`}>   </div>

                    </div>
                </div>

                {spin.mainlist ? (<div className="basicList"><div className="loader" /></div>)
                    : (<div>
                        <div className="basicList">
                            <ul>
                                {this.renderManus()}
                            </ul>
                            {(totalManus === 0) && this.renderNoResults()}
                        </div>
                        <div className={`btnArea${spin.loading ? ' loader' : ''}`} />
                       </div>)}
            </div>
        )
    }
}

// export default compose(RequireAuth, RequirePagination)(AllManus)
export default paginate(AllManus)
