import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import {
 compose as c, head, prop, compose, isEmpty, objOf, merge,
} from 'ramda'

class ItemSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            externalId: '',
            name: '',
            manuName: '',
            mpn: '',
        }
    }

    searchHandlerInit = () => {
        const { selectFirstPage, searchModels } = this.props

        const handleSearch = (e) => {
            const { name, value } = e
            const change = objOf(name, value)
            selectFirstPage()
            const searchP = searchModels('/items', 1, merge(this.state, change))
            this.setState(change)
            return searchP
        }
        return this.props.redSearchHandlerInit(handleSearch, '/itemssearch')
    }

    searchHandler = c(this.searchHandlerInit(), ({ target }) => ({ name: target.name, value: target.value }))

    render() {
        const {
 visibleItems, history, selectFirstPage, searchModels, redSpin,
} = this.props

        const handleEnterKey = (e) => {
            if ((e.key === 'Enter') && isEmpty(visibleItems)) return
            if (e.key === 'Enter') {
                const itemId = compose(prop('_id'), head)(visibleItems)
                history.push(`/item?id=${itemId}`)
            }
        }

        return (
            <div className="itemSearch">
                <form
                    onKeyPress={handleEnterKey}
                    // className={searchTerms ? ' inputted' : ''}
                    // className='inputted'
                    >
                    <div className="col-2 inBlock">
                        <input onChange={this.searchHandler} name="externalId" type="text" placeholder="Item ID" autoComplete="off" />
                    </div>
                    <div className="col-5 inBlock">
                        <input onChange={this.searchHandler} name="name" component="input" type="text" placeholder="Item Name" autoComplete="off" />
                    </div>
                    <div className="col-3 inBlock">
                        <input onChange={this.searchHandler} name="manuName" component="input" type="text" placeholder="Manufacturer" autoComplete="off" />
                    </div>
                    <div className="col-2 inBlock">
                        <input onChange={this.searchHandler} name="mpn" component="input" type="text" placeholder="MPN" autoComplete="off" />
                    </div>
                </form>
            </div>
        )
    }
}

export default ItemSearch
