import {
 assoc, pick, prop, isEmpty, pathOr,
} from 'ramda'
import moment from 'moment'
import axios from '../Essentials/axios'

const ADD_MASTER_AGREEMENTS = 'ADD_MASTER_AGREEMENTS'
const CLEAR_SPA_UPDATE = 'CLEAR_SPA_UPDATE'
const MERGE_SPA_ACCOUNT_DETAILS = 'MERGE_SPA_ACCOUNT_DETAILS'
// takes a master spa id
const fetchMasterSPADetails = (id) => axios.get('/masterspa', {
        params: {
            id,
        },
    })

export const getMasterSPADetails = (id) => fetchMasterSPADetails(id).then(({ data }) => {
        const { priceAgreements } = data
        const baseSPADetails = pick(['entityId', '_id', 'accountAgreements'])(data)
        const rawDate = prop('endDate')(data) || ''
        const momentDate = (!isEmpty(rawDate)) ? moment(rawDate) : rawDate
        // const formattedDate = baseSPADetails.endDate ? compatibleDateString(new Date(baseSPADetails.endDate)) : ''
        const baseWithDateFormatted = assoc('endDate')(momentDate)(baseSPADetails)

        const tm = pathOr(30, ['accountAgreements', 0, 'accountAgreementId', 'targetMargin'], data)
        const baseWithTargetMargin = assoc('targetMargin')(tm)(baseWithDateFormatted)


        return [{
            type: ADD_MASTER_AGREEMENTS,
            payload: priceAgreements,
        },
        {
            type: MERGE_SPA_ACCOUNT_DETAILS,
            payload: baseWithTargetMargin,
        }]
    })
        .catch((e) => console.log(e))

export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case ADD_MASTER_AGREEMENTS:
            return action.payload
        case CLEAR_SPA_UPDATE:
            return []
        default:
            return init
    }
}
