const CLEAR_CON_SUBMIT = 'CLEAR_CON_SUBMIT'
const FETCH_INIT_ACCOUNTS = 'FETCH_INIT_ACCOUNTS'
const FETCH_PAGE_ACCOUNTS = 'FETCH_PAGE_ACCOUNTS'
const SEARCH_ACCOUNTS = 'SEARCH_ACCOUNTS'
const CLEAR_ACCOUNTS = 'CLEAR_ACCOUNTS'
const CLEAR_SPA_CREATE = 'CLEAR_SPA_CREATE'

export default (state, action) => {
    const init = state || []

    switch (action.type) {
        case FETCH_INIT_ACCOUNTS:
            return action.payload
        case FETCH_PAGE_ACCOUNTS:
            return state.concat(action.payload)
        case SEARCH_ACCOUNTS:
            return action.payload
        case CLEAR_CON_SUBMIT:
            return []
        case CLEAR_ACCOUNTS:
            return []
        case CLEAR_SPA_CREATE:
            return []
        default:
            return init
    }
}
